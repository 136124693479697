import { LinearProgress, Stack, Typography, Tooltip } from '@mui/material';
import * as time from '../../utils/time.js'

export function RenderPercentile(display: string, percentile: number, percentileDivider: number = 1) {
  return (
    <>
      <Stack spacing={0} direction="column" justifyContent="center" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        {percentile === 0 && <Typography variant="body1">{display}</Typography>}
        {percentile > 0 && <Typography variant="body2">{display}</Typography>}
        {percentile > 0 &&
          <Stack spacing={0.5} direction="row" alignItems="center" sx={{ width: 1 }}>
            <LinearProgress
              value={percentile / percentileDivider}
              variant="determinate"
              color={
                (percentile / percentileDivider < 30 && 'error') ||
                (percentile / percentileDivider > 30 && percentile / percentileDivider < 70 && 'warning') ||
                'primary'
              }
              sx={{ width: 0.8, height: 0.2 }}
            />
            <Typography variant='caption'>{'>'}{percentile / percentileDivider}%</Typography>
          </Stack>
        }
      </Stack>
    </>
  );
}

export function RenderPercentile2(display: string, percentile: number, percentileDivider: number = 1) {
  return (
    <>
      <Stack spacing={1} direction="column" justifyContent="center" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        {percentile === 0 && <Typography variant="body1">{display}</Typography>}
        {percentile > 0 &&
          <Stack gap={1} direction="row" alignItems="center">
            <Typography variant="body2">{display}</Typography>
            <Typography variant="caption">{' (>'}{percentile / percentileDivider}%{')'}</Typography>
          </Stack>
        }
        {percentile > 0 &&
          <LinearProgress
            value={percentile / percentileDivider}
            variant="determinate"
            color={
              (percentile / percentileDivider < 30 && 'error') ||
              (percentile / percentileDivider > 30 && percentile / percentileDivider < 70 && 'warning') ||
              'primary'
            }
            sx={{ width: 0.6, height: 0.05 }}
          />
        }
      </Stack>
    </>
  );
}

export function RenderBalance(value: number) {
  return <Typography variant="body1">{value ? value.toFixed(3) : 0}</Typography>;
}

export function RenderDollarBalance(value: number) {
  return <Typography variant="body1">${value ? value.toFixed(2) : 0}</Typography>;
}

export function RenderHeader(value: string, hint?: string) {
  return (
    <>
      {hint &&
        <Tooltip title={hint} placement="top">
          <Typography variant="h6">{value}</Typography>
        </Tooltip>
      }
      {!hint && <Typography variant="h6">{value}</Typography>}
    </>
  )
}

export function RenderHeaderGroup(value: string) {
  return <Typography variant="h6">{value}</Typography>;
}

export function getAddressNickname(address, user) {
  if (!user || !user.addressNicknames) return null
  return user.addressNicknames[address]
}

export async function setAddressNickname(address, value, user, updateUser) {
  var newUserData = user
  if (!newUserData.addressNicknames) {
    newUserData.addressNicknames = {}
  }
  newUserData.addressNicknames[address] = value
  updateUser(newUserData)
}

export function getTruncatedAddress(address) {
  return address.substring(0, 6) + "..." + address.substring(38)
}

export function formatTime(value) {
  if (!value) return "-"
  return time.getTimeDescriptionFromTimestamp(value)
}

export function getPercentile(value, percentilesJson, maxPercentile = 100) {
  if (!percentilesJson || !percentilesJson.percentiles) return 0
  const percentiles = percentilesJson.percentiles
  var i = 0
  for (; i < maxPercentile; i++) {
    if (value <= percentiles[i]) {
      break
    }
  }
  return i
}