import { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import twitterImg from '../../images/twitter.png'
// @ts-ignore
import { useUserContext } from '../../context/UserContext.tsx';
import * as apis from '../../utils/apirequests.js'

function TwitterOAuth({ onUserConnected = null, showAtAccountPage = false, onSocialAccountSelected = null, socialAccountSelected = null }) {
  const userUuid = useRef<string | null>(null)
  const { user } = useUserContext()
  const [twitterUsername, setTwitterUsername] = useState(null)
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true)

  const fetchTwitterUsername = async () => {
    if (userUuid.current) {
      const jsonData = await apis.backendRequest("get_user_metadata", {
        "user_uuid": userUuid.current,
      });
      return jsonData.twitter_username
    }
  }

  const fetchTwitterUsernameLongPoll = async () => {
    if (userUuid.current) {
      const jsonData = await apis.backendRequest("get_user_twitter", {
        "user_uuid": userUuid.current,
      });
      return jsonData.twitter_username
    }
  }

  const TWITTER_STATE = "state"
  const TWITTER_CODE_CHALLENGE = "challenge"
  const TWITTER_AUTH_URL = "https://twitter.com/i/oauth2/authorize"
  const TWITTER_SCOPE = ["tweet.read", "users.read", "offline.access"].join(" ")
  const TWITTER_CLIENT_ID = "MzdDUktScFlVVm5NNm5NLXM3NTY6MTpjaQ"
  const TWITTER_COLOR_CODE = "#26a7de"

  const getURLWithQueryParams = (
    baseUrl: string,
    params: Record<string, any>
  ) => {
    const query = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&")

    return `${baseUrl}?${query}`
  }

  const getTwitterOAuthUrl = (redirectUri: string) =>
    getURLWithQueryParams(TWITTER_AUTH_URL, {
      response_type: "code",
      client_id: TWITTER_CLIENT_ID,
      redirect_uri: redirectUri,
      scope: TWITTER_SCOPE,
      state: TWITTER_STATE,

      code_challenge: TWITTER_CODE_CHALLENGE,
      code_challenge_method: "plain",
    })

  useEffect(() => {
    fetchTwitterUsername().then((username) => {
      setTwitterUsername(username)
      if (onUserConnected) {
        onUserConnected(username)
      }
      setIsLoading(false)
    })
  }, [onUserConnected])

  // Whenever local user state finds a new userUuid, get a valid token for the user
  useEffect(() => {
    if (user) {
      userUuid.current = user.userUuid
      fetchTwitterUsername().then((username) => {
        setTwitterUsername(username)
        if (onUserConnected) {
          onUserConnected(username)
        }
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const flowid = params.get("flowid");
    // Do not need to authorize if we already have the user's twitter username
    if (isLoading || !code || twitterUsername || !userUuid.current) return
    // Twitter API does not support CORS
    // https://stackoverflow.com/questions/35879943/twitter-api-authorization-fails-cors-preflight-in-browser
    const fetchData = async () => {
      const redirect_uri = localStorage.getItem("redirect_uri")
      await apis.backendRequest("get_twitter_access_token_and_username", { code: code, flowid: flowid, user_uuid: userUuid.current, redirect_uri: redirect_uri })
      window.close()
    }
    fetchData()
  }, [location, twitterUsername, isLoading, onUserConnected]);

  const handleTwitterConnect = async () => {
    if (!showAtAccountPage && onSocialAccountSelected) {
      onSocialAccountSelected()
    }
    if (twitterUsername) {
      return;
    }
    // Start long poll if user starts Twitter connection
    fetchTwitterUsernameLongPoll().then((username) => {
      setTwitterUsername(username)
      if (onUserConnected) {
        onUserConnected(username)
      }
      setIsLoading(false)
    })
    window.open(getTwitterOAuthUrl(window.location.href), "", 'toolbar=0,status=0,width=900,height=650');
    localStorage.setItem("redirect_uri", window.location.href)
  }

  const handleTwitterDisconnect = async () => {
    await apis.backendRequest("disconnect_twitter", { user_uuid: userUuid.current })
    setTwitterUsername(null)
    if (onUserConnected) {
      onUserConnected(null)
    }
  }

  let content = null;
  if (showAtAccountPage) {
    content = <Stack direction="row" alignItems='center'>
      {<Avatar alt="Twitter" src={twitterImg} variant="square" />}
      {twitterUsername && <Typography variant="subtitle1" color="text.secondary" sx={{ m: '12px' }}>{twitterUsername}</Typography>}
      {!twitterUsername && <Typography variant="h6" color={TWITTER_COLOR_CODE} onClick={() => { handleTwitterConnect() }} sx={{ m: '12px', cursor: 'pointer' }}>Connect Twitter</Typography>}
    </Stack >
  } else {
    content = <Stack direction="row" alignItems='center'>
      {socialAccountSelected === "Unchosen" && <Avatar alt="Twitter" src={twitterImg} variant="square" onClick={() => { handleTwitterConnect() }} sx={{ cursor: "pointer" }} />}

      {socialAccountSelected === "Twitter" && twitterUsername && <Avatar alt="Twitter" src={twitterImg} variant="square" />}
      {socialAccountSelected === "Twitter" && twitterUsername && <Typography variant="subtitle1" color="text.secondary" sx={{ m: '12px' }}>{twitterUsername}</Typography>}
    </Stack >
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems='center'>
      <Stack direction="row" alignItems='center'>
        {content}
      </Stack >
      {twitterUsername && showAtAccountPage &&
        <Box sx={{ cursor: 'pointer' }} onClick={handleTwitterDisconnect}>
          <Typography variant="caption" color={"text.secondary"}>
            Disconnect
          </Typography>
        </Box>
      }
    </Stack >
  )
}

export default TwitterOAuth;