import { Box, Typography, Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

export default function AccountRecoverySection(props) {
  const handleClickRestore = () => {
    props.handleLocalStorageRestore()
  }

  const handleClickBackUp = () => {
    props.handleLocalStorageBackup()
  }
  return (
    <Box sx={{ p: 4 }}>
      <Typography color="text.main" variant="h4" textAlign="left" sx={{ mb: 4 }}>
        Recovery
      </Typography>

      <List sx={{ border: 1, borderRadius: "16px", borderColor: "secondary.light" }}>
        <ListItem sx={{ p: 2 }}>
          <ListItemText primary="Restore profile" secondary="Upload your profile from another device." />
          <Button color="primary" variant="outlined" sx={{ mr: 1, minWidth: "160px" }} onClick={handleClickRestore}>
            Restore
          </Button>
        </ListItem>
        <Divider />
        <ListItem sx={{ p: 2 }}>
          <ListItemText primary="Back-up data" secondary="Download your profile so that you can back-it up and use it on another device." />
          <Button color="primary" variant="outlined" sx={{ mr: 1, minWidth: "160px" }} onClick={handleClickBackUp} >
            Back Up
          </Button>
        </ListItem>
      </List>
    </Box >
  );
}