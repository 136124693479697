import { deflate } from 'zlib';
import * as querystring from 'querystring-browser';
import { useEffect, useState, useRef } from 'react'
// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import { Container, Button, Stack, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Iconify from '../components/iconify';

import * as apis from '../utils/apirequests.js'


function ViewVerifications() {
  const { setPage } = useUserContext()

  useEffect(() => {
    setPage("View")
  }, [setPage])

  // State to store data fetched from the backend
  const [data, setData] = useState(Object);
  const [flowInfo, setFlowInfo] = useState({})
  const [vcInfo, setVcInfo] = useState({})
  const [verifyResult, setVerifyResult] = useState(null)
  const flowId = useRef(null)

  const initializeFlow = async () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const publicAccountId = queryParameters.get("publicaccountid")
    const flowId = queryParameters.get("flowid")
    // const verificationId = queryParameters.get("verificationid")
    // const timestamp = queryParameters.get("timestamp")
    let verificationResultResponse = null
    try {
      verificationResultResponse = await apis.backendRequest('verify/query_verification', {
        "public_account_id": publicAccountId,
        "flow_id": flowId,
        // "id": verificationId,
        // "timestamp": timestamp,
      })

      const flowDetailsResponse = await apis.backendRequest('verify/flows', { "flow_id": parseInt(flowId) })
      setFlowInfo(flowDetailsResponse.flow_info)
      setVcInfo(flowDetailsResponse.vc_info)
    } catch (error) {
      console.log("errors when retrieving verificationResultResponse")
    }
    return verificationResultResponse
  }

  const verifyRangeProof = async (proof) => {
    const verifyResponse = await apis.backendRequest('verify/verify_range_proof', {
      "proof_str": JSON.stringify(proof),
    })
    setVerifyResult(verifyResponse)
  }

  const processRange = (filter, bounds) => {
    var res = ""
    for (var i = 0; i < filter.length; i++) {
      if (filter[i] === 0 || filter[i] === "0") {
        res = res + ", n/a"
      } else {
        res = res + ", " + parseInt(bounds[i])
      }
    }
    return res.slice(2)
  }

  const processSchema = (schema, lowerFilter, upperFilter) => {
    var res = ""
    for (var i = 0; i < schema.numElements; i++) {
      if (i >= schema.fields.length) {
        res = res + ", n/a"
        continue
      }
      if (lowerFilter[i] === 1 || lowerFilter[i] === "1" || upperFilter[i] === 1 || upperFilter[i] === "1") {
        res = res + ", " + schema.fields[i].fieldName
      } else {
        res = res + ", n/a"
      }
    }
    return res.slice(2)
  }

  const encodeAttestation = async (obj) => {
    const dataArray = [
      obj.sig.domain.version,
      obj.sig.domain.chainId,
      obj.sig.domain.verifyingContract,
      obj.sig.signature.r,
      obj.sig.signature.s,
      obj.sig.signature.v,
      obj.signer,
      obj.sig.uid,
      obj.sig.message.schema,
      "0",
      obj.sig.message.time,
      obj.sig.message.expirationTime,
      "0",
      obj.sig.message.revocable,
      obj.sig.message.data,
      obj.sig.message.nonce,
      obj.sig.message.version,
    ]

    // Convert the JSON array to a string
    const jsonString = JSON.stringify(dataArray);

    // Compress the JSON string
    deflate(jsonString, (err, buffer) => {
      if (err) {
        console.error('Deflate error:', err);
        return;
      }

      // Encode the compressed data into a Base64 string
      const base64Encoded = buffer.toString('base64');
      window.open(`https://easscan.org/offchain/url/#attestation=${querystring.escape(base64Encoded)}`, "_blank");
    })
  }


  // Use useEffect to call the backend API when the component mounts
  useEffect(() => {
    initializeFlow().then((response) => {
      if (response) {
        setData(response)
        flowId.current = response.flow_id
      }
    })
  }, [])

  const copyProof = () => {
    navigator.clipboard.writeText(JSON.stringify(data.source_proof, null, 2))
  }

  const copyAttestation = () => {
    navigator.clipboard.writeText(data.source_private_vc)
  }

  return (
    <Container>
      <Stack gap={1} mt={4}>
        <Typography variant="h4" textAlign="left" sx={{ mb: 1, width: 1, maxWidth: "450px" }}>
          View Proof
        </Typography>
        <Stack >
          <Typography variant="h6">
            Name:
          </Typography>
          <Typography variant="body1" ml={1}>
            {flowInfo["name"]}
          </Typography>
        </Stack>
        <Stack >
          <Typography variant="h6">
            Description:
          </Typography>
          <Typography variant="body1" ml={1}>
            {flowInfo["description"]}
          </Typography>
        </Stack>
        <Stack >
          <Typography variant="h6">
            Attester:
          </Typography>
          <Typography variant="body1" ml={1}>
            {data.attester}
          </Typography>
        </Stack>

        <Stack>
          <Stack direction="row" alignItems={'center'} gap={1}>
            <Typography variant="h6">
              Attestation:
            </Typography>
            <Tooltip title="The verifiable credential of your private data." placement="right">
              <Iconify height={20} width={20} icon="gg:info" sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </Stack>
          <TextField
            size="small"
            fullWidth
            multiline
            minRows={2}
            maxRows={10}
            variant="outlined"
            value={data.source_private_vc}
            contentEditable={false}
            onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
            sx={{ ml: '8px' }}
          />
          <Button sx={{ m: '8px' }} variant="outlined" size="small" onClick={copyAttestation}>Copy</Button>
          <Button sx={{ m: '8px' }} variant="outlined" size="small" onClick={() => { encodeAttestation(JSON.parse(data.source_private_vc)) }}>View on EAS</Button>
        </Stack>

        <Stack>
          <Stack direction="row" alignItems={'center'} gap={1}>
            <Typography variant="h6">
              Proof:
            </Typography>
            <Tooltip title="Evidence of your selectively disclosed information" placement="right">
              <Iconify height={20} width={20} icon="gg:info" sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </Stack>
          <TextField
            size="small"
            fullWidth
            multiline
            minRows={2}
            maxRows={10}
            variant="outlined"
            value={JSON.stringify(data.source_proof, null, 2)}
            contentEditable={false}
            onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
            sx={{ ml: '8px' }}
          />
          <Button sx={{ m: '8px' }} variant="outlined" size="small" onClick={copyProof}>Copy</Button>
          <Button sx={{ m: '8px' }} variant="outlined" size="small" onClick={() => verifyRangeProof(data.source_proof)}>Verify</Button>
        </Stack>

        {verifyResult && verifyResult.validated &&
          <>
            <Typography variant='h5' color="success.main" textAlign={"center"}>Proof Verified</Typography>
            <Stack >
              <Typography variant="h6">
                Attestation Name:
              </Typography>
              <Typography variant="body1" ml={1}>
                {vcInfo["attestation_name"]}
              </Typography>
            </Stack>
            <Stack>
              <Stack direction="row" alignItems={'center'} gap={1}>
                <Typography variant="h6">
                  Data Commitments:
                </Typography>
                <Tooltip title="This should match with commitments in the attestation." placement="right">
                  <Iconify height={20} width={20} icon="gg:info" sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={2}
                maxRows={10}
                variant="outlined"
                value={verifyResult.commitment[0] + "\n" + verifyResult.commitment[1]}
                contentEditable={false}
                onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
                sx={{ ml: '8px' }}
              />
            </Stack>
            <Stack>
              <Stack direction="row" alignItems={'center'} gap={1}>
                <Typography variant="h6">
                  Data Schema:
                </Typography>
                <Tooltip title="The sequence of data stored in this attestation." placement="right">
                  <Iconify height={20} width={20} icon="gg:info" sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={1}
                maxRows={10}
                variant="outlined"
                value={processSchema(vcInfo["schema"], verifyResult.lower_filter, verifyResult.upper_filter)}
                contentEditable={false}
                onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
                sx={{ ml: '8px' }}
              />
            </Stack>
            <Stack>
              <Stack direction="row" alignItems={'center'} gap={1}>
                <Typography variant="h6">
                  Data Lower Bounds:
                </Typography>
                <Tooltip title="The proof has revealed that certain data pieces within the attestation satisfy within a range." placement="right">
                  <Iconify height={20} width={20} icon="gg:info" sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={1}
                maxRows={10}
                variant="outlined"
                value={processRange(verifyResult.lower_filter, verifyResult.lower_bounds)}
                contentEditable={false}
                onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
                sx={{ ml: '8px' }}
              />
            </Stack>
            <Stack>
              <Stack direction="row" alignItems={'center'} gap={1}>
                <Typography variant="h6">
                  Data Upper Bounds:
                </Typography>
                <Tooltip title="The proof has revealed that certain data pieces within the attestation satisfy within a range." placement="right">
                  <Iconify height={20} width={20} icon="gg:info" sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={1}
                maxRows={10}
                variant="outlined"
                value={processRange(verifyResult.upper_filter, verifyResult.upper_bounds)}
                contentEditable={false}
                onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
                sx={{ ml: '8px' }}
              />
            </Stack>
          </>
        }
        {verifyResult && !verifyResult.validated &&
          <Typography variant='h5' color="error.main" textAlign={"center"}>Proof Invalid</Typography>
        }
      </Stack>
    </Container >
  );
}

export default ViewVerifications;
