// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import { useState, useEffect, useRef } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material';
import EthereumActivities from './activities/Ethereum'
import ZkSyncActivities from './activities/ZkSync'
import * as apis from '../utils/apirequests.js'
import * as cryptographic from '../utils/cryptographic.js';

const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginLeft: "16px",
    marginBottom: "40px",
    '& > *': { mx: '8px !important' },
} as const;

const tabStyle = {
    padding: '8px 0px',
    fontSize: 14,
}

const TABS = [
    {
        value: 'ethereum',
        label: 'Ethereum',
    },
    {
        value: 'zksync',
        label: 'zkSync',
    },
];

export default function Activities() {
    const userUuid = useRef<string | null>(null)
    const sessionInfo = useRef({
        token: null,
        expiresAt: 0,
    })
    const encryptionKey = useRef(null)

    const { user, setUser, session, setPage } = useUserContext()
    const [currentTab, setCurrentTab] = useState(sessionStorage.getItem("activity_active_tab") || "ethereum");

    async function authedBackendRequest(url = '', data = {}) {
        if (!sessionInfo.current.token) {
            return null
        }
        data['user_uuid'] = userUuid.current
        data['token'] = sessionInfo.current.token
        return apis.backendRequest(url, data)
    }

    async function updateUser(newUserData) {
        setUser(() => (newUserData))
        localStorage.setItem("userData", JSON.stringify(newUserData))
        authedBackendRequest("update_encrypted_user_data", {
            "encrypted_user_data": cryptographic.encryptText(
                JSON.stringify(newUserData),
                encryptionKey.current,
            ),
        });
    }

    // Whenever page loads, load locally stored data
    useEffect(() => {
        setPage("Activities")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Whenever local user state finds a new userUuid, get a valid token for the user
    useEffect(() => {
        if (user) {
            userUuid.current = user.userUuid
            encryptionKey.current = cryptographic.recoverShardedKeys([user.encryptionKeyLocal, JSON.parse(localStorage.getItem("encryptionKeyServer"))])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Whenever global session updates, update the local ref
    useEffect(() => {
        sessionInfo.current = session
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session])

    return (
        <Box sx={{ my: "20px", mx: "40px" }}>
            <Typography variant="h5" display="flex" justifyContent="left" sx={{ ml: "24px", mb: "24px" }}>ACTIVITIES</Typography>
            <Tabs sx={style} value={currentTab} onChange={(event, newValue) => {
                setCurrentTab(newValue)
                sessionStorage.setItem("activity_active_tab", newValue)
            }}>
                {TABS.map((tab) => (
                    <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                        disabled={false}
                        sx={tabStyle}
                    />
                ))}
            </Tabs>
            {currentTab === "ethereum" && <EthereumActivities updateUser={updateUser} />}
            {currentTab === "zksync" && <ZkSyncActivities updateUser={updateUser} />}
        </Box>
    );
}