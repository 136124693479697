import { useState, useEffect, useRef } from 'react'
// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import { Link } from 'react-router-dom'
import { Badge } from '@mui/material';
import IconButton from '@mui/material/Button';
import { CustomAvatar } from '../components/custom-avatar';
import * as merkle from '../utils/merkle.js';
import * as cryptographic from '../utils/cryptographic.js';
import * as apis from '../utils/apirequests.js'
import avatarImg from '../images/avatar.jpg'
import localforage from 'localforage';

const crypto = require('crypto');
const MIN_SECURE_ATTESTATION_TREE_SIZE = 1
const SECOND_MS = 1000;

const css = `
`

function User({ hideAvatar = false }) {
    const userUuid = useRef<string | null>(null)
    const sessionInfo = useRef({
        token: null,
        expiresAt: 0,
    })
    const { user, setUser, session, setSession } = useUserContext()

    const [authError, setAuthError] = useState(false)
    const firstMount = useRef(true)
    const loggingIn = useRef(false)
    const verifyingPendingWallets = useRef(false)
    const scanningPendingAccounts = useRef(false)
    const encryptionKey = useRef(null)
    const proofZkeys = useRef({})
    const pendingWalletProofs = useRef({})

    async function authedBackendRequest(url = '', data = {}) {
        if (!sessionInfo.current.token) {
            return null
        }
        data['user_uuid'] = userUuid.current
        data['token'] = sessionInfo.current.token
        return apis.backendRequest(url, data)
    }

    async function getProofZkey(proofType) {
        if (proofType in Object.keys(proofZkeys.current)) {
            return proofZkeys.current[proofType]
        }
        proofZkeys.current[proofType] = await cryptographic.getProofFile(proofType)
        return proofZkeys.current[proofType]
    }

    const signUp = async (watchingWallets = [], addressNicknames = {}) => {
        const userSecret = cryptographic.generateSecretAndHash()
        encryptionKey.current = crypto.randomBytes(32)
        const [encryptionKeyLocal, encryptionKeyServer, encryptionKeyBackup] = cryptographic.getShardedKeys(encryptionKey.current)

        var jsonData = null
        if (process.env.REACT_APP_TEST_ACCOUNT !== "false") {
            jsonData = await apis.backendRequest("sign_up", {
                "user_secret_hash": userSecret.secret_hash,
                "encryption_key_server": encryptionKeyServer,
                "test_account": true,
            });
        } else {
            jsonData = await apis.backendRequest("sign_up", {
                "user_secret_hash": userSecret.secret_hash,
                "encryption_key_server": encryptionKeyServer,
                "test_account": false,
            });
        }

        if (jsonData && jsonData.user_uuid) {
            // Update User
            var userData = {
                "userUuid": jsonData.user_uuid,
                "userSecret": userSecret,
                "userAccountRoot": jsonData.user_account_root,
                "encryptionKeyLocal": encryptionKeyLocal,
                "version": jsonData.version,
                "maxAccounts": jsonData.max_accounts,
                "wallets": [],
                "pendingWallets": {},
                "watchingWallets": watchingWallets,
                "addressNicknames": addressNicknames,
            }

            userUuid.current = jsonData.user_uuid
            setUser(userData)
            console.log(userData)
            localStorage.setItem("userData", JSON.stringify(userData))
            apis.backendRequest("update_encrypted_user_data", {
                "user_uuid": jsonData.user_uuid,
                "token": jsonData.session.token,
                "encrypted_user_data": cryptographic.encryptText(JSON.stringify(userData), encryptionKey.current),
            });

            // Update encryption keys
            localStorage.setItem("encryptionKeyServer", JSON.stringify(encryptionKeyServer))
            localStorage.setItem("encryptionKeyBackup", JSON.stringify({
                "userUuid": jsonData.user_uuid,
                "key": encryptionKeyBackup,
            }))

            // Update session
            const newSession = {
                "token": jsonData.session.token,
                "expiresAt": jsonData.session.expires_at,
            }
            sessionInfo.current = newSession
            setSession(sessionInfo.current)
            localStorage.setItem("sessionData", JSON.stringify(newSession))
            return
        }
    };

    const verifyUser = async (userData) => {
        const jsonData = await apis.backendRequest("get_user_metadata", {
            "user_uuid": userUuid.current,
        });

        // Calculate wallet merkle root with local wallets information and compare
        if (jsonData && jsonData.wallet_merkle_root) {
            var walletElements = new Array(128).fill(0);
            walletElements[0] = userData.userSecret.secret_hash
            for (var i = 0; i < userData.wallets.length; i++) {
                walletElements[i + 1] = cryptographic.generateWalletHash(userData.wallets[i].chainId, userData.wallets[i].walletAddress)
            }
            const walletMerkleRoot = merkle.calculateMerkleRoot(walletElements, 7).toString()
            if (walletMerkleRoot === jsonData.wallet_merkle_root) {
                localStorage.setItem("encryptionKeyServer", JSON.stringify(jsonData.encryption_key_server))
                return
            }
        }

        // Calculate wallet merkle root with local wallets information and compare
        if (jsonData && jsonData.user_account_root) {
            var accountElements = new Array(jsonData.max_accounts).fill(0);
            for (var j = 0; j < userData.wallets.length; j++) {
                accountElements[j] = cryptographic.generateWalletHash(userData.wallets[j].chainId, userData.wallets[j].walletAddress)
            }
            const userAccountRoot = merkle.calculateUserAccountRoot(
                accountElements,
                Math.log2(jsonData.max_accounts),
                userData.userSecret.secret_hash,
            ).toString()
            if (userAccountRoot === jsonData.user_account_root && userData.maxAccounts === jsonData.max_accounts) {
                localStorage.setItem("encryptionKeyServer", JSON.stringify(jsonData.encryption_key_server))
                userData.twitter_username = jsonData.twitter_username
                userData.reddit_username = jsonData.reddit_username
                return
            }
        }
        throw new Error('Failed to verify local data.');
    };

    const loginOrRefreshToken = async (forceRefresh = false) => {
        if (loggingIn.current || !userUuid.current || !user) {
            return
        }
        loggingIn.current = true
        var has_valid_session = false

        // Session exists and has not expired yet
        if (sessionInfo.current && sessionInfo.current.expiresAt > Date.now()) {
            has_valid_session = true
            if (forceRefresh || sessionInfo.current.expiresAt <= Date.now() + 60000 /* Expiring in 1 minute */) {
                // Try to refresh token if expiring in 1 minute
                const responseJson = await apis.backendRequest("refresh_token", {
                    "user_uuid": userUuid.current,
                    "token": sessionInfo.current.token,
                });

                // Response can be decoded, user authentication was successful
                if (responseJson && responseJson.token) {
                    // If new token is issued, update session and local storage
                    if (responseJson.token !== sessionInfo.current.token) {
                        const newSession = {
                            "token": responseJson.token,
                            "expiresAt": responseJson.expires_at,
                        }
                        sessionInfo.current = newSession
                        setSession(sessionInfo.current)
                        localStorage.setItem("sessionData", JSON.stringify(newSession))
                    }
                } else {
                    sessionInfo.current = { token: null, expiresAt: 0 }
                    setSession(sessionInfo.current)
                    localStorage.removeItem("sessionData")
                    has_valid_session = false
                }
            }
        }

        if (!has_valid_session) {
            // Generate a zk proof and retrieve a session token
            var proof = null
            var accountElements = new Array(user.maxAccounts).fill(0);
            for (var i = 0; i < user.wallets.length; i++) {
                accountElements[i] = cryptographic.generateWalletHash(user.wallets[i].chainId, user.wallets[i].walletAddress)
            }
            const accountMerkleRoot = merkle.calculateMerkleRoot(accountElements, Math.log2(user.maxAccounts),)
            const randomizer = cryptographic.generateSecretAndHash()
            const timestamp = Date.now()
            proof = await cryptographic.generateUserLoginProof(
                await getProofZkey("g_user_login_new"),
                user.userSecret.secret,
                accountMerkleRoot,
                randomizer.secret,
                timestamp,
            )

            // Post the login proof to backend to validate
            const responseJson = await apis.backendRequest("login", {
                "user_uuid": userUuid.current,
                "proof": proof.proof,
                "public_signals": proof.publicResults,
            });

            if (!responseJson || !responseJson.token) {
                // Proof has not been verified, remove session
                sessionInfo.current = { token: null, expiresAt: 0 }
                setSession(sessionInfo.current)
                localStorage.removeItem("sessionData")
            } else {
                // Proof has been verified, set session
                const newSession = {
                    "token": responseJson.token,
                    "expiresAt": responseJson.expires_at,
                }
                sessionInfo.current = newSession
                setSession(sessionInfo.current)
                localStorage.setItem("sessionData", JSON.stringify(newSession))
            }
        }

        loggingIn.current = false
    }

    // Get tree size of the specific attestation tree
    const getAttestationTreeSize = async (treeIdx) => {
        const jsonData = await apis.backendRequest("get_account_owners_merkle_tree_size", {
            "tree_idx": treeIdx,
        });
        return jsonData ? jsonData["tree_size"] : -1;
    };

    // Get the contents of the specific attestation tree
    const getAttestationTree = async (treeIdx) => {
        const jsonData = await apis.backendRequest("get_account_owners_merkle_tree", {
            "tree_idx": treeIdx,
        });
        return jsonData;
    };

    const updateUserAccountRoot = async (
        userUuid,
        treeIdx,
        accountOwnershipProof,
        accountOwnershipPublicSignals,
        rootUpdateProof,
        rootUpdatePublicSignals,
    ) => {
        const jsonData = await apis.backendRequest("update_user_account_root", {
            "user_uuid": userUuid,
            "tree_idx": treeIdx,
            "account_ownership_proof": accountOwnershipProof,
            "account_ownership_public_signals": accountOwnershipPublicSignals,
            "root_update_proof": rootUpdateProof,
            "root_update_public_signals": rootUpdatePublicSignals,
        });
        return jsonData;
    };

    const expandUserAccountRoot = async (
        userUuid,
        treeIdx,
        accountOwnershipProof,
        accountOwnershipPublicSignals,
        rootExpandProof,
        rootExpandPublicSignals,
    ) => {
        const jsonData = await apis.backendRequest("expand_user_account_root", {
            "user_uuid": userUuid,
            "tree_idx": treeIdx,
            "account_ownership_proof": accountOwnershipProof,
            "account_ownership_public_signals": accountOwnershipPublicSignals,
            "root_expand_proof": rootExpandProof,
            "root_expand_public_signals": rootExpandPublicSignals,
        });
        return jsonData;
    };

    const generateAccountUpdateProof = async (
        maxAccounts,
        allAccountElements,
        walletIndex,
        accountHash,
        chainId,
        walletAddress,
        ownerSecret,
        attestationTreeIdx,
        attestationPath,
    ) => {
        // Use the current wallets to generate the merkle path from the next empty element
        const treeDepth = Math.log2(maxAccounts)
        var accountElements = new Array(maxAccounts).fill(0);
        for (var i = 0; i < walletIndex; i++) {
            accountElements[i] = allAccountElements[i]
        }
        const accountMerklePath = merkle.calculateMerklePath(accountElements, walletIndex, treeDepth)

        // Replace the next empty element with the new wallet into the array and generate the new merkle path
        const newAccountHash = allAccountElements[walletIndex]
        accountElements[walletIndex] = newAccountHash
        const newAccountMerklePath = merkle.calculateMerklePath(accountElements, walletIndex, treeDepth)

        // Generate zk proof about the update
        const accountOwnershipProof = cryptographic.generateAccountOwnershipAttestedProof(
            await getProofZkey("g_user_account_ownership_attested"),
            newAccountHash,
            ownerSecret,
            attestationPath.path,
            attestationPath.pathIndices,
        )
        const rootUpdateProof = cryptographic.generateUserAccountRootUpdateProof(
            await getProofZkey(`g_user_account_root_update_${maxAccounts}`),
            maxAccounts,
            user.userSecret.secret_hash,
            newAccountHash,
            accountMerklePath.path,
            accountMerklePath.pathIndices,
            newAccountMerklePath.pathIndices,
            ownerSecret,
        )
        const proofs = await Promise.all([accountOwnershipProof, rootUpdateProof])
        return [
            walletIndex,
            attestationTreeIdx,
            accountHash,
            chainId,
            walletAddress,
            "update",
            proofs,
        ]
    }

    const generateAccountExpandProof = async (
        maxAccounts,
        allAccountElements,
        walletIndex,
        accountHash,
        chainId,
        walletAddress,
        ownerSecret,
        attestationTreeIdx,
        attestationPath,
    ) => {
        // Existing tree full, expand the user's account merkle tree

        // Use the current wallets to generate the merkle path from the next empty element
        var newAccountElements = new Array(maxAccounts * 2).fill(0);
        for (var k = 0; k < walletIndex; k++) {
            newAccountElements[k] = allAccountElements[k]
        }

        // Replace the next empty element with the new wallet into the array and generate the new merkle path
        const newAccountHash = allAccountElements[walletIndex]
        newAccountElements[walletIndex] = newAccountHash
        const newAccountMerklePath = merkle.calculateMerklePath(newAccountElements, walletIndex, Math.log2(maxAccounts) + 1)

        // Generate zk proof about the update
        const accountOwnershipProof = cryptographic.generateAccountOwnershipAttestedProof(
            await getProofZkey("g_user_account_ownership_attested"),
            newAccountHash,
            ownerSecret,
            attestationPath.path,
            attestationPath.pathIndices,
        )
        const rootExpandProof = cryptographic.generateUserAccountRootExpandProof(
            await getProofZkey(`g_user_account_root_expand_${maxAccounts}`),
            maxAccounts,
            user.userSecret.secret_hash,
            newAccountHash,
            newAccountMerklePath.path,
            newAccountMerklePath.pathIndices,
            ownerSecret,
        )
        const proofs = await Promise.all([accountOwnershipProof, rootExpandProof])
        return [
            walletIndex,
            attestationTreeIdx,
            accountHash,
            chainId,
            walletAddress,
            "expand",
            proofs,
        ]
    }

    const generateAllPendingAccountProofs = async () => {
        if (scanningPendingAccounts.current) return false
        scanningPendingAccounts.current = true

        // Calculate the new maxAccounts after all pending wallets are verified
        const allAccountsLength = user.wallets.length + Object.keys(user.pendingWallets).length
        const newMaxAccounts = Math.max(2, Math.pow(2, Math.ceil(Math.log2(allAccountsLength))))

        // Calculate all accountHashes including the pending wallets
        var allAccountElements = new Array(newMaxAccounts).fill(0);
        for (var i = 0; i < user.wallets.length; i++) {
            allAccountElements[i] = cryptographic.generateWalletHash(user.wallets[i].chainId, user.wallets[i].walletAddress)
        }
        for (const contentId in user.pendingWallets) {
            const content = user.pendingWallets[contentId]
            const chainId = content.chainId
            const walletAddress = content.walletAddress
            const walletIndex = content.walletIndex
            allAccountElements[walletIndex] = cryptographic.generateWalletHash(chainId, walletAddress)
        }

        // Go over all pending accounts and generate proof for each of them
        for (const contentId in user.pendingWallets) {
            const content = user.pendingWallets[contentId]
            const ownerSecret = content.secret
            const attestationTreeIdx = content.attestationTreeIdx
            const attestationIdx = content.attestationIdx
            const walletIndex = content.walletIndex
            const chainId = content.chainId
            const walletAddress = content.walletAddress

            if (walletIndex in pendingWalletProofs.current) {
                // PendingWallet is already being proved
                continue
            }

            // Get the attestation tree attested user's ownership over the wallet and generate attestation merkle path
            const treeSize = await getAttestationTreeSize(attestationTreeIdx)
            if (treeSize < MIN_SECURE_ATTESTATION_TREE_SIZE) {
                scanningPendingAccounts.current = false
                return false
            }
            const tree = await getAttestationTree(attestationTreeIdx)
            if (!tree) {
                scanningPendingAccounts.current = false
                return false
            }
            const attestationPath = merkle.calculateMerklePath(tree.elements, attestationIdx, 4)

            // Hasn't filled up all empty wallet slots yet, update existing merkle tree
            const currentMaxAccounts = Math.max(2, Math.pow(2, Math.ceil(Math.log2(walletIndex))))
            if (walletIndex < currentMaxAccounts) {
                const updateProof = generateAccountUpdateProof(
                    currentMaxAccounts,
                    allAccountElements,
                    walletIndex,
                    contentId,
                    chainId,
                    walletAddress,
                    ownerSecret,
                    attestationTreeIdx,
                    attestationPath,
                )
                pendingWalletProofs.current[walletIndex] = updateProof
            } else {
                const expandProof = generateAccountExpandProof(
                    currentMaxAccounts,
                    allAccountElements,
                    walletIndex,
                    contentId,
                    chainId,
                    walletAddress,
                    ownerSecret,
                    attestationTreeIdx,
                    attestationPath,
                )
                pendingWalletProofs.current[walletIndex] = expandProof
            }
        }
        scanningPendingAccounts.current = false
        return true
    }

    // Look through all pendingWallets stored in user state and try to verify them
    const verifyPendingWallets = async () => {
        if (!user || !user.pendingWallets || Object.keys(user.pendingWallets).length === 0 || verifyingPendingWallets.current) {
            return
        }
        verifyingPendingWallets.current = true

        try {
            // Async parallel generate all the pending wallet proofs
            const proofGenerationRun = await generateAllPendingAccountProofs()
            if (!proofGenerationRun) {
                verifyingPendingWallets.current = false
                return
            }

            // Start writing a new user object
            var newUserData = user

            // Calculate the new maxAccounts after all pending wallets are verified
            const allAccountsLength = user.wallets.length + Object.keys(user.pendingWallets).length
            const newMaxAccounts = Math.max(2, Math.pow(2, Math.ceil(Math.log2(allAccountsLength))))
            newUserData.maxAccounts = newMaxAccounts

            const allProofs: any[][] = await Promise.all(Object.values(pendingWalletProofs.current))
            allProofs.sort((a, b) => a[0] - b[0])

            for (const oneProof of allProofs) {
                const [
                    walletIndex,
                    attestationTreeIdx,
                    accountHash,
                    chainId,
                    walletAddress,
                    method,
                    proofs,
                ] = oneProof
                var updateResponse = null
                if (method === "update") {
                    // Send proof to backend to validate the update
                    updateResponse = await updateUserAccountRoot(
                        userUuid.current,
                        attestationTreeIdx,
                        proofs[0].proof,
                        proofs[0].publicResults,
                        proofs[1].proof,
                        proofs[1].publicResults,
                    )
                } else if (method === "expand") {
                    updateResponse = await expandUserAccountRoot(
                        userUuid.current,
                        attestationTreeIdx,
                        proofs[0].proof,
                        proofs[0].publicResults,
                        proofs[1].proof,
                        proofs[1].publicResults,
                    )
                }
                if (!updateResponse || !updateResponse.is_verified) {
                    verifyingPendingWallets.current = false
                    return
                }

                const new_user_account_root = updateResponse.new_user_account_root
                // If backend successfully validates, add the wallet into wallets list, remove from pending wallets
                delete newUserData.pendingWallets[accountHash]
                if (!newUserData.wallets) {
                    newUserData.wallets = []
                }
                newUserData.wallets.push({
                    "chainId": chainId,
                    "walletAddress": walletAddress,
                })
                newUserData.userAccountRoot = new_user_account_root

                delete pendingWalletProofs.current[walletIndex]
            }

            // eslint-disable-next-line no-loop-func
            setUser(existingValues => ({
                ...existingValues,
                "pendingWallets": newUserData.pendingWallets,
                "wallets": newUserData.wallets,
                "walletMerkleRoot": newUserData.walletMerkleRoot,
                "userAccountRoot": newUserData.userAccountRoot,
            }))
            localStorage.setItem("userData", JSON.stringify(newUserData))
            authedBackendRequest("update_encrypted_user_data", {
                "encrypted_user_data": cryptographic.encryptText(JSON.stringify(newUserData), encryptionKey.current),
            });
        } catch (e) {
            console.log(e)
        }
        verifyingPendingWallets.current = false
    }

    // Whenever page loads, validate the local user is valid
    useEffect(() => {
        if (firstMount.current) {
            firstMount.current = false
            const userData: any = JSON.parse(localStorage.getItem("userData"))
            if (userData) {
                userUuid.current = userData.userUuid
            }
            const sessionData: any = JSON.parse(localStorage.getItem("sessionData"))
            if (sessionData) {
                sessionInfo.current = sessionData
            }

            if (userUuid.current) {
                if (userData.version && userData.version >= 100) {
                    verifyUser(userData)
                        .catch(() => {
                            setAuthError(true)
                            setUser(null)
                        })
                    setSession(sessionInfo.current)
                    setUser(userData)
                } else {
                    const watchingWallets = [userData.wallets || [], userData.watchingWallets || []].flat()
                    for (const walletId in userData.pendingWallets) {
                        const pendingWallet = userData.pendingWallets[walletId]
                        watchingWallets.push({
                            chainId: pendingWallet.chainId,
                            walletAddress: pendingWallet.walletAddress,
                        })
                    }
                    localStorage.clear()
                    localforage.clear()
                    signUp(watchingWallets, userData.addressNicknames || {})
                }
            } else {
                signUp()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Whenever local user state finds an update on the user, get a valid token for the user
    // And trigger a pending wallet verification
    useEffect(() => {
        if (user) {
            setAuthError(false)
            userUuid.current = user.userUuid
            sessionInfo.current = session
            encryptionKey.current = cryptographic.recoverShardedKeys([user.encryptionKeyLocal, JSON.parse(localStorage.getItem("encryptionKeyServer"))])
            loginOrRefreshToken().then(() => verifyPendingWallets())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Try to refresh token every minute
    useEffect(() => {
        const interval = setInterval(loginOrRefreshToken, 60 * SECOND_MS);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Try to verify all pending wallets a user has every 15 seconds
    useEffect(() => {
        const interval = setInterval(verifyPendingWallets, 15 * SECOND_MS);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])


    return (
        <>
            <style>
                {css}
            </style>

            {user && !hideAvatar && <div>
                {authError && <div>Error getting user, refresh the page and try again.</div>}

                <Link to='/account/wallets'
                    key='Account'
                    style={{ textDecoration: 'none', }}
                >
                    <IconButton>
                        <Badge>
                            <CustomAvatar alt="Manage Account" src={avatarImg} />
                        </Badge>
                    </IconButton>
                </Link>
            </div>
            }
        </>
    )
}

export default User