import { useEffect } from 'react'
// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import { Box, Grid, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import robinhoodImg from '../images/robinhood.png'
import TwitterOAuth from './social/TwitterOAuth';
import RedditOAuth from './social/RedditOAuth';
import Alert from '@mui/material/Alert';

function Proofs() {
  const { setPage } = useUserContext()

  useEffect(() => {
    setPage("Proofs")
  }, [setPage])

  const handleStartVerify = () => {
    window.open("../prove?flowid=104", "_self");
  }

  return (
    <Box sx={{ p: '50px' }}>
      <Alert severity="info">
        Thanks for using Jomo. We use cryptographic technologies to always keep your data secure and private while you claim the attestations. <br />
        Follow us on <a href="https://twitter.com/0xJomoID">Twitter</a> to keep updated.
      </Alert>

      <Typography variant="h4" textAlign="left" sx={{ maxWidth: "800px", my: '20px', px: '20px' }}>
        Claim Attestation
      </Typography>

      <Grid container spacing={1} columns={{ xs: 4, sm: 6, md: 6 }}>
        <Grid>
          <Card sx={{ maxWidth: 600, p: '20px', mx: '20px', my: '20px' }}>
            <CardContent>
              <Stack direction="row" spacing={2} alignItems='center'>
                <Avatar alt="Robinhood" src={robinhoodImg} />
                <Typography gutterBottom variant="h5" component="div">
                  Robinhood Investment Return
                </Typography>
              </Stack>
              <Typography variant="body2" color="text.secondary" sx={{ my: '20px' }}>
                Attest investment return on a weekly / monthly / yearly basis.
              </Typography>
            </CardContent>
            <CardActions sx={{ px: '20px' }}>
              <Button variant="contained" size="small" onClick={() => handleStartVerify()}>Attest</Button>
              {/* <Button variant="outlined" size="small">Share</Button> */}
            </CardActions>
          </Card>
        </Grid>

        {/* <Grid>
          <Card sx={{ maxWidth: 600, p: '20px', mx: '20px', my: '20px' }}>
            <CardContent>
              <Stack direction="row" spacing={2} alignItems='center'>
                <Avatar alt="Robinhood" src={robinhoodImg} />
                <Typography gutterBottom variant="h5" component="div">
                  Robinhood - Investment ROI
                </Typography>
              </Stack>
              <Typography variant="body2" color="text.secondary" sx={{ my: '20px' }}>
                Verify investment return on a yearly / monthly / weekly / daily basis.
              </Typography>
            </CardContent>
            <CardActions sx={{ px: '20px' }}>
              <Button variant="contained" size="small">Start Verify</Button>
              <Button variant="outlined" size="small">Share</Button>
            </CardActions>
          </Card>
        </Grid> */}
      </Grid>

      <Typography variant="h4" textAlign="left" sx={{ maxWidth: "800px", my: '20px', px: '20px' }}>
        Social Accounts
      </Typography>

      <Box sx={{ my: '20px', px: '20px' }}>
        <Stack direction="column" spacing={1} alignItems='flex-start'>
          <Box sx={{ minWidth: 500, border: 1, px: '40px', py: '8px', borderRadius: "16px", borderColor: "secondary.light" }}>
            <TwitterOAuth showAtAccountPage={true} />
          </Box>
          <Box sx={{ minWidth: 500, border: 1, px: '40px', py: '8px', borderRadius: "16px", borderColor: "secondary.light" }}>
            <RedditOAuth showAtAccountPage={true} />
          </Box>
        </Stack>
      </Box>

    </Box>
  )
}

export default Proofs