// @ts-ignore
import { useUserContext } from '../../context/UserContext.tsx';
import { useState, useEffect, useRef, useCallback } from 'react'
import { Container, Card, Box, Stack, Typography, CircularProgress, IconButton, Tooltip, Button } from '@mui/material';
import SvgColor from '../../components/svg-color';
import {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  GridValueSetterParams,
  GridRowModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import CustomDataGrid from '../../components/datagrid/CustomDataGrid';
import NewWallet from '../NewWallet'
import EthereumAggregated from './EthereumAggregated'
import * as ethereum from '../../utils/data/ethereum.js'
import * as activityutils from '../../utils/activityutils.js'
import ethImg from '../../images/ethereum.png';
import Iconify from '../../components/iconify/Iconify';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import { useSnackbar } from '../../components/snackbar';
import * as common from './common'
import * as apis from '../../utils/apirequests.js'

const css = `
`

export default function EthereumActivities({ updateUser }) {
  const { user } = useUserContext()
  const [ethereumData, setEthereumData] = useState({})

  const firstMount = useRef(true)
  const initLoaded = useRef(false)
  const addressPulling = useRef({})
  const [addressPullingState, setAddressPulling] = useState({})

  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const apiRef = useGridApiRef();

  const pullEthereumDataForWallet = async function (wallet) {
    if (addressPulling.current[wallet]) {
      return
    }
    addressPulling.current[wallet] = true
    setAddressPulling(existingValues => ({
      ...addressPulling.current,
    }))

    const ethETHBalance = ethereum.getEthBalance(wallet, "mainnet")
    const ethTxCount = ethereum.getTxCount(wallet, "mainnet")
    const ethTx = ethereum.getTxs(wallet, "eth-mainnet")
    const ethTokenBalance = ethereum.getBalances(wallet, "eth-mainnet")
    const ethTxCountPercentiles = await apis.backendRequest('eth/get_tx_count_percentiles', {})

    const data = {}
    ethETHBalance.then((result) => { data["ethETHBalance"] = result })
    ethTxCount.then((result) => {
      data["ethTxCount"] = result
      data["ethTxCountPercentile"] = common.getPercentile(result, ethTxCountPercentiles, 100)
    })

    ethTokenBalance.then((tokens) => {
      if (!tokens) return
      data["ethTotalBalance"] = 0
      for (const tokenId in tokens) {
        const token = tokens[tokenId]
        data["ethTotalBalance"] += token["quote_rate"] * parseInt(token["balance"], 10) / 10 ** token["contract_decimals"]
      }
    })

    ethTx.then((resultList) => {
      var lastTxTime = 0
      var days = {}
      var weeks = {}
      var months = {}
      var gasFee = 0.0

      for (const txId in resultList) {
        const tx = resultList[txId]
        const txData = ethereum.understandTransaction(tx, wallet)
        if (!txData) continue

        // Last Tx
        if (txData["block_signed_at"] > lastTxTime) {
          lastTxTime = txData["block_signed_at"]
        }

        // Unique Days
        if (!days[txData["day"]]) {
          days[txData["day"]] = 0
        }
        days[txData["day"]] = days[txData["day"]] + 1

        // Unique Weeks
        if (!weeks[txData["week"]]) {
          weeks[txData["week"]] = 0
        }
        weeks[txData["week"]] = weeks[txData["week"]] + 1

        // Unique Months
        if (!months[txData["month"]]) {
          months[txData["month"]] = 0
        }
        months[txData["month"]] = months[txData["month"]] + 1

        // Total Fee
        gasFee += txData["fees_paid"]
      }
      data["ethLastTx"] = lastTxTime === 0 ? null : lastTxTime
      data["ethDaysCount"] = Object.keys(days).length
      data["ethWeeksCount"] = Object.keys(weeks).length
      data["ethMonthsCount"] = Object.keys(months).length
      data["ethGasFee"] = gasFee
    })
    await Promise.all([ethETHBalance, ethTxCount, ethTx, ethTokenBalance])

    data["updatedAt"] = Date.now()

    addressPulling.current[wallet] = false
    setAddressPulling(existingValues => ({
      ...addressPulling.current,
    }))

    return data
  }

  const updateEthereumDataForWallet = async function (wallet) {
    const data = await pullEthereumDataForWallet(wallet)
    const newEthereumData = ethereumData
    newEthereumData[wallet] = data
    setEthereumData(() => (newEthereumData))
    localStorage.setItem("ethereumData", JSON.stringify(newEthereumData))
  }

  const getEthereumRows = function (data) {
    const rows = []
    for (const wallet in data) {
      rows.push({
        id: wallet,
        nickname: common.getAddressNickname(wallet, user) || "",
        ethETHBalance: data[wallet]["ethETHBalance"] || 0.0,
        ethTotalBalance: data[wallet]["ethTotalBalance"] || 0.0,
        ethTxCount: data[wallet]["ethTxCount"] || 0,
        ethTxCountPercentile: data[wallet]["ethTxCountPercentile"] || 0,
        ethLastTx: data[wallet]["ethLastTx"],
        ethDaysCount: data[wallet]["ethDaysCount"] || 0,
        ethWeeksCount: data[wallet]["ethWeeksCount"] || 0,
        ethMonthsCount: data[wallet]["ethMonthsCount"] || 0,
        ethGasFee: data[wallet]["ethGasFee"] || 0.0,
        updatedAt: data[wallet]["updatedAt"],
      })
    }
    return rows
  }

  const getEthereumColumns = function () {
    const columns: GridColDef[] = [
      {
        field: 'id',
      },
      {
        field: 'updatedAt',
        renderHeader: () => common.RenderHeader('Last Updated'),
        headerClassName: 'column-header',
        editable: false,
        sortable: true,
        minWidth: 150,
        flex: 1,
        headerAlign: 'center',
        renderCell: (params) => (
          <Stack spacing={0.5} direction="row" justifyContent="left" alignItems="center" sx={{ height: 1, width: 1 }}>
            {!addressPullingState[params.row.id] &&
              <IconButton color="primary" onClick={() => updateEthereumDataForWallet(params.row.id)}>
                <Iconify icon="mdi:refresh" color="primary" width={20} />
              </IconButton>
            }
            {addressPullingState[params.row.id] &&
              <IconButton color="primary" disabled>
                <CircularProgress color="primary" size={20} />
              </IconButton>
            }
            <Typography variant="body1">{common.formatTime(params.value)}</Typography>
          </Stack>
        ),
      },
      {
        field: 'nickname',
        renderHeader: () => common.RenderHeader('Address'),
        headerClassName: 'column-header',
        minWidth: 200,
        flex: 1,
        editable: true,
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          const address = params.row.id
          const nickname = common.getAddressNickname(address, user)

          return (
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 1, height: 1, width: 1 }}>
              <Typography variant="body1">
                {nickname}
                {!nickname && common.getTruncatedAddress(address)}
              </Typography>
              <Stack spacing={0} direction="row" justifyContent="center" alignItems="center">
                <Tooltip title={`Edit nickname`}>
                  <IconButton onClick={() => { apiRef.current.startCellEditMode({ id: address, field: 'nickname' }) }} sx={{ margin: 0, padding: "3px" }}>
                    <Iconify icon="mdi:edit-outline" width={18} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`${address} (Click to Copy!)`}>
                  <IconButton onClick={() => {
                    copy(address).then((success) => { if (success) enqueueSnackbar("Copied!", { variant: "info" }) })
                  }} sx={{ margin: 0, padding: "3px" }}>
                    <Iconify icon="eva:copy-fill" width={18} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          )
        },
        valueSetter: (params: GridValueSetterParams) => {
          const nickname = params.value
          return { ...params.row, nickname };
        },
      },
      {
        field: 'ethETHBalance',
        renderHeader: () => common.RenderHeader('ETH', "ETH balance on Ethereum"),
        headerClassName: 'column-header',
        type: 'number',
        minWidth: 75,
        flex: 1,
        editable: false,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params) => common.RenderBalance(params.value),
      },
      {
        field: 'ethTotalBalance',
        renderHeader: () => common.RenderHeader('Total Assets', "Aggregated balance in USD for all ERC-20 tokens held on Ethereum"),
        headerClassName: 'column-header',
        type: 'number',
        minWidth: 120,
        flex: 1,
        editable: false,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params) => common.RenderDollarBalance(params.value),
      },
      {
        field: 'ethTxCount',
        renderHeader: () => common.RenderHeader('# Tx', "Number of transactions on Ethereum"),
        headerClassName: 'column-header',
        type: 'number',
        minWidth: 80,
        flex: 1,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => common.RenderPercentile2(params.row.ethTxCount.toString(), params.row.ethTxCountPercentile, 1.0),
      },
      {
        field: 'ethLastTx',
        renderHeader: () => common.RenderHeader('Last Tx', "Time of last transactions on Ethereum"),
        headerClassName: 'column-header',
        editable: false,
        sortable: true,
        minWidth: 120,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params) => (
          <Typography variant="body1">{common.formatTime(params.value)}</Typography>
        ),
      },
      {
        field: 'ethDaysCount',
        renderHeader: () => common.RenderHeader('Days', "Number of days with active transactions on Ethereum"),
        headerClassName: 'column-header',
        type: 'number',
        minWidth: 70,
        flex: 1,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => common.RenderPercentile(params.row.ethDaysCount.toString(), 0),
      },
      {
        field: 'ethWeeksCount',
        renderHeader: () => common.RenderHeader('Weeks', "Number of weeks with active transactions on Ethereum"),
        headerClassName: 'column-header',
        type: 'number',
        minWidth: 70,
        flex: 1,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => common.RenderPercentile(params.row.ethWeeksCount.toString(), 0),
      },
      {
        field: 'ethMonthsCount',
        renderHeader: () => common.RenderHeader('Months', "Number of months with active transactions on Ethereum"),
        headerClassName: 'column-header',
        type: 'number',
        minWidth: 80,
        flex: 1,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => common.RenderPercentile(params.row.ethMonthsCount.toString(), 0),
      },
      {
        field: 'ethGasFee',
        renderHeader: () => common.RenderHeader('Gas', "Total gas fee spent on transactions on Ethereum"),
        headerClassName: 'column-header',
        width: 100,
        flex: 0,
        editable: false,
        align: 'right',
        headerAlign: 'center',
        valueGetter: (params: GridValueGetterParams) => {
          if (params.row.ethGasFee && params.row.ethGasFee > 0.000) {
            return params.row.ethGasFee.toFixed(3)
          }
          return 0
        },
        renderCell: (params) => (
          <Stack spacing={0.5} direction="row" alignItems="center" sx={{ px: 1, height: 1 }}>
            <Typography variant="body1">{params.value}</Typography>
            <SvgColor src={ethImg} sx={{ px: 1, width: 1 }} />
          </Stack>
        ),
      },
    ];
    return columns
  }

  const getEthereumColumnGrouping = function () {
    return [
      {
        groupId: 'Assets',
        align: 'center',
        headerClassName: 'column-header-group',
        renderHeaderGroup: () => common.RenderHeaderGroup('Assets'),
        headerAlign: 'center',
        children: [{ field: 'ethETHBalance' }, { field: 'ethTotalBalance' }],
      },
      {
        groupId: 'Transactions',
        align: 'center',
        headerClassName: 'column-header-group',
        renderHeaderGroup: () => common.RenderHeaderGroup('Transactions'),
        headerAlign: 'center',
        children: [{ field: 'ethTxCount' }],
      },
      {
        groupId: 'Active',
        align: 'center',
        headerClassName: 'column-header-group',
        renderHeaderGroup: () => common.RenderHeaderGroup('Active'),
        headerAlign: 'center',
        children: [{ field: 'ethDaysCount' }, { field: 'ethWeeksCount' }, { field: 'ethMonthsCount' }],
      },
      {
        groupId: 'Fees',
        align: 'center',
        headerClassName: 'column-header-group',
        renderHeaderGroup: () => common.RenderHeaderGroup('Fees'),
        headerAlign: 'center',
        children: [{ field: 'ethGasFee' }],
      },
    ]
  }

  const updateEthereumDataForNewWallets = async function (oldData, forceRefresh = false) {
    if (!user || !initLoaded.current) {
      return
    }
    const [newEthereumData, newWallets, removeWallets] = activityutils.updateDataAndNewWallets(oldData, "evm", user)

    //@ts-ignore
    if (newWallets.length === 0) {
      if (removeWallets.length > 0) {
        setEthereumData(() => (newEthereumData))
        localStorage.setItem("ethereumData", JSON.stringify(newEthereumData))
      }
      return
    }

    const pulls = []
    const newData = newEthereumData
    if (forceRefresh) {
      for (const wallet in newEthereumData) {
        const walletData = pullEthereumDataForWallet(wallet)
        walletData.then((data) => { newData[wallet] = data })
        pulls.push(walletData)
      }
    } else {
      for (const walletId in newWallets) {
        const walletData = pullEthereumDataForWallet(newWallets[walletId])
        walletData.then((data) => { newData[newWallets[walletId]] = data })
        pulls.push(walletData)
      }
    }
    await Promise.all(pulls)
    setEthereumData(() => (newData))
    localStorage.setItem("ethereumData", JSON.stringify(newData))
  }

  const initialLoadEthereumData = async function () {
    const storedEthereumData = JSON.parse(localStorage.getItem("ethereumData")) || {}
    setEthereumData(() => (storedEthereumData))
    const pulls = []
    for (const wallet in storedEthereumData) {
      if (!storedEthereumData[wallet] || !storedEthereumData[wallet]["updatedAt"]) {
        const walletData = pullEthereumDataForWallet(wallet)
        walletData.then((data) => {
          storedEthereumData[wallet] = data
        })
        pulls.push(walletData)
      }
    }
    await Promise.all(pulls)
    setEthereumData(() => (storedEthereumData))
    localStorage.setItem("ethereumData", JSON.stringify(storedEthereumData))
    initLoaded.current = true
    return storedEthereumData
  }

  // Whenever local user state finds an update on the user, refresh wallet list
  useEffect(() => {
    if (user && (user.wallets.length || user.pendingWallets.length || user.watchingWallets.length) && initLoaded.current) {
      updateEthereumDataForNewWallets(ethereumData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // Whenever page loads, load locally stored data
  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false
      initialLoadEthereumData().then((initialData) => updateEthereumDataForNewWallets(initialData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <style>
        {css}
      </style>
      <Container maxWidth={false}>
        <EthereumAggregated ethereumData={ethereumData} />
        <Stack gap={2} sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          marginBottom: "24px",
          marginTop: "24px",
          width: 1,
        }}>
          <Button
            variant='soft'
            startIcon={<Iconify icon="mdi:refresh" />}
            onClick={() => { updateEthereumDataForNewWallets(true) }}
          >
            Refresh All
          </Button>
          <NewWallet chainId="evm" sx={{}} />
        </Stack>
        <Card>
          <Box>
            <CustomDataGrid
              data={getEthereumRows(ethereumData)}
              columns={getEthereumColumns()}
              columnGrouping={getEthereumColumnGrouping()}
              processRowUpdate={
                useCallback(
                  async (newRow: GridRowModel) => {
                    const address = newRow.id
                    const value = newRow.nickname
                    common.setAddressNickname(address, value, user, updateUser)
                    return newRow
                  },
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                  [user],
                )
              }
              onProcessRowUpdateError={useCallback((error: Error) => {
                console.log(error)
              }, [])}
              apiRef={apiRef}
            />
          </Box>
        </Card>
      </Container>
    </>
  );
}