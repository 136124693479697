import { useEffect, useState } from 'react'
// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import { Container, Button, Stack, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Iconify from '../components/iconify';

import * as apis from '../utils/apirequests.js'


function VerifyProofs() {
  const { setPage } = useUserContext()

  useEffect(() => {
    setPage("VerifyProofs")
  }, [setPage])

  // State to store data fetched from the backend
  const [verifyResult, setVerifyResult] = useState(null)
  const [proof, setProof] = useState(null)

  const verifyRangeProof = async (proof) => {
    const verifyResponse = await apis.backendRequest('verify/verify_range_proof', {
      "proof_str": proof,
    })
    setVerifyResult(verifyResponse)
  }

  const processRange = (filter, bounds) => {
    var res = ""
    for (var i = 0; i < filter.length; i++) {
      if (filter[i] === 0 || filter[i] === "0") {
        res = res + ", n/a"
      } else {
        res = res + ", " + parseInt(bounds[i])
      }
    }
    return res.slice(2)
  }

  return (
    <Container>
      <Stack gap={1} mt={4}>
        <Typography variant="h4" textAlign="left" sx={{ mb: 1, width: 1, maxWidth: "450px" }}>
          Verify Proof
        </Typography>

        <Stack>
          <Stack direction="row" alignItems={'center'} gap={1}>
            <Typography variant="h6">
              Paste proof here:
            </Typography>
          </Stack>
          <TextField
            size="small"
            fullWidth
            multiline
            minRows={5}
            maxRows={20}
            variant="outlined"
            onChange={(event) => { setProof(event.target.value) }}
            sx={{ ml: '8px' }}
          />
          <Button sx={{ m: '8px' }} variant="outlined" size="small" onClick={() => verifyRangeProof(proof)}>Verify</Button>
        </Stack>

        {verifyResult && verifyResult.validated &&
          <>
            <Typography variant='h5' color="success.main" textAlign={"center"}>Proof Verified</Typography>
            <Stack>
              <Stack direction="row" alignItems={'center'} gap={1}>
                <Typography variant="h6">
                  Data Commitments:
                </Typography>
                <Tooltip title="This should match with commitments in the attestation." placement="right">
                  <Iconify height={20} width={20} icon="gg:info" sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={2}
                maxRows={10}
                variant="outlined"
                value={verifyResult.commitment[0] + "\n" + verifyResult.commitment[1]}
                contentEditable={false}
                onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
                sx={{ ml: '8px' }}
              />
            </Stack>
            <Stack>
              <Stack direction="row" alignItems={'center'} gap={1}>
                <Typography variant="h6">
                  Data Lower Bounds:
                </Typography>
                <Tooltip title="The proof has revealed that certain data pieces within the attestation satisfy within a range." placement="right">
                  <Iconify height={20} width={20} icon="gg:info" sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={1}
                maxRows={10}
                variant="outlined"
                value={processRange(verifyResult.lower_filter, verifyResult.lower_bounds)}
                contentEditable={false}
                onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
                sx={{ ml: '8px' }}
              />
            </Stack>
            <Stack>
              <Stack direction="row" alignItems={'center'} gap={1}>
                <Typography variant="h6">
                  Data Upper Bounds:
                </Typography>
                <Tooltip title="The proof has revealed that certain data pieces within the attestation satisfy within a range." placement="right">
                  <Iconify height={20} width={20} icon="gg:info" sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={1}
                maxRows={10}
                variant="outlined"
                value={processRange(verifyResult.upper_filter, verifyResult.upper_bounds)}
                contentEditable={false}
                onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
                sx={{ ml: '8px' }}
              />
            </Stack>
          </>
        }
        {verifyResult && !verifyResult.validated &&
          <Typography variant='h5' color="error.main" textAlign={"center"}>Proof Invalid</Typography>
        }
      </Stack>
    </Container >
  );
}

export default VerifyProofs;
