import { m } from 'framer-motion';
import { useEffect, useRef, useState } from 'react'
import { Stack, Typography, Box, Button, Collapse } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// @ts-ignore
import { useUserContext } from '../../context/UserContext.tsx';
import * as apis from '../../utils/apirequests.js'
import * as cryptographic from '../../utils/cryptographic.js'
import * as schemaparser from '../../utils/schemaparser.js'
import * as utils from './utils.js'
import { v4 as uuidv4 } from 'uuid';
import Iconify from '../../components/iconify';
import NewWallet from '../NewWallet';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const SECOND_MS = 1000;
const MAX_PARALLEL_PROOFS = 10

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2.5),
  backgroundColor: alpha(theme.palette.grey[100], 1),
  borderRadius: 10,
  maxWidth: 450,
  width: "100%",
}));

function ProveAttestationSum({ flowDetails, validateVerificationProof, redirectBack }) {
  const userUuid = useRef<string | null>(null)
  const sessionInfo = useRef({
    token: null,
    expiresAt: 0,
  })
  const { user, session } = useUserContext()
  const [accounts, setAccounts] = useState({})
  const [provable, setProvable] = useState(false)
  const [proving, setProving] = useState(false)
  const [proved, setProved] = useState(false)
  const [failed, setFailed] = useState(false)
  const [moreExpanded, setMoreExpanded] = useState(false)
  const [clientInfo, setClientInfo] = useState({})
  const [flowInfo, setFlowInfo] = useState(null)
  const proofInitiated = useRef(false)
  const proofInProgress = useRef(false)
  const accountIndexes = useRef({})
  const fullAccountIndexes = useRef(null)
  const attestationTrees = useRef({})
  const vcInfo = useRef(null)
  const verificationInfo = useRef(null)
  const proofs = useRef([])
  const accountSecrets = useRef({})
  const commitmentProofResults = useRef([])
  const accountProofResults = useRef([])
  const sumProofResult = useRef(null)
  const valueInRangeProofResult = useRef(null)
  const fetchingAccountEligibilities = useRef(false)
  const proofAccounts = useRef({})
  const proofZkeys = useRef({})
  const pendingProofCount = useRef(0)
  const sumSecret = useRef(null)
  const isMobile = useCheckMobileScreen()

  async function getProofZkey(proofType) {
    if (proofType in Object.keys(proofZkeys.current)) {
      return proofZkeys.current[proofType]
    }
    proofZkeys.current[proofType] = await cryptographic.getProofFile(proofType)
    return proofZkeys.current[proofType]
  }

  function getProvingBgColor(failed, proved) {
    if (failed) {
      return alpha('#FEE4D1', 0.3)
      // } else if (proved) {
      //   return alpha('#F0FDD3', 0.5)
    } else {
      return "transparent"
    }
  }

  function getWalletConnectBgColor(provable) {
    return alpha('#F9FAFB', 1)
  }

  async function authedBackendRequest(url = '', data = {}) {
    if (!sessionInfo.current.token) {
      return null
    }
    data['user_uuid'] = userUuid.current
    data['token'] = sessionInfo.current.token
    return apis.backendRequest(url, data)
  }

  const getAccountsElementSums = (accounts) => {
    const sumResults = {}
    for (const fieldId in vcInfo.current.schema.fields) {
      const field = vcInfo.current.schema.fields[fieldId]
      if (field.type === 'integer') {
        sumResults[field.fieldName] = 0
      } else if (field.type === 'string') {
        // TODO: we should figure out how do we want to handle strings
        sumResults[field.fieldName] = 0
      }
    }

    for (const accountId in accounts) {
      const account = accounts[accountId]
      if (account.eligible) {
        for (const fieldId in vcInfo.current.schema.fields) {
          const field = vcInfo.current.schema.fields[fieldId]
          if (field.type === 'integer') {
            sumResults[field.fieldName] += account.details.data[field.fieldName]
          }
        }
      }
    }
    return sumResults
  }

  const getAccountsElementSumsArray = (accounts, numElements) => {
    const finalResults = []
    const sumResults = getAccountsElementSums(accounts)
    for (const fieldId in vcInfo.current.schema.fields) {
      const field = vcInfo.current.schema.fields[fieldId]
      finalResults.push(sumResults[field.fieldName])
    }
    while (finalResults.length < numElements) {
      finalResults.push(0)
    }
    return finalResults
  }

  const evaluateOverallEligibility = (accounts) => {
    const sumResults = getAccountsElementSums(accounts)
    setProvable(utils.isCriteriaMet(sumResults, vcInfo.current.schema.fields, verificationInfo.current.prove_criteria))
  }

  const parseWallet = (walletInfo) => {
    const walletHash = cryptographic.generateWalletHash(walletInfo.chainId, walletInfo.walletAddress).toString()
    if (walletHash in accountIndexes.current) {
      const walletIndex = accountIndexes.current[walletHash]
      const walletDetails = attestationTrees.current[walletIndex.tree].tree[walletHash]
      return {
        details: walletDetails,
        accountId: walletHash,
        accountDisplay: utils.getTruncatedAddress(walletInfo.walletAddress),
        eligible: utils.isCriteriaMet(walletDetails.data, vcInfo.current.schema.fields, verificationInfo.current.filter_criteria)
      }
    } else {
      return {
        accountId: walletHash,
        accountDisplay: utils.getTruncatedAddress(walletInfo.walletAddress),
        eligible: false,
        details: {
          data: {}
        }
      }
    }
  }

  const initializeFlow = async () => {
    setClientInfo(flowDetails.client_info)
    setFlowInfo(flowDetails.flow_info)
    vcInfo.current = flowDetails.vc_info
    verificationInfo.current = flowDetails.verification_info
    return flowDetails.flow_info.name
  }

  const fetchAccountEligibilities = async (force_refresh = false) => {
    if (fetchingAccountEligibilities.current) {
      return false
    }
    fetchingAccountEligibilities.current = true

    const attestationName = vcInfo.current.attestation_name
    if (!fullAccountIndexes.current || force_refresh) {
      fullAccountIndexes.current = await apis.backendRequest('get_attestation_wallet_indexes', { "attestation_name": attestationName })
    }
    const treesToFetch = {}
    for (const walletId in user.wallets) {
      const accountHash = cryptographic.generateWalletHash(user.wallets[walletId].chainId, user.wallets[walletId].walletAddress).toString()
      if (accountHash in fullAccountIndexes.current) {
        accountIndexes.current[accountHash] = fullAccountIndexes.current[accountHash]
        treesToFetch[fullAccountIndexes.current[accountHash].tree] = true
      }
    }
    for (const walletId in user.pendingWallets) {
      const accountHash = cryptographic.generateWalletHash(user.pendingWallets[walletId].chainId, user.pendingWallets[walletId].walletAddress).toString()
      if (accountHash in fullAccountIndexes.current) {
        accountIndexes.current[accountHash] = fullAccountIndexes.current[accountHash]
        treesToFetch[fullAccountIndexes.current[accountHash].tree] = true
      }
    }
    for (const treeId in treesToFetch) {
      if (!attestationTrees.current[treeId] || force_refresh) {
        const tree = await apis.backendRequest('get_attestation_tree', { "attestation_name": attestationName, "tree_uuid": treeId })
        attestationTrees.current[treeId] = tree
      }
    }

    const parsedAccountInfos = new Array(user.wallets.length + Object.keys(user.pendingWallets).length)
    for (const walletId in user.wallets) {
      parsedAccountInfos[walletId] = parseWallet(user.wallets[walletId])
    }
    for (const walletId in user.pendingWallets) {
      parsedAccountInfos[user.pendingWallets[walletId].walletIndex] = parseWallet(user.pendingWallets[walletId])
    }

    var eligibleAccounts = parsedAccountInfos.filter((account) => account.eligible)
    const inEligibleAccounts = parsedAccountInfos.filter((account) => !account.eligible)
    eligibleAccounts.sort((a, b) => {
      const sortBys = verificationInfo.current.sorting_keys
      for (const sortId in sortBys) {
        const sortBy = sortBys[sortId]
        if (a.details.data[sortBy] > b.details.data[sortBy]) {
          return -1
        } else if (a.details.data[sortBy] < b.details.data[sortBy]) {
          return 1
        }
      }
      return 0
    })
    var overflowEligibleAccounts = []
    if (eligibleAccounts.length > vcInfo.current.max_to_sum) {
      overflowEligibleAccounts = eligibleAccounts.slice(vcInfo.current.max_to_sum)
      eligibleAccounts = eligibleAccounts.slice(0, vcInfo.current.max_to_sum)
    }
    setAccounts({
      "eligibleAccounts": eligibleAccounts,
      "inEligibleAccounts": [overflowEligibleAccounts, inEligibleAccounts].flat(),
    })
    proofAccounts.current = {
      "eligibleAccounts": eligibleAccounts,
      "inEligibleAccounts": [overflowEligibleAccounts, inEligibleAccounts].flat(),
    }

    evaluateOverallEligibility(parsedAccountInfos)

    fetchingAccountEligibilities.current = false
    return true
  }

  const startProve = async (accounts) => {
    if (proofInitiated.current) return
    proofInitiated.current = true
    setProving(true)

    tryGenerateAndShareProof(user, accounts)
  }

  const tryGenerateAndShareProof = async (user, accounts) => {
    if (proofInProgress.current) return
    proofInProgress.current = true

    const pendingAccountProofsCount = Object.keys(user.pendingWallets).length * 2

    // Calculate the new maxAccounts after all pending wallets are verified
    const allAccountsLength = user.wallets.length + Object.keys(user.pendingWallets).length
    const newMaxAccounts = Math.max(2, Math.pow(2, Math.ceil(Math.log2(allAccountsLength))))

    // Generate the new total account elements after all pending wallets are verified
    var allAccountElements = new Array(newMaxAccounts).fill(0);
    for (var i = 0; i < user.wallets.length; i++) {
      allAccountElements[i] = cryptographic.generateWalletHash(user.wallets[i].chainId, user.wallets[i].walletAddress)
    }
    for (const contentId in user.pendingWallets) {
      const content = user.pendingWallets[contentId]
      const chainId = content.chainId
      const walletAddress = content.walletAddress
      const walletIndex = content.walletIndex
      allAccountElements[walletIndex] = cryptographic.generateWalletHash(chainId, walletAddress)
    }

    try {
      // Not all proofs generated yet, generate all the proofs and put inside the states
      if (proofs.current.length < accounts.eligibleAccounts.length * 2 + 2) {
        // Get all eligible accounts to prove
        const eligibleAccounts = accounts.eligibleAccounts
        var proofIdx = 0

        // Generate commitment ownership proof for each of them in parallel
        for (const accountIdx in eligibleAccounts) {
          const account = eligibleAccounts[accountIdx]
          if (!(account.accountId in accountSecrets.current)) {
            const secret = cryptographic.generateStrHash(uuidv4())
            accountSecrets.current[account.accountId] = secret
          }
          const accountIndex = accountIndexes.current[account.accountId]

          if (proofIdx >= proofs.current.length && pendingProofCount.current + pendingAccountProofsCount < MAX_PARALLEL_PROOFS) {
            const commitmentProof = cryptographic.generateCommitmentOwnershipProof(
              await getProofZkey("g_commitment_ownership_32"), account, accountSecrets.current[account.accountId], attestationTrees.current[accountIndex.tree],
            )
            pendingProofCount.current += 1
            commitmentProof.then((res) => {
              commitmentProofResults.current.push({
                proof: res,
                tree: accountIndex.tree,
              })
              pendingProofCount.current -= 1
              // console.log("commitment proof done")
            }).catch(() => {
              console.log("commitment proof failed");
            })
            proofs.current.push(commitmentProof)
          } else if (pendingProofCount.current + pendingAccountProofsCount >= MAX_PARALLEL_PROOFS) {
            proofInProgress.current = false
            return
          }
          proofIdx += 1

          if (proofIdx >= proofs.current.length && pendingProofCount.current + pendingAccountProofsCount < MAX_PARALLEL_PROOFS) {
            const accountProof = cryptographic.generateHashedAccountOwnershipProof(
              await getProofZkey(`g_hashed_account_ownership_${newMaxAccounts}`), newMaxAccounts, account, accountSecrets.current[account.accountId], user, allAccountElements,
            )
            pendingProofCount.current += 1
            accountProof.then((res) => {
              accountProofResults.current.push(res)
              pendingProofCount.current -= 1
              // console.log("account proof done")
            }).catch(() => {
              console.log("account proof failed");
            })
            proofs.current.push(accountProof)
          } else if (pendingProofCount.current + pendingAccountProofsCount >= MAX_PARALLEL_PROOFS) {
            proofInProgress.current = false
            return
          }
          proofIdx += 1
        }

        // In parallel, generate commitment sum proof
        if (proofIdx >= proofs.current.length && pendingProofCount.current + pendingAccountProofsCount < MAX_PARALLEL_PROOFS) {
          if (!sumSecret.current) {
            sumSecret.current = cryptographic.generateStrHash(uuidv4())
          }
          const sumProof = cryptographic.generateCommitmentSumProof(
            await getProofZkey(`g_sum_${vcInfo.current.max_to_sum}_commitment_${vcInfo.current.schema.numElements}_elements`),
            eligibleAccounts,
            accountSecrets.current,
            vcInfo.current.schema.fields,
            vcInfo.current.schema.numElements,
            vcInfo.current.max_to_sum,
            sumSecret.current,
          )
          pendingProofCount.current += 1
          sumProof.then((res) => {
            sumProofResult.current = res
            pendingProofCount.current -= 1
            // console.log("sum proof done")
          }).catch((e) => {
            console.log("sum proof failed", e);
          })
          proofs.current.push(sumProof)
        } else if (pendingProofCount.current + pendingAccountProofsCount >= MAX_PARALLEL_PROOFS) {
          proofInProgress.current = false
          return
        }
        proofIdx += 1

        if (proofIdx >= proofs.current.length && pendingProofCount.current + pendingAccountProofsCount < MAX_PARALLEL_PROOFS) {
          // In parallel, generate sum value in range proof
          const sums = getAccountsElementSumsArray(accounts.eligibleAccounts, vcInfo.current.schema.numElements)
          const [lowerFilter, lowerBounds, upperFilter, upperBounds] = schemaparser.getLimitsFromCriteria(
            vcInfo.current.schema.fields,
            verificationInfo.current.prove_criteria,
            vcInfo.current.schema.numElements,
          )
          const challenge = await apis.backendRequest("verify/generate_proof_challenge", {})
          const valueInRangeProof = cryptographic.generateVcHasValueInRangeProof(
            await getProofZkey(`g_vc_has_${sums.length}_value_in_range`), sums, lowerFilter, lowerBounds, upperFilter, upperBounds, challenge.challenge, sumSecret.current,
          )
          pendingProofCount.current += 1
          valueInRangeProof.then((res) => {
            valueInRangeProofResult.current = res
            pendingProofCount.current -= 1
            // console.log("value in range proof done")
          }).catch(() => {
            console.log("range proof failed");
          })
          proofs.current.push(valueInRangeProof)
        } else if (pendingProofCount.current + pendingAccountProofsCount >= MAX_PARALLEL_PROOFS) {
          proofInProgress.current = false
          return
        }
      }

      // Make sure no pending accounts before sending to backend
      if (Object.keys(user.pendingWallets).length > 0
        || proofs.current.length < accounts.eligibleAccounts.length * 2 + 2) {
        proofInProgress.current = false
        return
      }

      await Promise.all(proofs.current)

      // Send proofs to backend to write sum results into a VC
      const res = await authedBackendRequest("generate_sum_attestation", {
        attestation_name: vcInfo.current.attestation_name,
        commitment_proofs: commitmentProofResults.current,
        account_proofs: accountProofResults.current,
        sum_proof: sumProofResult.current,
        max_to_sum: vcInfo.current.max_to_sum,
        num_elements: vcInfo.current.schema.numElements,
      })

      var verificationResult = false
      // Encode proofs and public results and redirect back
      if (res.validated) {
        // Store VC attestation and value locally
        localStorage.setItem(
          "private_vc_" + verificationInfo.current.vc_type + "_" + res.unique_id,
          JSON.stringify({
            attestation: res.attestation,
            data: getAccountsElementSums(accounts.eligibleAccounts),
            secret: sumSecret.current.toString(),
          }),
        )

        verificationResult = await validateVerificationProof(res.attestation, valueInRangeProofResult.current.proof, valueInRangeProofResult.current.publicResults)
      }

      if (verificationResult[0]) {
        setProved(true)
        redirectBack()
      } else {
        setFailed(true)
      }
      proofInitiated.current = false
    } catch (e) {
      console.log(e)
    }

    proofInProgress.current = false
  }

  // Try to verify all pending wallets a user has every 15 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (proofInitiated.current) {
        tryGenerateAndShareProof(user, proofAccounts.current)
      }
    }, 5 * SECOND_MS);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // Whenever local user state finds a new userUuid, get a valid token for the user
  useEffect(() => {
    if (user && flowDetails) {
      userUuid.current = user.userUuid

      initializeFlow().then((client_name) => {
        if (client_name) {
          fetchAccountEligibilities().then((fetched) => {
            if (fetched && proofInitiated.current) tryGenerateAndShareProof(user, proofAccounts.current)
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, flowDetails])

  // Whenever global session updates, update the local ref
  useEffect(() => {
    sessionInfo.current = session
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  return (
    <Stack gap={2} alignItems={"center"} sx={{ width: 1, maxWidth: "450px" }}>
      <Item sx={{ background: getWalletConnectBgColor(provable) }}>
        <Stack gap={2}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            {!provable &&
              <Stack minHeight={36} minWidth={36} borderRadius={5} border={"1px solid"} alignItems={"center"} justifyContent={"center"}>
                <Iconify height={20} width={20} icon="ri:number-1" />
              </Stack>
            }
            {provable &&
              <Stack minHeight={36} minWidth={36} borderRadius={5} alignItems={"center"} justifyContent={"center"}>
                <Iconify height={36} width={36} icon="material-symbols:check" />
              </Stack>
            }
            <Typography variant="body1" textAlign="left">
              Connect your wallets to meet the requirements
            </Typography>
          </Stack>
          <Stack gap={1} alignItems={"center"} paddingLeft={2}>
            <Stack gap={1} width={1}>
              {accounts && accounts["eligibleAccounts"] && accounts["eligibleAccounts"].map((account) =>
                <Box key={account.accountId}>{utils.renderAccountNftHolders(account, flowInfo.verification_type, isMobile)}</Box>
              )}
            </Stack>
            {accounts["inEligibleAccounts"] && accounts["inEligibleAccounts"].length > 0 &&
              <Box borderBottom={"1px dashed"} width={1}></Box>
            }
            {accounts["inEligibleAccounts"] && accounts["inEligibleAccounts"].length > 0 &&
              <div onClick={() => setMoreExpanded(!moreExpanded)}>
                {moreExpanded && <Typography variant='caption' sx={{ cursor: "pointer", width: "fit-content" }}>Hide {accounts["inEligibleAccounts"].length} wallet{accounts["inEligibleAccounts"].length > 1 && 's'}</Typography>}
                {!moreExpanded && <Typography borderBottom={"1px solid"} variant='caption' sx={{ cursor: "pointer", width: "fit-content" }}>View {accounts["inEligibleAccounts"].length} more wallet{accounts["inEligibleAccounts"].length > 1 && 's'}</Typography>}
              </div>
            }
            <Collapse in={moreExpanded} sx={{ width: 1 }}>
              <Stack gap={1} width={1}>
                {accounts && accounts["inEligibleAccounts"] && accounts["inEligibleAccounts"].map((account) =>
                  <Box key={account.accountId}>{utils.renderAccountNftHolders(account, flowInfo.verification_type, isMobile)}</Box>
                )}
              </Stack>
            </Collapse>
            {!provable &&
              <Collapse in={!provable}>
                <NewWallet chainId={"evm"} sx={{}} allowWatchWallet={false} disabled={provable} newWalletAdded={(_) => { fetchAccountEligibilities(flowInfo.refresh_data_on_new_wallet || false) }} />
              </Collapse>
            }
          </Stack>
        </Stack>
      </Item>
      <Item sx={{ padding: 0, width: 1 }}>
        {!proving &&
          <Button variant='soft' sx={{ padding: 2.5, width: 1 }} disabled={!provable} onClick={() => startProve(accounts)}>
            <Stack direction={"row"} gap={2} alignItems={"center"} width={1}>
              <Stack minHeight={36} minWidth={36} borderRadius={5} border={"1px solid"} alignItems={"center"} justifyContent={"center"}>
                <Iconify height={20} width={20} icon="ri:number-2" />
              </Stack>
              <Typography variant="body1" textAlign="left">
                Prove to {clientInfo["name"]}
              </Typography>
            </Stack>
          </Button>
        }
        <Collapse in={proving}>
          {proving &&
            <Box
              sx={{ width: 1, borderRadius: "10px" }}
              component={m.div}
            >
              <Box sx={{ padding: 2.5, width: 1, borderRadius: "10px", background: getProvingBgColor(failed, proved) }}>
                <Stack>
                  <Stack direction={"row"} gap={2} alignItems={"center"} width={1}>
                    <Stack minHeight={36} minWidth={36} alignItems={"center"} justifyContent={"center"}>
                      {!proved && !failed &&
                        <Iconify height={36} width={36} icon="line-md:loading-loop" />
                      }
                      {proved &&
                        <Iconify height={36} width={36} icon="material-symbols:check" />
                      }
                      {failed &&
                        <Iconify height={36} width={36} icon="gridicons:cross" />
                      }
                    </Stack>
                    {!proved && !failed &&
                      <Typography variant="body1" textAlign="left">
                        Generating proof and verifying with {clientInfo["name"]}
                      </Typography>
                    }
                    {proved &&
                      <Typography variant="body1" textAlign="left">
                        Successfully Verified
                      </Typography>
                    }
                    {failed &&
                      <Typography variant="body1" textAlign="left">
                        Verification Failed
                      </Typography>
                    }
                  </Stack>
                  <Collapse in={!proved && !failed}>
                    <Typography variant="body2" textAlign="center" mt={2}>
                      This usually takes up to 10 seconds
                    </Typography>
                  </Collapse>
                </Stack>
              </Box>
            </Box>
          }
        </Collapse>
      </Item>
    </Stack>
  )
}

export default ProveAttestationSum
