import { useEffect, useRef, useState } from 'react'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import * as encoding from "@walletconnect/encoding";
import { getProviderInfo } from "web3modal"
import { ethers } from 'ethers';
import {
  Typography,
  Button,
  TextField,
  Stack,
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material';
// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import VerticalLinearStepper from '../components/stepper/VerticalLinearStepper';
import Iconify from '../components/iconify/Iconify';
import * as cryptographic from '../utils/cryptographic.js';
import * as apis from '../utils/apirequests.js'
import metamaskImg from '../images/metamask.png'
import coinbaseImg from '../images/coinbase_wallet.svg'
import walletconnectImg from '../images/walletconnect.svg'
import { CustomAvatar } from '../components/custom-avatar';
import { useSnackbar } from '../components/snackbar';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const projectId = "68356ccffc405fa76490532f6ad56492";

function NewWallet({ chainId, sx, allowWatchWallet = true, disabled = false, newWalletAdded = (_) => { } }) {
  const userUuid = useRef<string | null>(null)
  const sessionInfo = useRef({
    token: null,
    expiresAt: 0,
  })
  const { user, setUser, session } = useUserContext()
  const [newWallets, setNewWallets] = useState("")
  const [open, setOpen] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [activeStep, setActiveStep] = useState(null);
  const [provider, setProvider] = useState(null);
  const [ethereumProvider, setEthereumProvider] = useState(null);
  const [connectingAddress, setConnectingAddress] = useState(null);
  const [metamaskEnabled, setMetamaskEnabled] = useState(false)
  const [coinbaseEnabled, setCoinbaseEnabled] = useState(false)

  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useCheckMobileScreen()

  const encryptionKey = useRef(null)
  const isFirstMount = useRef(true)

  async function authedBackendRequest(url = '', data = {}) {
    if (!sessionInfo.current.token) {
      return null
    }
    data['user_uuid'] = userUuid.current
    data['token'] = sessionInfo.current.token
    return apis.backendRequest(url, data)
  }

  function getTruncatedAddress(address) {
    return address.substring(0, 9) + "......" + address.substring(35)
  }

  async function findDelegatedVaults(delegate) {
    const allVaults = {}
    const v1Response = await (await fetch(`https://api.delegate.xyz/registry/v1/${delegate}`)).json()
    for (const v1Item of v1Response) {
      if (v1Item.type === "ALL" && v1Item.delegate.toLowerCase() === delegate.toLowerCase()) {
        allVaults[v1Item.vault] = true
      }
    }
    const v2Response = await (await fetch(`https://api.delegate.xyz/registry/v2/${delegate}`)).json()
    for (const v2Item of v2Response) {
      if (v2Item.type === "ALL" && v2Item.to.toLowerCase() === delegate.toLowerCase()) {
        allVaults[v2Item.from] = true
      }
    }
    return Object.keys(allVaults)
  }

  async function checkAndSelectAddress(wallet_address, vaults, on_select, on_fail, options = { title: "" }) {
    // Read options
    const title = options.title || ""

    // Construct infural provider
    const baseClass = "soulbuddy user-custom "

    // html layout
    var body = document.getElementsByTagName('body')[0]

    var popupRoot = document.createElement('div')
    popupRoot.classList.value = baseClass + "popup-root"
    popupRoot.onclick = (event) => {
      if (event.composedPath()[0] === popupRoot) {
        body.removeChild(popupRoot)
        on_fail("CANCELLED", "User cancelled selection.")
      }
    }

    var popupContent = document.createElement('div')
    popupContent.classList.value = baseClass + "popup-content"

    var popupSelector = document.createElement('div')
    popupSelector.classList.value = baseClass + "popup-selector"

    var popupSelectorTitle = document.createElement('div')
    popupSelectorTitle.classList.value = baseClass + "popup-selector-title"
    popupSelectorTitle.appendChild(document.createTextNode(title ? title : "Select Wallet to Connect"))
    popupSelector.appendChild(popupSelectorTitle)

    // Add itself as a choice
    var popupSelectorItemSelf = document.createElement('div')
    popupSelectorItemSelf.classList.value = baseClass + "popup-selector-item"
    popupSelectorItemSelf.appendChild(document.createTextNode(getTruncatedAddress(wallet_address)))
    popupSelectorItemSelf.onclick = () => {
      body.removeChild(popupRoot)
      on_select(wallet_address, wallet_address)
    }
    popupSelector.appendChild(popupSelectorItemSelf)

    // Add all other choices grabbed from soulbuddy contract
    vaults.map((vault) => {
      var popupSelectorItem = document.createElement('div')
      popupSelectorItem.classList.value = baseClass + "popup-selector-item"
      popupSelectorItem.appendChild(document.createTextNode(getTruncatedAddress(vault) + " (From delegate.xyz)"))
      popupSelectorItem.onclick = () => {
        body.removeChild(popupRoot)
        on_select(vault, wallet_address)
      }
      popupSelector.appendChild(popupSelectorItem)
      return null
    })

    popupContent.appendChild(popupSelector)
    popupRoot.appendChild(popupContent)
    body.appendChild(popupRoot)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false)
    setConnecting(false)
    setActiveStep(null)
  };

  // Mechanism to force metamask get user to select a wallet every time and not by default reuse the last one used
  const reconnectEvmWallet = async (provider) => {
    // @ts-ignore
    await provider.request({
      method: 'wallet_requestPermissions',
      params: [{
        eth_accounts: {},
      }]
    });
    // @ts-ignore
    const accounts = await provider.request({ method: 'eth_requestAccounts' });
    return accounts && accounts.length > 0 ? accounts[0] : ""
  }

  // Get a challenge from backend for verifying wallet ownership
  const getNonce = async (chainId, walletAddress) => {
    const jsonData = await apis.backendRequest("get_wallet_nonce", {
      "chain_id": chainId,
      "wallet_address": walletAddress,
    });

    return jsonData
  };

  // Send signed challenge to the backend in exchange for a backend attestation of wallet ownership
  const getAttestation = async (chainId, walletAddress, signatureWalletAddress, signature, ownersHash) => {
    const jsonData = await apis.backendRequest("attest_crypto_wallet_ownership", {
      "chain_id": chainId,
      "wallet_address": walletAddress,
      "signature_wallet_address": signatureWalletAddress,
      "signature": signature,
      "owners_hash": ownersHash,
    });
    return jsonData;
  };

  const addressConnected = function (chainId, walletAddress) {
    for (const walletId in user.wallets) {
      if (
        chainId === user.wallets[walletId].chainId
        && walletAddress === user.wallets[walletId].walletAddress
      ) {
        return true
      }
    }
    return `${chainId}:${walletAddress}` in user.pendingWallets
  }

  const addressWatched = function (chainId, walletAddress) {
    for (const walletId in user.watchingWallets) {
      if (chainId === user.watchingWallets[walletId].chainId
        && walletAddress === user.watchingWallets[walletId].walletAddress
      ) {
        return true
      }
    }
    return false
  }

  const getNewAddresses = function (chainId, walletAddresses) {
    const connectedWallets = {}
    for (const walletId in user.wallets) {
      if (chainId === user.wallets[walletId].chainId) {
        connectedWallets[user.wallets[walletId].walletAddress] = true
      }
    }
    const watchingWallets = {}
    for (const walletId in user.watchingWallets) {
      if (chainId === user.watchingWallets[walletId].chainId) {
        watchingWallets[user.watchingWallets[walletId].walletAddress] = true
      }
    }

    const newAddresses = {}
    walletAddresses.map((address) => {
      if (chainId === "evm") {
        if (!ethers.utils.isAddress(address)) {
          console.log("Not a valid evm address.")
          return null
        }
      }
      if (
        !(address in connectedWallets)
        && !(address in watchingWallets)
        && !(`${chainId}:${address}` in user.pendingWallets)
      ) {
        newAddresses[address] = true
      }
      return null
    })
    return Object.keys(newAddresses)
  }

  async function connectEvmWalletWalletConnect() {
    setActiveStep(0)

    const provider = await EthereumProvider.init({
      projectId: projectId, // REQUIRED your projectId
      chains: [1], // REQUIRED chain ids
      showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal
      methods: ['personal_sign'], // REQUIRED ethereum methods
      optionalMethods: ['personal_sign'],
      events: [], // REQUIRED ethereum events
      qrModalOptions: {
        explorerRecommendedWalletIds: [
          'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
          '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
          '8837dd9413b1d9b585ee937d27a816590248386d9dbf59f5cd3422dbbb65683e',
          'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
        ],
        enableExplorer: true,
      }
    })
    await provider.connect()

    const ethersProvider = new ethers.providers.Web3Provider(provider)
    const signer = ethersProvider.getSigner()
    const address = (await signer.getAddress()).toLowerCase()

    // Move active step to next
    setEthereumProvider(provider)
    setProvider(ethersProvider)
    setConnectingAddress(address)

    if (!isMobile) {
      await signMessageToAddWallet(ethersProvider, provider, address)
    } else {
      setActiveStep(1)
    }
  }

  // Master function for connecting a new wallet to an account
  const connectEvmWallet = async (provider_id) => {
    setActiveStep(0)

    // 1. Connect a wallet and get address from the providers
    let provider = window.ethereum;

    try {
      provider = await window.ethereum.providers.find(
        (provider) => getProviderInfo(provider).id === provider_id
      );
    } catch (error) {
      console.error(error)
    }

    var existing_permissions = []
    // @ts-ignore
    if (typeof provider !== 'undefined' && provider.isMetaMask) {
      // @ts-ignore
      existing_permissions = await provider.request({ method: 'wallet_getPermissions' });
    }

    var address = (await provider.request({
      method: "eth_requestAccounts",
      params: [],
    }))[0];

    if (getProviderInfo(provider).id === "injected" && existing_permissions.length > 0) {
      address = await reconnectEvmWallet(provider)
    }
    address = address.toLowerCase()

    // Move active step to next
    await signMessageToAddWallet(provider, null, address)
  }

  const signMessageToAddWallet = async function (provider, ethereumProvider, address) {
    const allVaults = await findDelegatedVaults(address)
    if (allVaults.length === 0) {
      signMessageToAddWalletWithSignatureWallet(provider, ethereumProvider, address, address)
    } else {
      checkAndSelectAddress(address, allVaults,
        (vault, _) => {
          signMessageToAddWalletWithSignatureWallet(provider, ethereumProvider, vault.toLowerCase(), address)
        },
        (_, reason) => {
          console.log(reason)
        },
      )
    }
  }

  const signMessageToAddWalletWithSignatureWallet = async function (provider, ethereumProvider, address, signatureWallet) {
    if (addressConnected("evm", address)) {
      handleClose()
      enqueueSnackbar("This wallet has already been connected.", { variant: "info" })
      return
    }

    setConnecting(true)
    setActiveStep(1)

    // 2. Get a nonce from backend and sign the nonce
    const walletNonce = await getNonce("evm", address)
    // if (walletNonce && walletNonce.verified_at && walletNonce.verified_at < Date.now() - 600000 /* 10 minutes */) {
    //   enqueueSnackbar("This wallet has already connected with another account.", { variant: "warning" })
    //   setActiveStep(null)
    //   setConnecting(false)
    //   return
    // }

    const msg = `Sign this message to verify ownership of ${signatureWallet}, nonce: ${walletNonce?.nonce}`

    const hexMsg = encoding.utf8ToHex(msg, true);
    var walletSignature = await provider.send("personal_sign", [hexMsg, signatureWallet])

    if (!walletSignature) {
      enqueueSnackbar("Signature verification failed.", { variant: "warning" })
      setActiveStep(1)
      return
    }
    if (walletSignature.result) {
      walletSignature = walletSignature.result
    }

    setConnectingAddress(null)
    setProvider(null)
    if (ethereumProvider) {
      ethereumProvider.disconnect()
      setEthereumProvider(null)
    }

    // 3. Generate secret and send to backend along with signature to get attestation
    const ownersSecret = cryptographic.generateSecretAndHash()
    const attestation = await getAttestation("evm", address, signatureWallet, walletSignature, ownersSecret.secret_hash).then((result) => {
      return result
    }).catch(() => {
      return null
    })

    if (!attestation) {
      enqueueSnackbar("Signature verification failed.", { variant: "warning" })
      setActiveStep(null)
      setConnecting(false)
      return
    }

    // Backend successfully attested, store the wallet into pendingWallets and update user state
    var newUserData = user
    const existingWalletCount = user.wallets.length + Object.keys(user.pendingWallets).length
    newUserData.pendingWallets["evm:" + address] = {
      "chainId": "evm",
      "walletAddress": address,
      "secret": ownersSecret.secret,
      "attestationTreeIdx": attestation.tree_idx,
      "attestationIdx": attestation.statement_idx,
      "walletIndex": existingWalletCount,
    }
    if (addressWatched("evm", address)) {
      newUserData.watchingWallets = newUserData.watchingWallets.filter((wallet) => { return wallet.walletAddress !== address || wallet.chainId !== "evm" })
    }
    setUser(existingValues => ({
      ...existingValues,
      "pendingWallets": newUserData.pendingWallets,
      "watchingWallets": newUserData.watchingWallets,
    }))
    localStorage.setItem("userData", JSON.stringify(newUserData))
    authedBackendRequest("update_encrypted_user_data", {
      "encrypted_user_data": cryptographic.encryptText(JSON.stringify(newUserData), encryptionKey.current),
    });
    handleClose()
    enqueueSnackbar("Wallet successfully connected!", { variant: "success" })
    newWalletAdded(address)
  }

  const connectNewWallet = async function (provider_id) {
    setConnecting(true)
    try {
      if (!chainId || chainId === "evm") {
        await connectEvmWallet(provider_id)
      } else {
        console.log("Not supported yet")
      }
    } catch (error) {
      setActiveStep(null)
    }
    setConnecting(false)
  }

  const connectNewWalletWalletConnect = async function () {
    setOpen(true)
    setConnecting(true)
    try {
      if (!chainId || chainId === "evm") {
        await connectEvmWalletWalletConnect()
      } else {
        console.log("Not supported yet")
      }
    } catch (error) {
      console.log(error)
      setActiveStep(0)
    }
  }

  const watchNewWallets = async function (chainId, walletAddresses) {
    if (chainId !== "evm") {
      console.log("Not supported yet")
      return
    }

    var newUserData = user
    if (!newUserData.watchingWallets) {
      newUserData.watchingWallets = []
    }

    const addresses = getNewAddresses("evm", walletAddresses.toLowerCase().split("\n"))
    if (addresses.length === 0) {
      return
    }

    apis.backendRequest("someone_watched_some_wallets", { "number_watched": addresses.length })

    addresses.map((address) => {
      newUserData.watchingWallets.push({
        "chainId": chainId,
        "walletAddress": address,
      })
      return address
    })

    setUser(existingValues => ({
      ...existingValues,
      "watchingWallets": newUserData.watchingWallets,
    }))
    localStorage.setItem("userData", JSON.stringify(newUserData))
    authedBackendRequest("update_encrypted_user_data", {
      "encrypted_user_data": cryptographic.encryptText(JSON.stringify(newUserData), encryptionKey.current),
    });
  }

  const STEPS = {
    stepIds: ['connet_wallet', 'verify_signature'],
    stepDetails: {
      'connet_wallet': {
        stepIdx: 0,
        label: 'Connect your wallet. No permission will be requested.',
        description: ``,
      },
      'verify_signature': {
        stepIdx: 1,
        label: 'Verify ownership of wallet by signing a sign-in message. Jomo does not store or have any knowledge of associations between your wallets.',
        description: ``,
      },
    }
  };

  const renderStepLabel = function (step) {
    const label = STEPS.stepDetails[step].label
    return (
      <>
        {label}
      </>
    )
  }

  const renderStepContent = function (step, setActiveStep) {
    const description = STEPS.stepDetails[step].description

    return (
      <>
        <Typography>{description}</Typography>
      </>
    )
  }

  const renderResetContent = function (step) {
    return (
      <>
        <Typography>Address Connected!</Typography>
      </>
    )
  }

  // Whenever local user state finds a new userUuid, get a valid token for the user
  // And trigger a pending wallet verification
  useEffect(() => {
    if (user) {
      userUuid.current = user.userUuid
      encryptionKey.current = cryptographic.recoverShardedKeys([user.encryptionKeyLocal, JSON.parse(localStorage.getItem("encryptionKeyServer"))])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // Whenever global session updates, update the local ref
  useEffect(() => {
    sessionInfo.current = session
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false

      if (window.ethereum && window.ethereum.providers) {
        for (const provider of window.ethereum.providers) {
          if (getProviderInfo(provider).name === "MetaMask") {
            setMetamaskEnabled(true)
          }
          if (getProviderInfo(provider).id === "walletlink") {
            setCoinbaseEnabled(true)
          }
        }
      } else {
        if (getProviderInfo(window.ethereum).name === "MetaMask") {
          setMetamaskEnabled(true)
        }
        if (getProviderInfo(window.ethereum).id === "walletlink") {
          setCoinbaseEnabled(true)
        }
      }
    }
  }, [])


  return (
    <>
      <Button sx={sx} variant="contained" onClick={() => {
        if (isMobile) {
          connectNewWalletWalletConnect()
        } else {
          handleClickOpen()
        }
      }} startIcon={(<Iconify icon="mdi:wallet-add-outline" />)} disabled={disabled}>
        Connect another wallet
      </Button>

      <Dialog open={open} onClose={handleClose} sx={{ zIndex: 69 }}>
        {!connecting && <DialogTitle sx={{
          maxWidth: "450px",
          minWidth: "300px",
        }}>Connect to Jomo
        </DialogTitle>}
        {connecting && <DialogTitle sx={{
          maxWidth: "450px",
          minWidth: "300px",
        }}>Connect Wallet
        </DialogTitle>}
        <Stack
          direction={"column"}
          alignItems={"center"}
          marginLeft={"24px"}
          marginRight={"24px"}
          marginBottom={"24px"}
          spacing={6}>

          {connecting &&
            <Stack gap={2} alignItems="flex-end">
              <VerticalLinearStepper
                sx={{
                  textAlign: "left",
                  maxWidth: "450px",
                  minWidth: "300px",
                }}
                steps={STEPS.stepIds}
                parentActiveStep={activeStep}
                renderStepLabel={renderStepLabel}
                renderStepContent={renderStepContent}
                showReset={true}
                renderResetContent={renderResetContent}
              />
              <Stack direction={"row"} gap={1}>
                {activeStep === 0 && <Button
                  variant='outlined'
                  color='primary'
                  sx={{ height: "30px", width: "100px" }}
                  onClick={() => {
                    setConnecting(false)
                  }}>Cancel</Button>
                }
                {isMobile && activeStep === 1 && <Button
                  variant='contained'
                  color='primary'
                  sx={{ height: "30px", width: "150px" }}
                  onClick={() => {
                    signMessageToAddWallet(provider, ethereumProvider, connectingAddress)
                  }}>Sign Message</Button>
                }
              </Stack>
            </Stack>
          }
          {!connecting &&
            <Stack direction={"row"} gap={2} alignItems="center" sx={{ marginTop: "12px" }}>
              {!isMobile && metamaskEnabled &&
                <Stack gap={0.5} alignItems={"center"} textAlign={"center"} width={"110px"}>
                  <IconButton sx={{ width: "75px", height: "75px" }} onClick={() => connectNewWallet("injected")}>
                    <CustomAvatar sx={{ width: "70px", height: "70px" }} alt="Metamask" src={metamaskImg} />
                  </IconButton>
                  <Typography variant='subtitle2'>Metamask</Typography>
                </Stack>
              }
              {!isMobile && coinbaseEnabled &&
                <Stack gap={0.5} alignItems={"center"} textAlign={"center"} width={"110px"}>
                  <IconButton sx={{ width: "75px", height: "75px" }} onClick={() => connectNewWallet("walletlink")}>
                    <CustomAvatar sx={{ width: "70px", height: "70px" }} alt="Coinbase Wallet" src={coinbaseImg} />
                  </IconButton>
                  <Typography variant='subtitle2'>Coinbase Wallet</Typography>
                </Stack>
              }
              <Stack gap={0.5} alignItems={"center"} textAlign={"center"} width={"110px"}>
                <IconButton sx={{ width: "75px", height: "75px" }} onClick={() => connectNewWalletWalletConnect()}>
                  <CustomAvatar sx={{ width: "70px", height: "70px" }} alt="WalletConnect" src={walletconnectImg} />
                </IconButton>
                <Typography variant='subtitle2'>WalletConnect</Typography>
              </Stack>
            </Stack>
          }

          {!connecting && allowWatchWallet &&
            <Stack gap={1} sx={{ display: "flex", flexDirection: "column", width: 1 }}>
              <Typography variant='subtitle2'>Track any wallet manually</Typography>
              <Stack gap={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", width: 1 }}>
                <TextField
                  id="input-watch-wallets"
                  label="Wallet Addresses (one per line)"
                  variant="outlined"
                  size='small'
                  multiline
                  value={newWallets}
                  rows={3}
                  onChange={(event) => setNewWallets(event.target.value)}
                  fullWidth
                />
                <Button
                  variant='outlined'
                  color='secondary'
                  sx={{ height: "30px" }}
                  onClick={() => {
                    watchNewWallets(chainId, newWallets).then(() => setNewWallets(""))
                    handleClose()
                  }}>Add</Button>
              </Stack>
            </Stack>
          }
        </Stack>

      </Dialog>
    </>
  )
}

export default NewWallet