import { useEffect, useRef } from 'react'
// @ts-ignore
import { useUserContext } from '../../context/UserContext.tsx';
import AccountRecoverySection from './AccountRecoverySection'
import * as cryptographic from '../../utils/cryptographic.js';
import * as apis from '../../utils/apirequests.js'

export default function AccountRecovery() {
  const userUuid = useRef<string | null>(null)
  const sessionInfo = useRef({
    token: null,
    expiresAt: 0,
  })
  const { user, setUser, session, setSession } = useUserContext()

  const encryptionKey = useRef(null)

  // Store the backup encryption key into a file and download it
  const localStorageBackup = async function () {
    var backup = localStorage.getItem("encryptionKeyBackup");
    var json = JSON.stringify(backup);
    var base = btoa(json);
    var href = 'data:text/javascript;charset=utf-8;base64,' + base;
    var link = document.createElement('a');
    link.setAttribute('download', 'jomo_user_backup.json');
    link.setAttribute('href', href);
    document.querySelector('body').appendChild(link);
    link.click();
    link.remove();
  };

  // Generate a pop up file selector and restore user from a backup key file
  const localStorageRestore = async function () {
    var t = document.createElement('div');
    var a = document.createElement('a');
    a.appendChild(document.createTextNode('X'));
    a.setAttribute('href', '#');

    a.style.position = 'absolute';
    a.style.top = '10px';
    a.style.right = '10px';
    a.style['text-decoration'] = 'none';
    a.style.color = '#fff';
    t.appendChild(a);
    a.onclick = function () {
      t.remove();
    };
    t.style.width = '50%';
    t.style.position = 'absolute';
    t.style.top = '25%';
    t.style.left = '25%';
    t.style['background-color'] = 'gray';
    t.style['text-align'] = 'center';
    t.style.padding = '50px';
    t.style.color = '#fff';
    t.style['z-index'] = 10000;

    var l = document.createElement('input');
    l.setAttribute('type', 'file');
    l.setAttribute('id', 'fileinput');
    l.onchange = function (e) {
      t.remove();
      // @ts-ignore
      var f = e.target.files[0];
      if (f) {
        var reader = new FileReader();
        reader.onload = function (e) {
          var text = e.target.result;
          // @ts-ignore
          var backup = JSON.parse(JSON.parse(text));
          const userUuid = backup["userUuid"];
          const backupKey = backup["key"];
          restoreFromBackup(userUuid, backupKey)
        };
        reader.readAsText(f);
      } else {
        alert('Failed to load file');
      }
    };
    // @ts-ignore
    a = document.createElement('h3');
    a.appendChild(document.createTextNode('Select file with backup'));
    t.appendChild(a);
    t.appendChild(l);
    document.querySelector('body').appendChild(t);
  };

  // With the backup key loaded, load server key and encrypted user info to restore a user
  const restoreFromBackup = async function (backupUserUuid, backupKey) {
    // Get server side key
    const jsonData = await apis.backendRequest("get_user_encryption_key_server", {
      "user_uuid": backupUserUuid,
    });

    const serverKey = jsonData.encryption_key_server
    if (!serverKey) {
      console.log("Could not find server side encryption key.")
      return
    }

    localStorage.setItem("encryptionKeyServer", JSON.stringify(serverKey))
    localStorage.setItem("encryptionKeyBackup", JSON.stringify({
      "userUuid": backupUserUuid,
      "key": backupKey,
    }))
    encryptionKey.current = cryptographic.recoverShardedKeys([backupKey, serverKey])

    // Get server stored encryption user data
    const responseJson = await apis.backendRequest("get_encrypted_user_data", {
      "user_uuid": backupUserUuid,
    });
    if (!responseJson.encrypted_user_data) {
      console.log("Unable to load and decrypt user data.")
      return
    }

    const userData = JSON.parse(cryptographic.decryptText(responseJson.encrypted_user_data, encryptionKey.current))
    localStorage.setItem("userData", JSON.stringify(userData))
    userUuid.current = userData.userUuid
    setUser(() => (userData))
    sessionInfo.current = { token: null, expiresAt: 0 }
    setSession(sessionInfo.current)
    localStorage.removeItem("sessionData")
  }

  // Whenever local user state finds a new userUuid, get a valid token for the user
  useEffect(() => {
    if (user) {
      userUuid.current = user.userUuid
      encryptionKey.current = cryptographic.recoverShardedKeys([user.encryptionKeyLocal, JSON.parse(localStorage.getItem("encryptionKeyServer"))])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // Whenever global session updates, update the local ref
  useEffect(() => {
    sessionInfo.current = session
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])


  return (
    <AccountRecoverySection handleLocalStorageRestore={localStorageRestore} handleLocalStorageBackup={localStorageBackup} />
  )
}
