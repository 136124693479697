import { cloneElement } from 'react'
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { Stack } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Link } from 'react-router-dom'
import logoImg from '../images/logo.png';
import Label from '../components/label/Label';

function ElevationScroll(props) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window,
    });

    return cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

// const menus = [
//     { text: 'Activities', href: '/activities' },
// ];

export default function NavBar() {
    const theme = useTheme()

    return (
        <>
            <CssBaseline />
            <ElevationScroll>
                <AppBar sx={{
                    backgroundColor: alpha(theme.palette.background.default, 0.75),
                    borderBottom: "1px solid #eae6e7",
                    backdropFilter: "blur(6px)",
                    zIndex: 69,
                }}>
                    <Toolbar>
                        <Stack display="flex" direction="row" justifyContent="space-between" alignItems="center" sx={{
                            width: 1,
                            marginLeft: "40px",
                            marginRight: "32px",
                        }}>
                            <Stack direction="row" alignItems={"center"} gap={1}>
                                <Link to="/" style={{ textDecoration: 'none' }}>
                                    <img src={logoImg} alt={'logo'} height="32px" />
                                </Link>
                                <Label>alpha</Label>
                            </Stack>
                            {/* <Box sx={{ flexGrow: 1, pl: 7, display: { xs: 'none', md: 'flex' } }}>
                            {menus.map(menu => (
                                <Link to={menu.href}
                                    key={menu.text}
                                    onClick={() => {
                                        setPage(menu.text);
                                    }}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Button
                                        sx={{ my: 2, mx: 2, display: 'block', borderRadius: 2 }}
                                        key={menu.text}
                                        color="secondary"
                                        fullWidth
                                    >
                                        {menu.text}
                                    </Button>
                                </Link>
                            ))}
                        </Box> */}

                            {/* <User /> */}
                        </Stack>

                    </Toolbar>

                </AppBar >
            </ElevationScroll >
            <Toolbar />
        </>
    );
}