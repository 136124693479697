export function updateDataAndNewWallets(oldData, chainId, user) {
  const newData = {}
  const newWallets = []
  const removeWallets = []

  for (const walletId in user.wallets) {
    const wallet = user.wallets[walletId]
    const address = wallet["walletAddress"].toLowerCase()
    if (wallet["chainId"] === chainId) {
      if (oldData[address]) {
        newData[address] = oldData[address]
      } else {
        newData[address] = {}
        newWallets.push(address)
      }
    }
  }

  for (const walletId in user.pendingWallets) {
    const wallet = user.pendingWallets[walletId]
    const address = wallet["walletAddress"].toLowerCase()
    if (wallet["chainId"] === chainId) {
      if (oldData[address]) {
        newData[address] = oldData[address]
      } else {
        newData[address] = {}
        newWallets.push(address)
      }
    }
  }

  for (const walletId in user.watchingWallets) {
    const wallet = user.watchingWallets[walletId]
    const address = wallet["walletAddress"].toLowerCase()
    if (wallet["chainId"] === chainId) {
      if (oldData[address]) {
        newData[address] = oldData[address]
      } else {
        newData[address] = {}
        newWallets.push(address)
      }
    }
  }

  for (const wallet in oldData) {
    if (!newData[wallet]) {
      removeWallets.push(wallet)
    }
  }

  return [newData, newWallets, removeWallets]
}