import { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import redditImg from '../../images/reddit.png'
// @ts-ignore
import { useUserContext } from '../../context/UserContext.tsx';
import * as apis from '../../utils/apirequests.js'

function RedditOAuth({ onUserConnected = null, showAtAccountPage = false, onSocialAccountSelected = null, socialAccountSelected = null }) {
  const userUuid = useRef<string | null>(null)
  const { user } = useUserContext()
  const [redditUsername, setRedditUsername] = useState(null)
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true)

  const fetchRedditUsername = async () => {
    if (userUuid.current) {
      const jsonData = await apis.backendRequest("get_user_metadata", {
        "user_uuid": userUuid.current,
      });
      return jsonData.reddit_username
    }
  }

  const fetchRedditUsernameLongPoll = async () => {
    if (userUuid.current) {
      const jsonData = await apis.backendRequest("get_user_reddit", {
        "user_uuid": userUuid.current,
      });
      return jsonData.reddit_username
    }
  }

  const REDDIT_AUTH_URL = "https://www.reddit.com/api/v1/authorize"
  const REDDIT_SCOPE = ["identity"].join(" ")
  const REDDIT_CLIENT_ID = "pZSFzgN5DxN9POfwDeVqcA"
  const REDDIT_PROD_CLIENT_ID = "zRBggzqusdB4Zkh8aBs97A"
  const REDDIT_RESPONSE_TYPE = "code"
  const REDDIT_STATE = "random"
  const REDDIT_DURATION = "permanent"
  const REDDIT_COLOR_CODE = "#FF4500"

  const getURLWithQueryParams = (
    baseUrl: string,
    params: Record<string, any>
  ) => {
    const query = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&")

    return `${baseUrl}?${query}`
  }

  const getRedditOAuthUrl = (redirectUri: string, isProd: boolean) =>
    getURLWithQueryParams(REDDIT_AUTH_URL, {
      response_type: REDDIT_RESPONSE_TYPE,
      client_id: isProd ? REDDIT_PROD_CLIENT_ID : REDDIT_CLIENT_ID,
      redirect_uri: redirectUri,
      scope: REDDIT_SCOPE,
      state: REDDIT_STATE,
      duration: REDDIT_DURATION,
    })

  useEffect(() => {
    fetchRedditUsername().then((username) => {
      setRedditUsername(username)
      if (onUserConnected) {
        onUserConnected(username)
      }
      setIsLoading(false)
    })
  }, [onUserConnected])

  // Whenever local user state finds a new userUuid, get a valid token for the user
  useEffect(() => {
    if (user) {
      userUuid.current = user.userUuid
      fetchRedditUsername().then((username) => {
        setRedditUsername(username)
        if (onUserConnected) {
          onUserConnected(username)
        }
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const flowid = params.get("flowid");
    // Do not need to authorize if we already have the user's reddit username
    if (isLoading || !code || redditUsername || !userUuid.current) return
    const fetchData = async () => {
      const redirect_uri = localStorage.getItem("redirect_uri")
      await apis.backendRequest("get_reddit_access_token_and_username", { code: code, flowid: flowid, user_uuid: userUuid.current, redirect_uri: redirect_uri })
      window.close()
    }
    fetchData()
  }, [location, redditUsername, isLoading, onUserConnected]);

  const handleRedditConnect = async () => {
    if (!showAtAccountPage && onSocialAccountSelected) {
      onSocialAccountSelected()
    }
    if (redditUsername) {
      return;
    }
    // Start long poll if user starts Reddit connection
    fetchRedditUsernameLongPoll().then((username) => {
      setRedditUsername(username)
      if (onUserConnected) {
        onUserConnected(username)
      }
      setIsLoading(false)
    })
    if (window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1")) {
      window.open(getRedditOAuthUrl("http://127.0.0.1:3000/proofs/", false), "", 'toolbar=0,status=0,width=900,height=650');
    } else {
      window.open(getRedditOAuthUrl("https://prove.jomo.id/proofs/", true), "", 'toolbar=0,status=0,width=900,height=650');
    }
    localStorage.setItem("redirect_uri", window.location.href)
  }

  const handleRedditDisconnect = async () => {
    await apis.backendRequest("disconnect_reddit", { user_uuid: userUuid.current })
    setRedditUsername(null)
    if (onUserConnected) {
      onUserConnected(null)
    }
  }

  let content = null;
  if (showAtAccountPage) {
    content = <Stack direction="row" alignItems='center'>
      {<Avatar alt="Reddit" src={redditImg} variant="square" />}
      {redditUsername && <Typography variant="subtitle1" color="text.secondary" sx={{ m: '12px' }}>{redditUsername}</Typography>}
      {!redditUsername && <Typography variant="h6" color={REDDIT_COLOR_CODE} onClick={() => { handleRedditConnect() }} sx={{ m: '12px', cursor: 'pointer' }}>Connect Reddit</Typography>}
    </Stack >
  } else {
    content = <Stack direction="row" alignItems='center'>
      {socialAccountSelected === "Unchosen" && <Avatar alt="Reddit" src={redditImg} variant="square" onClick={() => { handleRedditConnect() }} sx={{ cursor: "pointer" }} />}

      {socialAccountSelected === "Reddit" && redditUsername && <Avatar alt="Reddit" src={redditImg} variant="square" />}
      {socialAccountSelected === "Reddit" && redditUsername && <Typography variant="subtitle1" color="text.secondary" sx={{ m: '12px' }}>{redditUsername}</Typography>}
    </Stack >
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems='center'>
      <Stack direction="row" alignItems='center'>
        {content}
      </Stack >
      {redditUsername && showAtAccountPage &&
        <Box sx={{ cursor: 'pointer' }} onClick={handleRedditDisconnect}>
          <Typography variant="caption" color={"text.secondary"}>
            Disconnect
          </Typography>
        </Box>
      }
    </Stack >
  )
}

export default RedditOAuth;