const { poseidon } = require('circomlib')


export function calculateMerkleRoot(elements, levels) {
  let currentStack = []
  for (let i = 0; i < elements.length; i++) {
    // eslint-disable-next-line valid-typeof
    if (typeof elements[i] === 'BigNumber') {
      currentStack.push(elements[i].toBigInt())
    } else {
      // eslint-disable-next-line no-undef
      currentStack.push(BigInt(elements[i]))
    }
  }

  while (levels > 0) {
    const newStack = [];
    for (let i = 0; i * 2 < currentStack.length; i++) {
      const val1 = currentStack[i * 2]
      const val2 = i * 2 + 1 < currentStack.length ? currentStack[i * 2 + 1] : 0
      if (val2 === 0 || val1 < val2) {
        newStack.push(poseidon([val1, val2]))
      } else {
        newStack.push(poseidon([val2, val1]))
      }
    }
    currentStack = newStack
    levels--
  }
  return currentStack[0]
}

export function calculateMerklePath(elements, position, levels) {
  var current_stack = []
  var path = []
  var pathIndices = []
  for (var i = 0; i < elements.length; i++) {
    // eslint-disable-next-line valid-typeof
    if (typeof elements[i] === 'BigNumber') {
      current_stack.push(elements[i].toBigInt())
    } else {
      // eslint-disable-next-line no-undef
      current_stack.push(BigInt(elements[i]))
    }
  }

  while (levels > 0) {
    var new_stack = []
    for (i = 0; i * 2 < current_stack.length; i++) {
      const val1 = current_stack[i * 2];
      const val2 = i * 2 + 1 < current_stack.length ? current_stack[i * 2 + 1] : 0
      if (val2 === 0 || val1 < val2) {
        new_stack.push(poseidon([val1, val2]))
      } else {
        new_stack.push(poseidon([val2, val1]))
      }
      if (i * 2 === position || i * 2 + 1 === position) {
        var selfVal, otherVal
        if (i * 2 === position) {
          selfVal = val1
          otherVal = val2
        } else {
          selfVal = val2
          otherVal = val1
        }
        path.push(otherVal)
        pathIndices.push(otherVal === 0 || selfVal < otherVal ? 0 : 1)
      }
    }
    position = Math.floor(position / 2)
    current_stack = new_stack
    levels--
  }
  return { path, pathIndices }
}

export function calculateUserAccountRoot(elements, levels, userSecretHash) {
  const walletMerkleRoot = calculateMerkleRoot(elements, levels)
  const userAccountRoot = poseidon([userSecretHash, walletMerkleRoot])
  return userAccountRoot
}