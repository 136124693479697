import { m } from 'framer-motion';
import { useEffect, useState, useRef } from 'react'
import { styled, alpha } from '@mui/material/styles';
import { Button, Collapse, Stack, Typography, Box } from '@mui/material';
import Paper from '@mui/material/Paper';
// @ts-ignore
import { useUserContext } from '../../context/UserContext.tsx';
import Iconify from '../../components/iconify/index';
import * as apis from '../../utils/apirequests.js'
import * as cryptographic from '../../utils/cryptographic.js'
import TwitterOAuth from '../social/TwitterOAuth';
import RedditOAuth from '../social/RedditOAuth';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2.5),
  backgroundColor: alpha(theme.palette.grey[100], 1),
  borderRadius: 10,
  maxWidth: 450,
  width: "100%",
}));

function ProveOrShareNotarization({ flowDetails, validateVerificationProof, redirectBack, notarizationResult = null }) {
  const userUuid = useRef<string | null>(null)
  const sessionInfo = useRef({
    token: null,
    expiresAt: 0,
  })
  const encryptionKey = useRef(null)
  const { user, session } = useUserContext()

  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(false)
  const [clientInfo, setClientInfo] = useState({})
  // Notarization is completed and pending proof generation
  const [provable, setProvable] = useState(false)
  // The proof is being generated
  const [proving, setProving] = useState(false)
  // The proof has been generated (both notarization and social media connection done) and pending share to social media
  const [proved, setProved] = useState(false)
  const [verificationShareableId, setVerificationShareableId] = useState(null)
  const [socialAccountSelected, setSocialAccountSelected] = useState("Unchosen")

  const vcInfo = useRef(null)
  const verificationInfo = useRef(null)
  const proofZkeys = useRef({})
  const notarizationRes = useRef(null)
  const publicAccountId = useRef(null)

  function getProvingBgColor(failed, proved) {
    if (failed) {
      return alpha('#FEE4D1', 0.3)
      // } else if (proved) {
      //   return alpha('#F0FDD3', 0.5)
    } else {
      return "transparent"
    }
  }

  async function onTwitterUserConnected(twitter_username) {
    if (!twitter_username) {
      publicAccountId.current = null;
      return;
    }
    publicAccountId.current = "twitter@" + twitter_username
    if (notarizationRes.current) {
      setProvable(true)
    }
  }

  async function onRedditUserConnected(reddit_username) {
    if (!reddit_username) {
      publicAccountId.current = null;
      return;
    }
    publicAccountId.current = "reddit@" + reddit_username
    if (notarizationRes.current) {
      setProvable(true)
    }
  }

  async function getProofZkey(proofType) {
    if (proofType in Object.keys(proofZkeys.current)) {
      return proofZkeys.current[proofType]
    }
    proofZkeys.current[proofType] = await cryptographic.getProofFile(proofType)
    return proofZkeys.current[proofType]
  }

  async function generateProofFromNotarization(redirect = false) {
    setProving(true)

    const localSecret = notarizationRes.current.secret

    // Store VC attestation and value locally
    localStorage.setItem(
      "private_vc_" + verificationInfo.current.vc_type + "_" + notarizationRes.current.unique_id,
      JSON.stringify({
        attestation: notarizationRes.current.attestation,
        secret: localSecret.toString(),
        raw_elements: notarizationRes.current.raw_elements,
      }),
    )

    const challenge = await apis.backendRequest("verify/generate_proof_challenge", {})
    const values = notarizationRes.current.raw_elements
    const valueInRangeProof = await cryptographic.generateVcHasValueInRangeProof(
      await getProofZkey(`g_vc_has_${values.length}_value_in_range`),
      values,
      Array(values.length).fill(1),
      notarizationRes.current.raw_elements,
      Array(values.length).fill(1),
      values,
      challenge.challenge,
      localSecret,
    )
    const [verificationResult, verification_shareable_id] = await validateVerificationProof(
      notarizationRes.current.attestation,
      valueInRangeProof.proof,
      valueInRangeProof.publicResults,
      publicAccountId.current,
    )
    if (verification_shareable_id) {
      setVerificationShareableId(verification_shareable_id)
    }

    if (verificationResult) {
      setSuccess(true)
      setProved(true)
      if (redirect) {
        redirectBack()
      }
    } else {
      setFailed(true)
    }
  }

  const initializeFlow = async () => {
    setClientInfo(flowDetails.client_info)
    vcInfo.current = flowDetails.vc_info
    verificationInfo.current = flowDetails.verification_info
    return flowDetails.flow_info.name
  }

  // Whenever local user state finds a new userUuid, get a valid token for the user
  useEffect(() => {
    if (user) {
      encryptionKey.current = cryptographic.recoverShardedKeys([user.encryptionKeyLocal, JSON.parse(localStorage.getItem("encryptionKeyServer"))])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // Whenever local user state finds a new userUuid, get a valid token for the user
  useEffect(() => {
    if (user && flowDetails) {
      userUuid.current = user.userUuid
      initializeFlow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, flowDetails])

  // Whenever global session updates, update the local ref
  useEffect(() => {
    sessionInfo.current = session
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  // Whenever global session updates, update the local ref
  useEffect(() => {
    notarizationRes.current = notarizationResult
    if (notarizationRes.current && (!flowDetails.flow_info["no_client"] || publicAccountId.current)) {
      setProvable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notarizationResult])

  // Generate the proof while notarization is finished and social account connected.
  useEffect(() => {
    if (publicAccountId.current && provable && socialAccountSelected !== "Unchosen") {
      generateProofFromNotarization()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provable, socialAccountSelected])

  useEffect(() => {
    if (verificationShareableId) {
      handleRedirectToProofDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationShareableId])

  const handleRedirectToProofDetail = () => {
    window.open(`../verification?verificationshareableid=${verificationShareableId}`, "_self");
  }

  const setTwitterSelected = () => {
    setSocialAccountSelected("Twitter")
  }

  const setRedditSelected = () => {
    setSocialAccountSelected("Reddit")
  }

  return (
    <>
      {flowDetails.flow_info["no_client"] &&
        <Item>
          <Stack gap={2}>
            <Stack direction={"row"} gap={2} alignItems={"center"}>
              <Stack minHeight={36} minWidth={36} borderRadius={5} border={"1px solid"} alignItems={"center"} justifyContent={"center"}>
                <Iconify height={20} width={20} icon="ri:number-2" />
              </Stack>
              <Typography variant="body1" textAlign="left">
                Select a social account to bind
              </Typography>
            </Stack>
            <Stack gap={1} alignItems={"center"} paddingLeft={2}>
              <Collapse in={true}>
                <Stack direction={"row"} justifyContent={"start"} alignItems={"center"} gap={2}>
                  {!proved && ["Twitter", "Unchosen"].includes(socialAccountSelected) && <TwitterOAuth onUserConnected={onTwitterUserConnected} socialAccountSelected={socialAccountSelected} onSocialAccountSelected={setTwitterSelected} />}
                  {!proved && ["Reddit", "Unchosen"].includes(socialAccountSelected) && <RedditOAuth onUserConnected={onRedditUserConnected} socialAccountSelected={socialAccountSelected} onSocialAccountSelected={setRedditSelected} />}
                </Stack>
              </Collapse>
            </Stack>
          </Stack >
        </Item >
      }
      {/* {
        flowDetails.flow_info["no_client"] && proved && <Typography variant='h6' color="text.secondary" sx={{ mt: '20px', cursor: 'pointer', textDecoration: "underline" }} onClick={handleRedirectToProofDetail}>
          View the proof
        </Typography>
      } */}

      {
        !flowDetails.flow_info["no_client"] &&
        <Item sx={{ padding: 0, width: 1 }}>
          {!proving &&
            <Button variant='soft' sx={{ padding: 2.5, width: 1 }} disabled={!provable} onClick={() => generateProofFromNotarization(true)}>
              <Stack direction={"row"} gap={2} alignItems={"center"} width={1}>
                <Stack minHeight={36} minWidth={36} borderRadius={5} border={"1px solid"} alignItems={"center"} justifyContent={"center"}>
                  <Iconify height={20} width={20} icon="ri:number-2" />
                </Stack>
                <Typography variant="body1" textAlign="left">
                  Prove to {clientInfo["name"]}
                </Typography>
              </Stack>
            </Button>
          }
          <Collapse in={proving}>
            {proving &&
              <Box
                sx={{ width: 1, borderRadius: "10px" }}
                component={m.div}
              >
                <Box sx={{ padding: 2.5, width: 1, borderRadius: "10px", background: getProvingBgColor(failed, success) }}>
                  <Stack>
                    <Stack direction={"row"} gap={2} alignItems={"center"} width={1}>
                      <Stack minHeight={36} minWidth={36} alignItems={"center"} justifyContent={"center"}>
                        {!success && !failed &&
                          <Iconify height={36} width={36} icon="line-md:loading-loop" />
                        }
                        {success &&
                          <Iconify height={36} width={36} icon="material-symbols:check" />
                        }
                        {failed &&
                          <Iconify height={36} width={36} icon="gridicons:cross" />
                        }
                      </Stack>
                      {!success && !failed &&
                        <Typography variant="body1" textAlign="left">
                          Generating proof and verifying with {clientInfo["name"]}
                        </Typography>
                      }
                      {success &&
                        <Typography variant="body1" textAlign="left">
                          Successfully Verified
                        </Typography>
                      }
                      {failed &&
                        <Typography variant="body1" textAlign="left">
                          Verification Failed
                        </Typography>
                      }
                    </Stack>
                    <Collapse in={!success && !failed}>
                      <Typography variant="body2" textAlign="center" mt={2}>
                        This usually takes up to 10 seconds
                      </Typography>
                    </Collapse>
                  </Stack>
                </Box>
              </Box>
            }
          </Collapse>
        </Item>
      }
    </>
  )
}

export default ProveOrShareNotarization
