import { useEffect, useState, useRef } from 'react'
import { Button, Collapse, Stack, TextField, Typography, Avatar } from '@mui/material';
// @ts-ignore
import { useUserContext } from '../../../context/UserContext.tsx';
import Iconify from '../../../components/iconify';
import * as apis from '../../../utils/apirequests.js'
import * as utils from './utils'
import turbotaxImg from '../../../images/turbotax.svg'


function TurboTax({ onNotarizationComplete, extensionFound }) {
  const userUuid = useRef<string | null>(null)
  const sessionInfo = useRef({
    token: null,
    expiresAt: 0,
  })
  const { user, session } = useUserContext()

  const [credentialEnabled, setCredentialEnabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [loadingFailed, setLoadingFailed] = useState(false)
  const [loadingText, setLoadingText] = useState("")
  const [notarizeDetails, setNotarizeDetails] = useState("")
  const [verifiedData, setVerifiedData] = useState(null)
  const [verifiedTimestamp, setVerifiedTimestamp] = useState(null)
  const [uniqueId, setUniqueId] = useState(null);

  const notarizeDetailedText = useRef("")

  async function notarizeTurboTaxGrossIncome() {
    setCredentialEnabled(false)
    setLoading(true)
    setLoadingText("Logging in...")

    // @ts-ignore
    chrome.runtime.sendMessage(
      "nmdnfckjjghlbjeodefnapacfnocpdgm",
      {
        type: "prepareSession",
        redirectUrl: "https://myturbotax.intuit.com",
        urlFilters: ["https://us-east-2.turbotaxonline.intuit.com/myturbotax/services/v1/taxCasterContent/2022"]
      },
      {},
      (response) => {
        const authorizationHeader = response.headers.Authorization
        const cookieHeader = response.headers.Cookie
        notarizeWithAuthentication(authorizationHeader, cookieHeader)
      },
    )
  }


  async function notarizeWithAuthentication(authorizationHeader, cookieHeader) {
    const server = "us-east-2.turbotaxonline.intuit.com"

    const headersWithBearer = new Map([
      ["Authorization", authorizationHeader],
      ["Cookie", cookieHeader],
      ["Host", server],
      ["Accept", "application/json, text/javascript, */*; q=0.01"],
    ])

    const userid_regex = /userIdentifier=[0-9]+;/;
    const userid_str = cookieHeader.match(userid_regex)[0];

    setLoadingText("Fetching and notarizing data from TurboTax ...")

    const dataPath = `myturbotax/services/v1/taxCasterContent/2022`
    const dataMethod = "GET"
    const keysToNotarize = [["totalIncome"]]
    const notarizationProof = await utils.notarizeRequest(
      server, dataPath, dataMethod, {}, headersWithBearer,
      [userid_str],
      [],
      keysToNotarize,
      notarizeDetailedText,
      setNotarizeDetails,
    )
    console.log("success")

    let res = await apis.backendRequest("generate_notary_attestation", {
      attestation_name: "yearly_gross_income",
      tax_source: "TurboTax",
      session_proof: notarizationProof["session_proof"],
      substrings_proof: notarizationProof["substrings_proof"],
      body_start: notarizationProof["body_start"],
    })
    if (res.validated) {
      setVerifiedData(res.raw_elements)
      setVerifiedTimestamp(res.attestation.sig.message.time)
      setUniqueId(res.unique_id)
      setLoaded(true)
      onNotarizationComplete(res)
    } else {
      setLoadingFailed(true)
    }
    setLoading(false)
  }

  // Whenever local user state finds a new userUuid, get a valid token for the user
  useEffect(() => {
    if (user) {
      userUuid.current = user.userUuid
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // Whenever global session updates, update the local ref
  useEffect(() => {
    sessionInfo.current = session
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  useEffect(() => {
    const areas = document.getElementsByClassName("MuiInputBase-inputMultiline")
    if (notarizeDetails) {
      for (var i = 0; i < areas.length; i++) {
        const area = areas.item(i)
        area.scrollTop = area.scrollHeight
      }
    }
  }, [notarizeDetails]);

  return (
    <Stack gap={2}>
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        {!loaded &&
          <Stack minHeight={36} minWidth={36} borderRadius={5} border={"1px solid"} alignItems={"center"} justifyContent={"center"}>
            <Iconify height={20} width={20} icon="ri:number-1" />
          </Stack>
        }
        {loaded &&
          <Stack minHeight={36} minWidth={36} borderRadius={5} alignItems={"center"} justifyContent={"center"}>
            <Iconify height={36} width={36} icon="material-symbols:check" />
          </Stack>
        }
        {!loaded &&
          <Typography variant="body1" textAlign="left">
            Login to TurboTax to prove your gross income
          </Typography>
        }
        {loaded &&
          <Typography variant="body1" textAlign="left">
            Successfully Fetched and Notarized
          </Typography>
        }

      </Stack>
      {!loaded &&
        <Stack alignItems={"center"} paddingLeft={2}>
          <Collapse in={credentialEnabled} sx={{ width: 1, maxWidth: "450px" }}>
            <Stack alignItems={"center"} sx={{ width: 1, maxWidth: "450px" }} spacing={2}>
              <Button disabled={loading || !extensionFound} variant="contained" onClick={() => { notarizeTurboTaxGrossIncome() }}>Login TurboTax and Prove</Button>
            </Stack>
          </Collapse>
          <Collapse in={loading} sx={{ width: 1, maxWidth: "450px", alignContent: "center" }}>
            <Typography variant="body1" textAlign={"center"}>{loadingText}</Typography>
          </Collapse>
          <Collapse in={loadingFailed}>
            <Typography variant="body1">Failed to fetch data</Typography>
          </Collapse>
        </Stack>
      }
      {notarizeDetails &&
        <TextField
          size="small"
          fullWidth
          multiline
          minRows={3}
          maxRows={10}
          margin="dense"
          variant="outlined"
          label="Notarization process details"
          value={notarizeDetails}
          contentEditable={false}
          onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
        />
      }
      {loaded &&
        <Stack gap={1} alignItems={"left"} paddingX={5} width={1}>
          <Stack direction="row" spacing={1} alignItems='center'>
            <Avatar alt="TurboTax" src={turbotaxImg} sx={{ width: 24, height: 24 }} />
            <Typography gutterBottom variant="h6" component="div">
              TurboTax
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={"space-between"}>
            <Typography variant="subtitle2">
              Randomized Unique Id
            </Typography>
            <Typography variant="body2">{uniqueId}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={"space-between"}>
            <Typography variant="subtitle2">
              Gross Income
            </Typography>
            <Typography variant="body2">
              ${verifiedData[2]}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems='center' justifyContent={"space-between"}>
            <Typography variant="subtitle2">
              Notarized
            </Typography>
            <Typography variant="body2">{new Date(verifiedTimestamp * 1000).toDateString()}</Typography>
          </Stack>
        </Stack>
      }
    </Stack>
  )
}

export default TurboTax
