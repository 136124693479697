import { Box, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/Button';
import { CustomAvatar } from '../../components/custom-avatar';
import ethereumBlackImg from '../../images/ethereum-black.png';
import NewWallet from '../NewWallet';

export default function WalletsListCard(props) {
  const user = props.user

  const getAddressNickname = function (address) {
    if (!user || !user.addressNicknames) return null
    return user.addressNicknames[address]
  }

  return (
    <Box sx={{ p: 4 }}>
      <Typography color="text.main" variant="h4" textAlign="left" sx={{ mb: 4 }}>
        Wallets
      </Typography>

      {user &&
        <Box sx={{ p: 2, minWidth: 240, border: 1, borderRadius: "16px", borderColor: "secondary.light" }}>
          <Stack gap={2}>
            {user && user.wallets.map((wallet) =>
              <Box sx={{ minWidth: 240, border: 1, borderRadius: "16px", borderColor: "secondary.light" }} key={wallet.walletAddress}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 1.5 }}>
                  <Stack direction="row">
                    <IconButton disabled sx={{ padding: 0 }}>
                      <CustomAvatar sx={{ width: "56px", height: "56px" }} alt="Ethereum" src={wallet.chainId === "evm" ? ethereumBlackImg : ethereumBlackImg} />
                    </IconButton>
                    <Stack direction="column" justifyContent="center" gap={0.5} alignItems="flex-start" sx={{ mx: 2 }}>
                      {getAddressNickname(wallet.walletAddress) &&
                        <>
                          <Typography color="inherit" fontSize={"1.125rem"} fontWeight={"regular"}>
                            {getAddressNickname(wallet.walletAddress)}
                          </Typography>
                          <Typography color="inherit" variant="caption">
                            {wallet.walletAddress}
                          </Typography>
                        </>
                      }
                      {!getAddressNickname(wallet.walletAddress) &&
                        <Typography color="inherit" variant="body2">
                          {wallet.walletAddress}
                        </Typography>
                      }
                    </Stack>
                  </Stack>

                  <Typography color="secondary.dark" variant="caption" sx={{ mr: 2 }}>
                    Verified
                  </Typography>
                </Stack>
              </Box>
            )}
            {user && user.watchingWallets.map((wallet) =>
              <Box sx={{ minWidth: 240, border: 1, borderRadius: "16px", borderColor: "secondary.light" }} key={wallet.walletAddress}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 1.5 }}>
                  <Stack direction="row">
                    <IconButton disabled sx={{ padding: 0 }}>
                      <CustomAvatar sx={{ width: "56px", height: "56px" }} alt="Ethereum" src={wallet.chainId === "evm" ? ethereumBlackImg : ethereumBlackImg} />
                    </IconButton>
                    <Stack direction="column" justifyContent="center" gap={0.5} alignItems="flex-start" sx={{ mx: 2 }}>
                      {getAddressNickname(wallet.walletAddress) &&
                        <>
                          <Typography color="inherit" fontSize={"1.125rem"} fontWeight={"regular"}>
                            {getAddressNickname(wallet.walletAddress)}
                          </Typography>
                          <Typography color="inherit" variant="caption">
                            {wallet.walletAddress}
                          </Typography>
                        </>
                      }
                      {!getAddressNickname(wallet.walletAddress) &&
                        <Typography color="inherit" variant="body2">
                          {wallet.walletAddress}
                        </Typography>
                      }
                    </Stack>
                  </Stack>

                  <Typography color="secondary.dark" variant="caption" sx={{ mr: 2 }}>
                    Watchlist
                  </Typography>
                </Stack>
              </Box>
            )}
            <Box>
              <NewWallet chainId="evm" sx={{ width: "240px" }} />
            </Box>
          </Stack >
        </Box >}

    </Box>
  )
}