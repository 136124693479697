import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Grid, Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(6),
  textAlign: 'center',
  margin: 16,
  color: theme.palette.secondary.dark,
  backgroundColor: alpha(theme.palette.primary.main, 0.05)
}));

export default function EthereumAggregated(props) {
  const totalGasFee: any = Object.values(props.ethereumData).reduce((accumulator, value) => {
    return accumulator + value["ethGasFee"] || 0.0;
  }, 0);
  const totalAssetsValue: any = Object.values(props.ethereumData).reduce((accumulator, value) => {
    return accumulator + value["ethTotalBalance"] || 0.0;
  }, 0);

  return (
    <Box sx={{ mt: 3, mr: -2, ml: -2 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 9, md: 12 }} item columnSpacing={2}>
        <Grid xs={2} sm={3} md={4} item>
          <Item>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {Object.keys(props.ethereumData).length}
            </Typography>
            <Typography variant="body1">
              Total Wallets
            </Typography>
          </Item>
        </Grid>
        <Grid xs={2} sm={3} md={4} item>
          <Item>
            <Typography variant="h4" sx={{ mb: 2 }}>
              ${totalAssetsValue.toFixed(2)}
            </Typography>
            <Typography variant="body1">
              Total Assets
            </Typography>
          </Item>
        </Grid>
        <Grid xs={2} sm={3} md={4} item>
          <Item>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {totalGasFee.toFixed(3)} ETH
            </Typography>
            <Typography variant="body1">
              Total Gas Fee
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  )
}