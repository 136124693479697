import { useEffect, useRef } from 'react'
import { Box, Grid, Stack, Divider } from '@mui/material';
// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import WalletsListCard from './account/WalletsListCard'
import {
    NavSectionVertical,
} from '../components/nav-section';
import Iconify from '../components/iconify';
import AccountRecovery from './account/AccountRecovery';

function Account({ subsection }) {
    const userUuid = useRef<string | null>(null)
    const sessionInfo = useRef({
        token: null,
        expiresAt: 0,
    })
    const { user, session, setPage } = useUserContext()

    // Whenever local user state finds a new userUuid, get a valid token for the user
    useEffect(() => {
        if (user) {
            userUuid.current = user.userUuid
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Whenever global session updates, update the local ref
    useEffect(() => {
        sessionInfo.current = session
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session])

    // Whenever page loads, load locally stored data
    useEffect(() => {
        setPage("Account")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Box sx={{ my: "20px", mx: "40px" }}>
            <Stack sx={{ marginTop: "-20px", width: 1 }} direction={"row"} divider={(<Divider flexItem orientation='vertical' />)}>
                <Grid sm={2} md={2} item sx={{ width: 0.2, minWidth: "150px" }}>
                    <NavSectionVertical
                        data={NAV_ITEMS}
                        sx={{
                            py: 2,
                            borderRadius: 2,
                            bgcolor: 'background.paper',
                            alignItems: "flex-start"
                        }}
                    />
                </Grid>
                <Grid sm={10} md={10} item sx={{ width: 0.8, minWidth: "650px" }}>
                    {subsection === "wallets" && <WalletsListCard user={user} />}
                    {subsection === "recovery" && <AccountRecovery />}
                </Grid>
            </Stack>
        </Box>
    )
}

export default Account

const NAV_ITEMS = [
    {
        subheader: 'Settings',
        items: [
            {
                title: 'Wallets',
                path: '/account/wallets',
                icon: <Iconify icon="ic:baseline-circle" width={"4px"}></Iconify>,
                activeIcon: <Iconify icon="ic:baseline-circle" width={"8px"}></Iconify>
            },
            {
                title: 'Recovery',
                path: '/account/recovery',
                icon: <Iconify icon="ic:baseline-circle" width={"4px"}></Iconify>,
                activeIcon: <Iconify icon="ic:baseline-circle" width={"8px"}></Iconify>
            },
        ],
    },
];
