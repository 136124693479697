import { v4 as uuidv4 } from 'uuid';
const { poseidon, pedersenHash, babyJub } = require('circomlib')
const crypto = require('crypto');
const sss = require('shamirs-secret-sharing')
const localforage = require("localforage");
const { calculateMerklePath } = require('./merkle')

export function generateWalletHash(chainId, walletAddress) {
  const md5 = crypto.createHash('md5').update(chainId.toLowerCase() + walletAddress.toLowerCase()).digest('hex');
  // eslint-disable-next-line no-undef
  return BigInt(`0x${md5.substring(0, 16)}`);
}

export function generateSecretAndHash() {
  const secret_uuid = uuidv4()
  const md5 = crypto.createHash('md5').update(secret_uuid).digest('hex');
  // eslint-disable-next-line no-undef
  const secret = BigInt(`0x${md5.substring(0, 16)}`)
  return {
    'secret': secret.toString(),
    'secret_hash': poseidon([secret]).toString(),
  }
}

export function generateStrHash(str) {
  const md5 = crypto.createHash('md5').update(str).digest('hex');
  // eslint-disable-next-line no-undef
  return BigInt(`0x${md5.substring(0, 8)}`);
}

export async function generateAccountOwnershipAttestedProof(
  zkeyFastFile,
  accountHash,
  ownerSecret,
  attestationPath,
  attestationPathIndices,
) {
  const worker = new Worker("/worker_g.js");
  const resData = new Promise((resolve, reject) => {
    worker.onmessage = async function (e) {
      const [success, res] = e.data;
      if (!success) {
        reject(res)
        return
      }

      const rootAttestation = res.publicSignals[0]
      const accountSecretHash = res.publicSignals[1]
      const proof = JSON.stringify(res.proof, (key, value) =>
        typeof value === 'bigint'
          ? value.toString()
          : value // return everything else unchanged
      )

      const data = {
        "publicResults": {
          "rootAttestation": rootAttestation,
          "accountSecretHash": accountSecretHash,
        },
        "proof": proof,
      }
      resolve(data)
    }
    worker.postMessage([
      JSON.stringify({
        accountHash: accountHash,
        ownerSecret: ownerSecret,
        attestationPath: attestationPath,
        attestationPathIndices: attestationPathIndices,
      }, (key, value) =>
        typeof value === 'bigint'
          ? value.toString()
          : value // return everything else unchanged
      ),
      "./g_user_account_ownership_attested.wasm",
      zkeyFastFile,
    ]);
  })

  return resData
}

export async function generateUserAccountRootUpdateProof(
  zkeyFastFile,
  maxAccounts,
  userSecretHash,
  accountHash,
  path,
  pathIndicesOld,
  pathIndicesNew,
  ownerSecret,
) {
  const proofName = `g_user_account_root_update_${maxAccounts}`
  const worker = new Worker("/worker_g.js");
  const resData = new Promise((resolve, reject) => {
    worker.onmessage = async function (e) {
      const [success, res] = e.data;
      if (!success) {
        reject(res)
        return
      }

      const accountRootOld = res.publicSignals[0]
      const accountRootNew = res.publicSignals[1]
      const accountSecretHash = res.publicSignals[2]
      const proof = JSON.stringify(res.proof, (key, value) =>
        typeof value === 'bigint'
          ? value.toString()
          : value // return everything else unchanged
      )

      const data = {
        "publicResults": {
          "accountRootOld": accountRootOld,
          "accountRootNew": accountRootNew,
          "accountSecretHash": accountSecretHash,
        },
        "proof": proof,
      }
      resolve(data)
    }
    worker.postMessage([
      JSON.stringify({
        userSecretHash: userSecretHash,
        accountHash: accountHash,
        path: path,
        pathIndicesOld: pathIndicesOld,
        pathIndicesNew: pathIndicesNew,
        ownerSecret: ownerSecret,
      }, (key, value) =>
        typeof value === 'bigint'
          ? value.toString()
          : value // return everything else unchanged
      ),
      `./${proofName}.wasm`,
      zkeyFastFile,
    ]);
  })

  return resData
}

export async function generateUserAccountRootExpandProof(
  zkeyFastFile,
  maxAccounts,
  userSecretHash,
  accountHash,
  path,
  pathIndices,
  ownerSecret,
) {
  const proofName = `g_user_account_root_expand_${maxAccounts}`
  const worker = new Worker("/worker_g.js");
  const resData = new Promise((resolve, reject) => {
    worker.onmessage = async function (e) {
      const [success, res] = e.data;
      if (!success) {
        reject(res)
        return
      }

      const accountRootOld = res.publicSignals[0]
      const accountRootNew = res.publicSignals[1]
      const accountSecretHash = res.publicSignals[2]
      const proof = JSON.stringify(res.proof, (key, value) =>
        typeof value === 'bigint'
          ? value.toString()
          : value // return everything else unchanged
      )

      const data = {
        "publicResults": {
          "accountRootOld": accountRootOld,
          "accountRootNew": accountRootNew,
          "accountSecretHash": accountSecretHash,
        },
        "proof": proof,
      }
      resolve(data)
    }
    worker.postMessage([
      JSON.stringify({
        userSecretHash: userSecretHash,
        accountHash: accountHash,
        path: path,
        pathIndices: pathIndices,
        ownerSecret: ownerSecret,
      }, (key, value) =>
        typeof value === 'bigint'
          ? value.toString()
          : value // return everything else unchanged
      ),
      `./${proofName}.wasm`,
      zkeyFastFile,
    ]);
  })

  return resData
}

export async function generateUserLoginProof(
  zkeyFastFile,
  userSecret,
  accountMerkleRoot,
  randomizer,
  timestamp,
) {
  const worker = new Worker("/worker_g.js");
  const resData = new Promise((resolve, reject) => {
    worker.onmessage = async function (e) {
      const [success, res] = e.data;
      if (!success) {
        reject(res)
        return
      }

      const userAccountRoot = res.publicSignals[0]
      const nullifier = res.publicSignals[1]
      const proof = JSON.stringify(res.proof, (key, value) =>
        typeof value === 'bigint'
          ? value.toString()
          : value // return everything else unchanged
      )

      const data = {
        "publicResults": {
          "userAccountRoot": userAccountRoot,
          "nullifier": nullifier,
          "timestamp": timestamp,
        },
        "proof": proof,
      }
      resolve(data)
    }
    worker.postMessage([
      JSON.stringify({
        userSecret: userSecret,
        accountMerkleRoot: accountMerkleRoot,
        randomizer: randomizer,
        timestamp: timestamp,
      }, (key, value) =>
        typeof value === 'bigint'
          ? value.toString()
          : value // return everything else unchanged
      ),
      "./g_user_login_new.wasm",
      zkeyFastFile,
    ]);
  })

  return resData
}

export async function generateCommitmentOwnershipProof(zkeyFastFile, account, secret, attestationTree) {
  var attestationElements = new Array(32).fill(0);
  for (const accountHash in attestationTree.tree) {
    const curAccount = attestationTree.tree[accountHash]
    attestationElements[curAccount.idx] = curAccount.attestation
  }
  const commitmentMerklePath = calculateMerklePath(attestationElements, account.details.idx, 5)

  const resData = new Promise((resolve, reject) => {
    var worker = null
    try {
      worker = new Worker("/worker_g.js");
      worker.onmessage = async function (e) {
        const [success, res] = e.data;
        if (!success) {
          reject(res)
          return
        }

        const accountSecretHash = res.publicSignals[0]
        const commitmentSecretHash = res.publicSignals[1]
        const commitmentMerkleRoot = res.publicSignals[2]
        const proof = JSON.stringify(res.proof, (key, value) =>
          typeof value === 'bigint'
            ? value.toString()
            : value // return everything else unchanged
        )

        const data = {
          "publicResults": {
            "accountSecretHash": accountSecretHash,
            "commitmentSecretHash": commitmentSecretHash,
            "commitmentMerkleRoot": commitmentMerkleRoot,
          },
          "proof": proof,
        }
        resolve(data)
      }
      worker.onerror = async function (e) {
        throw new Error("worker error")
      }
      worker.postMessage([
        JSON.stringify({
          // eslint-disable-next-line no-undef
          accountHash: BigInt(account.accountId),
          // eslint-disable-next-line no-undef
          commitment: [BigInt(account.details.commitment_0), BigInt(account.details.commitment_1)],
          commitmentPath: commitmentMerklePath.path,
          commitmentPathIndices: commitmentMerklePath.pathIndices,
          secret: secret,
        }, (key, value) =>
          typeof value === 'bigint'
            ? value.toString()
            : value // return everything else unchanged
        ),
        "./g_commitment_ownership_32.wasm",
        zkeyFastFile,
      ]);
    } catch (e) {
      if (worker) worker.terminate()
      reject("memory exceeded")
    }
  })

  return resData
}

export async function generateHashedAccountOwnershipProof(zkeyFastFile, maxAccounts, account, secret, user, allAccountElements) {
  const proofName = `g_hashed_account_ownership_${maxAccounts}`

  var accountElements = new Array(maxAccounts).fill(0);
  var accountIdx = 0
  for (var i = 0; i < maxAccounts; i++) {
    accountElements[i] = allAccountElements[i]
    // eslint-disable-next-line no-undef
    if (BigInt(account.accountId) === accountElements[i]) {
      accountIdx = i
    }
  }
  const accountMerklePath = calculateMerklePath(accountElements, accountIdx, Math.log2(maxAccounts))

  const resData = new Promise((resolve, reject) => {
    var worker = null
    try {
      worker = new Worker("/worker_g.js");
      worker.onmessage = async function (e) {
        const [success, res] = e.data;
        if (!success) {
          reject(res)
          return
        }

        const accountSecretHash = res.publicSignals[0]
        const userAccountRoot = res.publicSignals[1]
        const proof = JSON.stringify(res.proof, (key, value) =>
          typeof value === 'bigint'
            ? value.toString()
            : value // return everything else unchanged
        )

        const data = {
          "publicResults": {
            "accountSecretHash": accountSecretHash,
            "userAccountRoot": userAccountRoot,
          },
          "proof": proof,
        }
        resolve(data)
      }
      worker.onerror = async function (e) {
        throw new Error("worker error")
      }
      worker.postMessage([
        JSON.stringify({
          // eslint-disable-next-line no-undef
          accountHash: BigInt(account.accountId),
          // eslint-disable-next-line no-undef
          userSecretHash: BigInt(user.userSecret.secret_hash),
          accountPath: accountMerklePath.path,
          accountPathIndices: accountMerklePath.pathIndices,
          secret: secret,
        }, (key, value) =>
          typeof value === 'bigint'
            ? value.toString()
            : value // return everything else unchanged
        ),
        `./${proofName}.wasm`,
        zkeyFastFile,
      ]);
    } catch (e) {
      if (worker) worker.terminate()
      reject("memory exceeded")
    }
  })

  return resData
}

export async function generateCommitmentSumProof(zkeyFastFile, accounts, accountSecrets, dataSchema, numElements, numCommitments, sumSecret) {
  const proofName = `g_sum_${numCommitments}_commitment_${numElements}_elements`

  const commitments = []
  const secrets = []
  const elements = []

  var i = 0
  for (const accountIdx in accounts) {
    const account = accounts[accountIdx]
    // eslint-disable-next-line no-undef
    secrets.push(accountSecrets[account.accountId])
    // eslint-disable-next-line no-undef
    commitments.push([BigInt(account.details.commitment_0), BigInt(account.details.commitment_1)])
    const accountElements = parseElements(account.details.data, dataSchema, numElements)
    elements.push(accountElements)
    i++
  }

  // eslint-disable-next-line no-undef
  const emptyElements = new Array(numElements).fill(BigInt(0))
  while (i < numCommitments) {
    // eslint-disable-next-line no-undef
    secrets.push(BigInt(0))
    elements.push(emptyElements)
    commitments.push(pedersen(emptyElements))
    i++
  }

  const resData = new Promise((resolve, reject) => {
    var worker = null
    try {
      worker = new Worker("/worker_g.js");
      worker.onmessage = async function (e) {
        const [success, res] = e.data;
        if (!success) {
          reject(res)
          return
        }

        const sumCommitment0 = res.publicSignals[0]
        const sumCommitment1 = res.publicSignals[1]
        const commitmentSecretHashes = res.publicSignals.slice(2)
        const proof = JSON.stringify(res.proof, (key, value) =>
          typeof value === 'bigint'
            ? value.toString()
            : value // return everything else unchanged
        )

        const data = {
          "publicResults": {
            "sumCommitment": [sumCommitment0, sumCommitment1],
            "commitmentSecretHashes": commitmentSecretHashes,
          },
          "proof": proof,
        }
        resolve(data)
      }
      worker.onerror = async function (e) {
        throw new Error("worker error")
      }
      worker.postMessage([
        JSON.stringify({
          elements: elements,
          commitments: commitments,
          secrets: secrets,
          sum_secret: sumSecret,
        }, (key, value) =>
          typeof value === 'bigint'
            ? value.toString()
            : value // return everything else unchanged
        ),
        `./${proofName}.wasm`,
        zkeyFastFile,
      ]);
    } catch (e) {
      if (worker) worker.terminate()
      reject("memory exceeded")
    }
  })

  return resData
}

export async function generateVcHasValueInRangeProof(zkeyFastFile, elements, lowerFilter, lowerBounds, upperFilter, upperBounds, challenge, secret) {
  const numElements = elements.length
  const proofName = `g_vc_has_${numElements}_value_in_range`

  const resData = new Promise((resolve, reject) => {
    var worker = null
    try {
      worker = new Worker("/worker_g.js");
      worker.onmessage = async function (e) {
        const [success, res] = e.data;
        if (!success) {
          reject(res)
          return
        }

        const commitment0 = res.publicSignals[0]
        const commitment1 = res.publicSignals[1]
        const withinRange = res.publicSignals[2]
        const outputChallenge = res.publicSignals[3]
        const lowerFilter = res.publicSignals.slice(4, 4 + numElements)
        const lowerBounds = res.publicSignals.slice(4 + numElements, 4 + 2 * numElements)
        const upperFilter = res.publicSignals.slice(4 + 2 * numElements, 4 + 3 * numElements)
        const upperBounds = res.publicSignals.slice(4 + 3 * numElements)
        const proof = JSON.stringify(res.proof, (_, value) =>
          typeof value === 'bigint'
            ? value.toString()
            : value // return everything else unchanged
        )

        const data = {
          "publicResults": {
            "commitment": [commitment0, commitment1],
            "withinRange": withinRange,
            "outputChallenge": outputChallenge,
            "lowerFilter": lowerFilter,
            "lowerBounds": lowerBounds,
            "upperFilter": upperFilter,
            "upperBounds": upperBounds,
          },
          "proof": proof,
        }
        resolve(data)
      }
      worker.onerror = async function (e) {
        throw new Error("worker error")
      }
      worker.postMessage([
        JSON.stringify({
          elements: elements,
          lowerFilter: lowerFilter,
          lowerBounds: lowerBounds,
          upperFilter: upperFilter,
          upperBounds: upperBounds,
          challenge: challenge,
          secret: secret,
        }, (key, value) =>
          typeof value === 'bigint'
            ? value.toString()
            : value // return everything else unchanged
        ),
        `./${proofName}.wasm`,
        zkeyFastFile,
      ]);
    } catch (e) {
      if (worker) worker.terminate()
      reject("memory exceeded")
    }
  })

  return resData
}

async function downloadFromFilename(filename) {
  try {
    const zkeyResp = await fetch("/" + filename, {
      method: "GET",
    });
    const zkeyBuff = await zkeyResp.arrayBuffer();
    await localforage.setItem(filename, zkeyBuff);
    console.log(`Storage of ${filename} successful!`);
    return zkeyBuff
  } catch (e) {
    console.log(
      `Storage of ${filename} unsuccessful, make sure IndexedDB is enabled in your browser.`
    );
  }
}

export const downloadProofFiles = async function (proofType) {
  const zkeySuffix = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const filePromises = [];
  for (const c of zkeySuffix) {
    const item = await localforage.getItem(`${proofType}.zkey${c}`);
    if (item) {
      continue;
    }
    filePromises.push(
      downloadFromFilename(`${proofType}.zkey${c}`)
    );
  }
  await Promise.all(filePromises);
};

export const downloadProofFile = async function (proofType) {
  const item = await localforage.getItem(`${proofType}.zkey`);
  if (item) {
    return
  }
  await downloadFromFilename(`${proofType}.zkey`)
};

export const getProofFile = async function (proofType) {
  const zkeyFile = await localforage.getItem(`${proofType}.zkey`)
  if (zkeyFile) {
    const zkeyRawData = new Uint8Array(zkeyFile);
    return { type: "mem", data: zkeyRawData };
  } else {
    const zkeyRawData = new Uint8Array(await downloadFromFilename(`${proofType}.zkey`))
    return { type: "mem", data: zkeyRawData };
  }
}

export const getProofFileFromChunks = async function (proofType) {
  const zkeySuffix = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const zkeyArrays = []

  // Get the total length of all arrays.
  let length = 0;
  for (const c of zkeySuffix) {
    const item = await localforage.getItem(`${proofType}.zkey${c}`);
    if (!item) {
      console.log(`${proofType}.zkey${c} does not exist!`);
      return;
    }
    const itemArray = new Uint8Array(item);
    length += itemArray.length;
    zkeyArrays.push(itemArray)
  }

  // Create a new array with total length and merge all source arrays.
  const zkeyRawData = new Uint8Array(length);

  let offset = 0;
  zkeyArrays.forEach(item => {
    zkeyRawData.set(item, offset);
    offset += item.length;
  });

  const file = { type: "mem", data: zkeyRawData }
  return file
}

export function getShardedKeys(encryptionKey, shardNum = 3, limitNum = 2) {
  const shards = sss.split(encryptionKey, { shares: shardNum, threshold: limitNum })
  return shards
}

export function recoverShardedKeys(keyShards) {
  const bufferShards = keyShards.map((shard) => Buffer.from(shard))
  return sss.combine(bufferShards)
}

//Encrypting text
export function encryptText(text, key) {
  const iv = crypto.randomBytes(16);
  let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') };
}

// Decrypting text
export function decryptText(text, key) {
  let iv = Buffer.from(text.iv, 'hex');
  let encryptedText = Buffer.from(text.encryptedData, 'hex');
  let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}

function bigIntToLeByteArray(number, nBytes) {
  const result = Array(nBytes).fill(0)
  for (var i = 0; i < nBytes; i++) {
    // eslint-disable-next-line no-undef
    result[i] = parseInt(number % BigInt(256))
    // eslint-disable-next-line no-undef
    number /= BigInt(256)
  }
  return result
}

function pedersen(elements) {
  // eslint-disable-next-line no-undef
  const byteArray = elements.map((element) => bigIntToLeByteArray(BigInt(element), 4)).flat()
  const commitments = babyJub.unpackPoint(pedersenHash.hash(byteArray))
  return commitments
}

function parseElements(data, dataSchema, numElements) {
  const result = []
  var i = 0
  for (const fieldId in dataSchema) {
    const field = dataSchema[fieldId]
    if (field.type === "string") {
      result.push(generateStrHash(data[field.fieldName]))
    } else if (field.type === "integer") {
      // eslint-disable-next-line no-undef
      result.push(BigInt(data[field.fieldName]))
    }
    i++
  }
  while (i < numElements) {
    // eslint-disable-next-line no-undef
    result.push(BigInt(0))
    i++
  }
  return result
}