import { ethers } from "ethers";
import * as apis from '../apirequests.js'

export async function getAccountData(address) {
  const url = "https://zksync2-mainnet-explorer.zksync.io/address/" + address;
  const responseJson = await apis.getUrl(url)
  if (!responseJson) return null

  const result = {}
  const tokenBalances = {}
  for (const token in responseJson.info.balances) {
    const tokenBalance = responseJson.info.balances[token]
    const balance = tokenBalance.balance
    const decimals = tokenBalance.tokenInfo.decimals
    const symbol = tokenBalance.tokenInfo.symbol
    const usdPrice = tokenBalance.tokenInfo.usdPrice
    tokenBalances[symbol] = {
      balance: parseInt(balance, 16) / 10 ** decimals,
      usdPrice: usdPrice,
    }
  }
  result["tokenBalances"] = tokenBalances
  result["txCount"] = responseJson.info.sealedNonce;
  return result
}

export async function getTxs(address) {
  const url = "https://zksync2-mainnet-explorer.zksync.io/transactions?limit=100&direction=older&accountAddress=" + address;
  const responseJson = await apis.getUrl(url)
  if (!responseJson) return null

  if (responseJson.total === 0) {
    return []
  }

  const result = responseJson.list

  if (responseJson.total > 100) {
    const fromBlockNumber = responseJson.list[0].blockNumber;
    const fromTxIndex = responseJson.list[0].indexInBlock;
    var offset = 0

    while (true) {
      const newUrl = url + `&fromBlockNumber=${fromBlockNumber}&fromTxIndex=${fromTxIndex}&offset=${offset}`;
      const newResponseJson = await apis.getUrl(newUrl)
      if (!newResponseJson) break

      const newResponseLength = newResponseJson.list.length;
      result.push(newResponseJson.list)

      if (newResponseLength === 100) {
        offset += newResponseLength;
      } else {
        break;
      }
    }
  }

  return result
}

function getDayStr(date) {
  return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;
}

function getWeekStr(date) {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  return `${d.getUTCFullYear()}-${weekNo}`;
}

function getMonthStr(date) {
  return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)}`;
}

export function understandTransaction(tx, address) {
  const result = {}
  if (tx['balanceChanges'][0]['from'].toLowerCase() !== address.toLowerCase()) {
    return null
  }
  result["contractAddress"] = tx.data.contractAddress;
  result["fee"] = parseFloat((parseInt(tx.fee, 16) / 10 ** 18).toFixed(4))

  const receivedAtTimestamp = Date.parse(tx['receivedAt'])
  result["receivedAt"] = receivedAtTimestamp

  const receivedAtDate = new Date(receivedAtTimestamp);
  result["day"] = getDayStr(receivedAtDate);
  result["week"] = getWeekStr(receivedAtDate);
  result["month"] = getMonthStr(receivedAtDate);
  if (tx.isL1Originated === true) {
    result["isL1ToL2"] = true
    result["l1ToL2Value"] = parseFloat(ethers.utils.formatEther(tx.data.value, "ether"))
  } else if (tx.data.contractAddress === "0x000000000000000000000000000000000000800a") {
    result["isL2ToL1"] = true
    result["l2ToL1Value"] = parseFloat(ethers.utils.formatEther(tx.data.value, "ether"))
  }
  return result
}