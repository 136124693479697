import { Stack, Typography, Chip } from '@mui/material';

export function getTruncatedAddress(address) {
  return address.substring(0, 8) + "......" + address.substring(38)
}

export function judgeCondition(condition, data) {
  if (condition.method === "EQ") {
    return data === condition.value
  }
  if (condition.method === "GE") {
    return data >= condition.value
  }
  if (condition.method === "LE") {
    return data <= condition.value
  }
}

export function isCriteriaMet(data, fields, criteria) {
  for (const fieldId in fields) {
    const field = fields[fieldId]
    if (field.fieldName in criteria) {
      const fieldCriteria = criteria[field.fieldName]
      if (field.type !== "list") {
        const fieldConditions = fieldCriteria.conditions
        for (const conditionId in fieldConditions) {
          const fieldCondition = fieldConditions[conditionId]
          if (!judgeCondition(fieldCondition, data[field.fieldName])) return false
        }
      } else {
        const lenConditions = fieldCriteria.lenConditions
        for (const conditionId in lenConditions) {
          const lenCondition = lenConditions[conditionId]
          if (!judgeCondition(lenCondition, data[field.fieldName].length)) return false
        }

        const itemConditions = fieldCriteria.itemConditions
        const listData = data[field.fieldName]
        const itemCount = listData.length
        for (var i = 0; i < itemCount; i++) {
          const itemData = listData[i]
          const itemSchema = field.listFields
          if (i.toString() in itemConditions) {
            const itemCriteria = itemConditions[i.toString()].criteria
            if (!isCriteriaMet(itemData, itemSchema, itemCriteria)) return false
          } else {
            if (!isCriteriaMet(itemData, itemSchema, {})) return false
          }
        }
      }
    }
  }
  return true
}

export function renderAccountNftHolders(account, verificationType, isMobile) {
  const prefixToLabel = {
    "cryptopunks_sum_gte_1": {
      value: account.details.data.CryptoPunks || 0,
      label: "Punks",
    },
    "cryptopunks_sum_gte_3": {
      value: account.details.data.CryptoPunks || 0,
      label: "Punks",
    },
    "bayc_sum_gte_1": {
      value: account.details.data.BAYC || 0,
      label: "BAYCs",
    },
    "bayc_sum_gte_3": {
      value: account.details.data.BAYC || 0,
      label: "BAYCs",
    },
    "mayc_sum_gte_1": {
      value: account.details.data.MAYC || 0,
      label: "MAYCs",
    },
    "mayc_sum_gte_3": {
      value: account.details.data.MAYC || 0,
      label: "MAYCs",
    },
    "azuki_sum_gte_1": {
      value: account.details.data.Azuki || 0,
      label: "Azukis",
    },
    "azuki_sum_gte_3": {
      value: account.details.data.Azuki || 0,
      label: "Azukis",
    },
    "polygon_ice_sum_gte_1": {
      value: account.details.data.ICE || 0,
      label: "ICEs",
    },
    "station3_patron_sum_gte_1": {
      value: account.details.data.Station3Patron || 0,
      label: "Station3 Patron",
    },
    "mfers_sum_gte_1": {
      value: account.details.data.mfers || 0,
      label: "mfers",
    },
    "mfers_sum_gte_3": {
      value: account.details.data.mfers || 0,
      label: "mfers",
    },
    "mfers_sum_gte_5": {
      value: account.details.data.mfers || 0,
      label: "mfers",
    },
    "clonex_sum_gte_1": {
      value: account.details.data.CloneX || 0,
      label: "CloneX",
    },
    "moonbirds_sum_gte_1": {
      value: account.details.data.Moonbirds || 0,
      label: "Moonbirds",
    },
    "proof_sum_gte_1": {
      value: account.details.data.Proof || 0,
      label: "Proof",
    },
    "doodles_sum_gte_1": {
      value: account.details.data.Doodles || 0,
      label: "Doodles",
    },
    "bakc_sum_gte_1": {
      value: account.details.data.BAKC || 0,
      label: "BAKC",
    },
    "pudgypenguins_sum_gte_1": {
      value: account.details.data.PudgyPenguins || 0,
      label: "Penguins",
    },
    "coolcats_sum_gte_1": {
      value: account.details.data.CoolCats || 0,
      label: "CoolCats",
    },
    "degods_sum_gte_1": {
      value: account.details.data.DeGods || 0,
      label: "DeGods",
    },
    "miladymaker_sum_gte_1": {
      value: account.details.data.MiladyMaker || 0,
      label: "MiladyMaker",
    },
    "0n1force_sum_gte_1": {
      value: account.details.data["0N1Force"] || 0,
      label: "0N1Force",
    },
    "worldofwomen_sum_gte_1": {
      value: account.details.data.WorldOfWomen || 0,
      label: "World of Women",
    },
    "parallelalpha_sum_gte_1": {
      value: account.details.data.ParallelAlpha || 0,
      label: "Parallel Alpha",
    },
  }

  if (verificationType.startsWith("member_of_")) {
    return (
      <Stack direction={isMobile ? "column" : "row"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant='body2'>{account.accountDisplay}</Typography>
        {account.details && account.eligible &&
          <Chip
            variant="outlined"
            size="small"
            label="Part of group"
            color="success"
          />
        }
        {account.details && !account.eligible &&
          <Chip
            variant="outlined"
            size="small"
            label="Not part of group"
            color="secondary"
          />
        }
        {(!account || !account.details) &&
          <Chip
            variant="outlined"
            size="small"
            label="Not part of group"
            color="secondary"
          />
        }
      </Stack>
    )
  } else if (verificationType.startsWith("cb_verified")) {
    return (
      <Stack direction={isMobile ? "column" : "row"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant='body2'>{account.accountDisplay}</Typography>
        {account.details && account.eligible &&
          <Chip
            variant="outlined"
            size="small"
            label="CB Verification Found"
            color="success"
          />
        }
        {account.details && !account.eligible &&
          <Chip
            variant="outlined"
            size="small"
            label="CB Verification Not Found"
            color="secondary"
          />
        }
        {(!account || !account.details) &&
          <Chip
            variant="outlined"
            size="small"
            label="CB Verification Not Found"
            color="secondary"
          />
        }
      </Stack>
    )
  } else {
    return (
      <Stack direction={isMobile ? "column" : "row"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant='body2'>{account.accountDisplay}</Typography>
        {account.details && account.eligible &&
          <Chip
            variant="outlined"
            size="small"
            label={`${prefixToLabel[verificationType].value} ${prefixToLabel[verificationType].label} Found`}
            color="success"
          />
        }
        {account.details && !account.eligible &&
          <Chip
            variant="outlined"
            size="small"
            label={`${prefixToLabel[verificationType].value} ${prefixToLabel[verificationType].label} Found`}
            color="secondary"
          />
        }
        {(!account || !account.details) &&
          <Chip
            variant="outlined"
            size="small"
            label="0 Found"
            color="secondary"
          />
        }
      </Stack>
    )
  }
}

export function renderAccount(account, verificationType, isMobile) {
  const prefixToLabel = {
    "eth_class_of_year": {
      value: account.details.data.class_of_year || "No Data",
      label: "Year of",
    },
  }
  return (
    <Stack direction={isMobile ? "column" : "row"} justifyContent={"space-between"} alignItems={"center"}>
      <Typography variant='body2'>{account.accountDisplay}</Typography>
      {account.details && account.eligible &&
        <Chip
          variant="outlined"
          size="small"
          label={`${prefixToLabel[verificationType].label} ${prefixToLabel[verificationType].value}`}
          color="success"
        />
      }
      {account.details && !account.eligible &&
        <Chip
          variant="outlined"
          size="small"
          label={`${prefixToLabel[verificationType].label} ${prefixToLabel[verificationType].value}`}
          color="secondary"
        />
      }
      {(!account || !account.details) &&
        <Chip
          variant="outlined"
          size="small"
          label=""
          color="secondary"
        />
      }
    </Stack>
  )
}