import './App.css';
import Account from './pages/Account';
import Activities from './pages/Activities';
import About from './pages/About';
import Prove from './pages/Prove';
import Proofs from './pages/Proofs';
import NavBar from './pages/NavBar';
import ProveNavBar from './pages/ProveNavBar';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { UserContext } from './context/UserContext.tsx';
import ThemeProvider from './theme';
import { useState } from "react";
import Feedback from './pages/Feedback';
import SnackbarProvider from './components/snackbar';
import { MotionLazyContainer } from './components/animate';
import Page404 from './pages/Page404';
import Verification from './pages/Verification';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ViewVerifications from './pages/ViewVerifications';
import VerifyProofs from './pages/VerifyProofs';

function App() {
  const [page, setPage] = useState(null);
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);

  let navbar = null
  if (page && ["Prove", "Proofs", "Verification"].includes(page)) {
    navbar = <ProveNavBar />
  } else if (page && !["404"].includes(page)) {
    navbar = <NavBar />
  }

  return (
    <GoogleOAuthProvider clientId="214133190156-t49nq5q4mvhsudrf4o7q7fk971mjfl6j.apps.googleusercontent.com">
      <BrowserRouter>
        <MotionLazyContainer>
          <ThemeProvider>
            <SnackbarProvider>
              <UserContext.Provider value={{ page, setPage, user, setUser, session, setSession }}>
                {navbar}
                <Routes>
                  <Route path='/' element={<Proofs />} />
                  <Route path="/about" element={<About />} />
                  <Route path='/account/wallets' element={<Account subsection="wallets" />} />
                  <Route path='/account/recovery' element={<Account subsection="recovery" />} />
                  <Route path='/activities' element={<Activities />} />
                  <Route path='/prove' element={<Prove />} />
                  <Route path='/proofs' element={<Proofs />} />
                  <Route path='/verification' element={<Verification />} />
                  <Route path='/view' element={<ViewVerifications />} />
                  <Route path='/verify' element={<VerifyProofs />} />
                  <Route path='*' element={<Page404 />} />
                </Routes>
                {page && page !== "Home" && page !== "404" && <Feedback />}
                {page && page !== "Home" && page !== "404" && <div className="footer">Built with ❤️ in California</div>}
              </UserContext.Provider>
            </SnackbarProvider>
          </ThemeProvider >
        </MotionLazyContainer>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );

}

export default App;
