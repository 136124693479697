import { deflate } from 'zlib';
import * as querystring from 'querystring-browser';
import { useEffect, useState, useRef } from 'react'
// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import { Container, Button, Stack, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Iconify from '../components/iconify';

import * as apis from '../utils/apirequests.js'
import { CustomAvatar } from '../components/custom-avatar';
import logoImg from '../images/logo.png'
import robinhoodImg from '../images/robinhood.png'
import twitterImg from '../images/twitter.png'
import redditImg from '../images/reddit.png'


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: 10,
  width: 600,
  height: 343,
  background: 'url("/verification_backdrop.png")',
  backgroundSize: "contain",
  opacity: 0.9,
}));


function Verification() {
  const { user, setPage } = useUserContext()

  useEffect(() => {
    setPage("Verification")
  }, [setPage])

  // State to store data fetched from the backend
  const [data, setData] = useState(Object);
  const [dataBoundValue, setDataBoundValue] = useState(Object)
  const [publicAccount, setPublicAccount] = useState(Object)
  const [expanded, setExpanded] = useState(false)
  const [verificationId, setVerificationId] = useState(null)
  const flowId = useRef(null)

  const translatePublicAccount = (public_account_id: string) => {
    if (public_account_id) {
      const sections = public_account_id.split("@")
      if (sections.length > 1) {
        return {
          platform: sections[0],
          account: sections[1].replace("AT", "@").replace("DOT", "."),
        }
      } else {
        return {
          platform: "",
          account: sections[0].replace("AT", "@").replace("DOT", "."),
        }
      }
    }
  }

  const translateTimestamp = (timestamp: number) => {
    return `${new Date(timestamp).toDateString()} ${new Date(timestamp).toLocaleTimeString()}`
  }

  const translateBounds = (lower_bounds) => {
    const brokerageToStr = {
      "0": "Robinhood"
    }
    const timespanToStr = {
      "0": "Week",
      "1": "Month",
      "2": "Year"
    }
    const profit = (parseInt(lower_bounds[2]) / 100)
    const loss = (parseInt(lower_bounds[3]) / 100)
    let roi = ""
    let direction = "positive"
    if (loss === 0) {
      roi = '+' + profit.toFixed(2)
    } else {
      roi = '' + -loss.toFixed(2)
      direction = "negative"
    }
    return {
      "brokerage": brokerageToStr[lower_bounds[0]],
      "timespan": timespanToStr[lower_bounds[1]],
      "return": roi,
      "direction": direction
    }
  }

  const initializeFlow = async () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const verificationShareableId = queryParameters.get("verificationshareableid")
    setVerificationId(verificationShareableId)
    let verificationResultResponse = null
    try {
      verificationResultResponse = await apis.backendRequest('verify/get_shareable_verification_details', { "verification_shareable_id": verificationShareableId })
    } catch (error) {
      console.log("errors when retrieving verificationResultResponse")
    }
    return verificationResultResponse
  }

  const encodeAttestation = async (obj) => {
    const dataArray = [
      obj.sig.domain.version,
      obj.sig.domain.chainId,
      obj.sig.domain.verifyingContract,
      obj.sig.signature.r,
      obj.sig.signature.s,
      obj.sig.signature.v,
      obj.signer,
      obj.sig.uid,
      obj.sig.message.schema,
      "0",
      obj.sig.message.time,
      obj.sig.message.expirationTime,
      "0",
      obj.sig.message.revocable,
      obj.sig.message.data,
      obj.sig.message.nonce,
      obj.sig.message.version,
    ]

    // Convert the JSON array to a string
    const jsonString = JSON.stringify(dataArray);

    // Compress the JSON string
    deflate(jsonString, (err, buffer) => {
      if (err) {
        console.error('Deflate error:', err);
        return;
      }

      // Encode the compressed data into a Base64 string
      const base64Encoded = buffer.toString('base64');
      window.open(`https://easscan.org/offchain/url/#attestation=${querystring.escape(base64Encoded)}`, "_blank");
    })
  }


  // Use useEffect to call the backend API when the component mounts
  useEffect(() => {
    initializeFlow().then((response) => {
      if (response) {
        setData(response)
        setDataBoundValue(translateBounds(response.lower_bounds))
        setPublicAccount(translatePublicAccount(response.public_account_id))
        flowId.current = response.flow_id
      }
    })
  }, [])

  const expandShowMore = () => {
    setExpanded(!expanded)
  }

  const copyProof = () => {
    navigator.clipboard.writeText(JSON.stringify(data.source_proof, null, 2))
  }

  const copyAttestation = () => {
    navigator.clipboard.writeText(data.source_private_vc)
  }

  const handleShareToTwitter = (values) => {
    const shareableUri = `https://prove.jomo.id/verification?verificationshareableid=${verificationId}`
    const shareableText = `I ${(values.direction === "positive" && "gained") || (values.direction === "negative" && "lost")}${values.return}%25 on ${values.brokerage} over the past ${values.timespan}. Check out the proof! ${shareableUri}`
    window.open(`https://twitter.com/intent/tweet?text=${shareableText}`, "_blank");
  }

  const handleShareToReddit = (values) => {
    const shareableUri = encodeURIComponent(`https://prove.jomo.id/verification?verificationshareableid=${verificationId}`)
    const shareableText = `I ${(values.direction === "positive" && "gained") || (values.direction === "negative" && "lost")} ${values.return}%25 on ${values.brokerage} over the past ${values.timespan}. Check out the proof! ${shareableUri}`
    window.open(`https://reddit.com/submit?url=${shareableText}`, "_blank");
  }

  const handleGetYourOwn = () => {
    if (flowId.current) {
      window.location.href = "/prove?flowid=" + flowId.current
    }
  }

  return (
    <Container>
      <Stack gap={2} sx={{ m: "40px" }} alignItems={"center"} display={"flex"} flexDirection={"column"}>
        <Card variant="elevation">
          <Item>
            <Stack width={1} height={1} sx={{ backgroundColor: "rgb(255 255 255 / 0.15)" }} gap={2.5} justifyContent={"space-between"}>

              <Stack ml={3} mt={4} gap={1}>
                <Stack direction="row" justifyContent={'left'} alignItems={'center'} gap={1}>
                  {publicAccount.platform === "twitter" &&
                    <CustomAvatar
                      sx={{ width: 28, height: 28 }}
                      alt="Twitter"
                      src={twitterImg}
                    />
                  }
                  {publicAccount.platform === "reddit" &&
                    <CustomAvatar
                      sx={{ width: 28, height: 28 }}
                      alt="Reddit"
                      src={redditImg}
                    />
                  }
                  <Typography variant="subtitle1">
                    {publicAccount.account}
                  </Typography>
                  <Typography variant="body1">
                    had
                  </Typography>
                </Stack>
                <Typography variant="h1" color={dataBoundValue.direction === "negative" ? "text.stockdown" : "text.stockup"}>
                  {dataBoundValue.return}%
                </Typography>
                <Stack direction="row" justifyContent={'left'} alignItems={'center'} gap={1}>
                  <Typography variant="body1">
                    on
                  </Typography>
                  <Typography variant="h5">
                    {dataBoundValue.brokerage}
                  </Typography>
                  {dataBoundValue.brokerage === "Robinhood" &&
                    <CustomAvatar
                      sx={{ width: 24, height: 24 }}
                      alt="Robinhood"
                      src={robinhoodImg}
                    />
                  }
                </Stack>

                <Stack direction="row" justifyContent={'left'} alignItems={'center'} gap={1}>
                  <Typography variant="body1">
                    over the past
                  </Typography>
                  <Typography variant="h5">
                    {dataBoundValue.timespan}
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction={"row"} justifyContent={"space-between"} px={3} mb={0.5} sx={{ backgroundColor: "rgb(255 255 255 / 0.4)" }} width={1}>
                <Stack direction="row" justifyContent={'center'} alignItems={'center'}>
                  <Typography variant="caption">
                    {translateTimestamp(data.timestamp)}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent={'center'} alignItems={'center'}>
                  <Typography variant="caption">
                    Attested by
                  </Typography>
                  <img src={logoImg} alt={'logo'} height="20px" />
                </Stack>
              </Stack>
            </Stack>
          </Item>
        </Card>


        <Stack>
          <Stack direction="row" justifyContent="center" alignItems='center'>
            {publicAccount.platform === "twitter" &&
              (
                (user.twitter_username && user.twitter_username.toLowerCase() === publicAccount.account.toLowerCase() &&
                  <Button variant={"contained"} onClick={() => handleShareToTwitter(dataBoundValue)}>
                    Share on Twitter<script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                  </Button>
                ) ||
                (
                  <Button variant={"contained"} onClick={handleGetYourOwn}>
                    Get your own attestation
                  </Button>
                )
              )
            }
            {publicAccount.platform === "reddit" &&
              (
                (user.reddit_username && user.reddit_username.toLowerCase() === publicAccount.account.toLowerCase() &&
                  <Button variant={"contained"} onClick={() => handleShareToReddit(dataBoundValue)}>
                    Share on Reddit
                  </Button>
                ) ||
                (
                  <Button variant={"contained"} onClick={handleGetYourOwn}>
                    Get your own attestation
                  </Button>
                )
              )
            }
          </Stack>
          <Typography onClick={expandShowMore} color="secondary" textAlign={"center"} sx={{ mt: "12px", borderBottom: "1px dashed", cursor: "pointer" }}>
            {!expanded && "Show attestation details"}
            {expanded && "Hide details"}
          </Typography>
        </Stack>

        <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ maxWidth: "800px", minWidth: "600px" }}>
          <Stack gap={1}>
            <Stack >
              <Typography variant="h6">
                Attester:
              </Typography>
              <Typography variant="body1" ml={1}>
                {data.attester}
              </Typography>
            </Stack>
            <Stack >
              <Typography variant="h6">
                Signature:
              </Typography>
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={2}
                maxRows={5}
                variant="outlined"
                value={data.signature}
                contentEditable={false}
                sx={{ ml: '8px' }}
              />
            </Stack>

            <Stack>
              <Stack direction="row" alignItems={'center'} gap={1}>
                <Typography variant="h6">
                  Attestation:
                </Typography>
                <Tooltip title="The verifiable credential of your private data." placement="right">
                  <Iconify height={20} width={20} icon="gg:info" sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={2}
                maxRows={10}
                variant="outlined"
                value={data.source_private_vc}
                contentEditable={false}
                onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
                sx={{ ml: '8px' }}
              />
              <Button sx={{ m: '8px' }} variant="outlined" size="small" onClick={copyAttestation}>Copy</Button>
              <Button sx={{ m: '8px' }} variant="outlined" size="small" onClick={() => { encodeAttestation(JSON.parse(data.source_private_vc)) }}>View on EAS</Button>
            </Stack>

            <Stack>
              <Stack direction="row" alignItems={'center'} gap={1}>
                <Typography variant="h6">
                  Proof:
                </Typography>
                <Tooltip title="Evidence of your selectively disclosed information" placement="right">
                  <Iconify height={20} width={20} icon="gg:info" sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={2}
                maxRows={10}
                variant="outlined"
                value={JSON.stringify(data.source_proof, null, 2)}
                contentEditable={false}
                onChange={(event) => { event.target.scrollTo(0, event.target.scrollHeight) }}
                sx={{ ml: '8px' }}
              />
              <Button sx={{ m: '8px' }} variant="outlined" size="small" onClick={copyProof}>Copy</Button>
            </Stack>
          </Stack>
        </Collapse>
      </Stack>
    </Container >
  );
}

export default Verification;
