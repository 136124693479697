
function fillCondition(condition, curPos, limits) {
  if (condition.method === "EQ" || condition.method === "GE") {
    limits.lowerFilter[curPos.count] = 1
    limits.lowerBounds[curPos.count] = condition.value
  }
  if (condition.method === "EQ" || condition.method === "LE") {
    limits.upperFilter[curPos.count] = 1
    limits.upperBounds[curPos.count] = condition.value
  }
}

function parseSchemaForLimits(schema, criteria, curPos, limits) {
  for (const fieldId in schema) {
    const field = schema[fieldId]
    if (field.fieldName in criteria) {
      const fieldCriteria = criteria[field.fieldName]
      if (field.type !== "list") {
        const fieldConditions = fieldCriteria.conditions
        for (const conditionId in fieldConditions) {
          const fieldCondition = fieldConditions[conditionId]
          fillCondition(fieldCondition, curPos, limits)
        }
      } else {
        const lenConditions = fieldCriteria.lenConditions
        for (const conditionId in lenConditions) {
          const lenCondition = lenConditions[conditionId]
          fillCondition(lenCondition, curPos, limits)
        }
        curPos.count++

        const itemConditions = fieldCriteria.itemConditions
        for (var i = 0; i < field.listLength; i++) {
          const itemSchema = field.listFields
          if (i.toString() in itemConditions) {
            const itemCriteria = itemConditions[i.toString()].criteria
            parseSchemaForLimits(itemSchema, itemCriteria, curPos, limits)
          } else {
            parseSchemaForLimits(itemSchema, {}, curPos, limits)
          }
        }
      }
    }
    curPos.count++
  }
}

export function getLimitsFromCriteria(schema, criteria, numElements) {
  const limits = {
    lowerFilter: Array(numElements).fill(0),
    lowerBounds: Array(numElements).fill(0),
    upperFilter: Array(numElements).fill(0),
    upperBounds: Array(numElements).fill(0),
  }

  var curPos = { count: 0 }
  parseSchemaForLimits(schema, criteria, curPos, limits)

  return [limits.lowerFilter, limits.lowerBounds, limits.upperFilter, limits.upperBounds]
}

export function getLimitsFromProveKeys(schema, keys, numElements, elements) {
  // TODO: parse more complex schema structure
  const limits = {
    lowerFilter: Array(numElements).fill(0),
    lowerBounds: Array(numElements).fill(0),
    upperFilter: Array(numElements).fill(0),
    upperBounds: Array(numElements).fill(0),
  }
  console.log(keys)

  var curPos = 0
  for (const fieldId in schema) {
    const field = schema[fieldId]
    if (keys.includes(field.fieldName)) {
      limits.lowerFilter[curPos] = 1
      limits.lowerBounds[curPos] = elements[curPos]
      limits.upperFilter[curPos] = 1
      limits.upperBounds[curPos] = elements[curPos]
    }
    curPos++
  }

  return [limits.lowerFilter, limits.lowerBounds, limits.upperFilter, limits.upperBounds]
}