import * as apis from '../apirequests.js'

const DATA_URL = {
  "mainnet": "https://eth.llamarpc.com",
  "polygon": "https://polygon-bor.publicnode.com",
  "optimism": "https://optimism-mainnet.public.blastapi.io",
  "arbitrum": "https://rpc.ankr.com/arbitrum",
};

export async function getEthBalance(walletAddress, network) {
  const url = DATA_URL[network]
  const responseJson = await apis.postUrl(url, {
    jsonrpc: "2.0",
    method: "eth_getBalance",
    params: [walletAddress, "latest"],
    id: 1,
  })

  if (responseJson) return parseInt(responseJson.result, 16) / 10 ** 18
  return null
}

export async function getTxCount(walletAddress, network) {
  let url = DATA_URL[network];
  const responseJson = await apis.postUrl(url, {
    jsonrpc: "2.0",
    method: "eth_getTransactionCount",
    params: [walletAddress, "latest"],
    id: 1,
  })

  if (responseJson) return parseInt(responseJson.result, 16)
  return null
}

export async function getTxs(walletAddress, network) {
  // Note there needs to be a colon at the end
  let headers = new Headers();
  headers.set('Authorization', 'Basic ' + new Buffer(process.env.REACT_APP_COVALENT_API_KEY).toString('base64'));
  let page = 0
  const transactionEndpoint = `https://api.covalenthq.com/v1/${network}/address/${walletAddress}/transactions_v3/page/${page}/`

  let response = await fetch(transactionEndpoint, { method: 'GET', headers: headers })
    .then((resp) => resp.json())
  let result = response.data.items

  while (response.data.links.next) {
    response = await fetch(response.data.links.next, { method: 'GET', headers: headers })
      .then((resp) => resp.json())
    result = [...result, ...response.data.items]
  }

  return result
}

export async function getBalances(walletAddress, network) {
  // Note there needs to be a colon at the end
  let headers = new Headers();
  headers.set('Authorization', 'Basic ' + new Buffer(process.env.REACT_APP_COVALENT_API_KEY).toString('base64'));

  const response = await fetch(`https://api.covalenthq.com/v1/${network}/address/${walletAddress}/balances_v2/`, { method: 'GET', headers: headers })
    .then((resp) => resp.json())

  return response.data.items
}

function getDayStr(date) {
  return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;
}

function getWeekStr(date) {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  return `${d.getUTCFullYear()}-${weekNo}`;
}

function getMonthStr(date) {
  return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)}`;
}

export function understandTransaction(tx, address) {
  const result = {}
  if (tx['from_address'].toLowerCase() !== address.toLowerCase()) {
    return null
  }
  result["fees_paid"] = parseFloat((parseInt(tx["fees_paid"], 10) / 10 ** 18).toFixed(4))

  const timestamp = Date.parse(tx['block_signed_at'])
  result["block_signed_at"] = timestamp

  const date = new Date(timestamp);
  result["day"] = getDayStr(date);
  result["week"] = getWeekStr(date);
  result["month"] = getMonthStr(date);
  return result
}