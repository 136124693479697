// @ts-ignore
import { useUserContext } from '../../context/UserContext.tsx';
import { useState, useEffect, useRef, useCallback } from 'react'
import { Container, Card, Box, Stack, Typography, CircularProgress, IconButton, Tooltip, Button, Popover, Table, TableRow, TableBody, TableCell, TableContainer } from '@mui/material';
import SvgColor from '../../components/svg-color';
import {
    GridColDef,
    GridValueGetterParams,
    GridRenderCellParams,
    GridValueSetterParams,
    GridRowModel,
    useGridApiRef,
} from '@mui/x-data-grid';
import CustomDataGrid from '../../components/datagrid/CustomDataGrid';
import TableHeadCustom from '../../components/table/TableHeadCustom';
import NewWallet from '../NewWallet'
import ZkSyncAggregated from './ZkSyncAggregated'
import * as ethereum from '../../utils/data/ethereum.js'
import * as zksyncera from '../../utils/data/zksyncera.js'
import * as activityutils from '../../utils/activityutils.js'
import * as apis from '../../utils/apirequests.js'
import ethImg from '../../images/ethereum.png';
import Iconify from '../../components/iconify/Iconify';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import { useSnackbar } from '../../components/snackbar';
import * as common from './common'

const css = `
`

export default function ZkSyncActivities({ updateUser }) {
    const { user } = useUserContext()
    const [zkSyncData, setZkSyncData] = useState({})

    const firstMount = useRef(true)
    const initLoaded = useRef(false)
    const addressPulling = useRef({})
    const [addressPullingState, setAddressPulling] = useState({})

    const { copy } = useCopyToClipboard();
    const { enqueueSnackbar } = useSnackbar();
    const apiRef = useGridApiRef();

    const [hover, setHover] = useState<HTMLElement | null>(null);
    const [hoverWallet, setHoverWallet] = useState(null);

    const handleHoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: String) => {
      setHover(event.currentTarget);
      setHoverWallet(id);
    };
    const handleHoverClose = () => {
      setHover(null);
      setHoverWallet(null)
    };


    const pullZkSyncDataForWallet = async function (wallet) {
        if (addressPulling.current[wallet]) {
            return
        }
        addressPulling.current[wallet] = true
        setAddressPulling(existingValues => ({
            ...addressPulling.current,
        }))

        const ethETHBalance = ethereum.getEthBalance(wallet, "mainnet")
        const ethTxCount = ethereum.getTxCount(wallet, "mainnet")
        const zkSyncEraAccount = zksyncera.getAccountData(wallet)
        const zkSyncEraTx = zksyncera.getTxs(wallet)
        const zkSyncBridgePercentiles = await apis.backendRequest('erabridge/get_percentiles', {})
        const zkSyncDaysPercentiles = await apis.backendRequest('zksync/get_active_percentiles', { "period": "days" })
        const zkSyncWeeksPercentiles = await apis.backendRequest('zksync/get_active_percentiles', { "period": "weeks" })
        const zkSyncMonthsPercentiles = await apis.backendRequest('zksync/get_active_percentiles', { "period": "months" })

        const data = {}
        ethETHBalance.then((result) => { data["ethETHBalance"] = result })
        ethTxCount.then((result) => { data["ethTxCount"] = result })
        zkSyncEraAccount.then((result) => {
            if (!result) return
            data["zkSyncEraTotalBalance"] = 0
            for (const tokenSymbol in result.tokenBalances) {
                const token = result.tokenBalances[tokenSymbol]
                data["zkSyncEra" + tokenSymbol + "Balance"] = token["balance"]
                data["zkSyncEraTotalBalance"] += parseFloat(token["usdPrice"]) * token["balance"]
            }
            data["zkSyncEraTxCount"] = result.txCount
        })

        zkSyncEraTx.then((resultList) => {
            var lastTxTime = 0
            var contractAddresses = {}
            var days = {}
            var weeks = {}
            var months = {}
            var l1ToL2Count = 0
            var l1ToL2Value = 0.0
            var l2ToL1Count = 0
            var l2ToL1Value = 0.0
            var gasFee = 0.0

            for (const txId in resultList) {
                const tx = resultList[txId]
                const txData = zksyncera.understandTransaction(tx, wallet)
                if (!txData) continue

                // Last Tx
                if (txData["receivedAt"] > lastTxTime) {
                    lastTxTime = txData["receivedAt"]
                }

                // Unique Contracts
                if (!contractAddresses[txData["contractAddress"]]) {
                    contractAddresses[txData["contractAddress"]] = 0
                }
                contractAddresses[txData["contractAddress"]] = contractAddresses[txData["contractAddress"]] + 1

                // Unique Days
                if (!days[txData["day"]]) {
                    days[txData["day"]] = 0
                }
                days[txData["day"]] = days[txData["day"]] + 1

                // Unique Weeks
                if (!weeks[txData["week"]]) {
                    weeks[txData["week"]] = 0
                }
                weeks[txData["week"]] = weeks[txData["week"]] + 1

                // Unique Months
                if (!months[txData["month"]]) {
                    months[txData["month"]] = 0
                }
                months[txData["month"]] = months[txData["month"]] + 1

                // Total Fee
                gasFee += txData["fee"]

                // Bridge
                if (txData["isL1ToL2"]) {
                    l1ToL2Count++
                    l1ToL2Value += txData["l1ToL2Value"]
                }
                if (txData["isL2ToL1"]) {
                    l2ToL1Count++
                    l2ToL1Value += txData["l2ToL1Value"]
                }
            }
            data["zkSyncEraLastTx"] = lastTxTime === 0 ? null : lastTxTime
            data["zkSyncEraUniqueContractCount"] = Object.keys(contractAddresses).length
            data["zkSyncEraStarmakerCount"] = contractAddresses["0x1bdb8250eaf3c596441e6c3417c9d5195d6c85b9"]
            data["zkSyncEraSpacifiCount"] = contractAddresses["0xbe7d1fd1f6748bbdefc4fbacafbb11c6fc506d1d"]
            data["zkSyncEraDaysCount"] = Object.keys(days).length
            data["zkSyncEraDaysPercentile"] = common.getPercentile(Object.keys(days).length, zkSyncDaysPercentiles)
            data["zkSyncEraWeeksCount"] = Object.keys(weeks).length
            data["zkSyncEraWeeksPercentile"] = common.getPercentile(Object.keys(weeks).length, zkSyncWeeksPercentiles)
            data["zkSyncEraMonthsCount"] = Object.keys(months).length
            data["zkSyncEraMonthsPercentile"] = common.getPercentile(Object.keys(months).length, zkSyncMonthsPercentiles)
            data["zkSyncEraGasFee"] = gasFee
            data["zkSyncEraL1ToL2Count"] = l1ToL2Count
            data["zkSyncEraL1ToL2Value"] = l1ToL2Value
            data["zkSyncEraL1ToL2Percentile"] = common.getPercentile(l1ToL2Value, zkSyncBridgePercentiles)
            data["zkSyncEraL2ToL1Count"] = l2ToL1Count
            data["zkSyncEraL2ToL1Value"] = l2ToL1Value
        })
        await Promise.all([ethETHBalance, ethTxCount, zkSyncEraAccount, zkSyncEraTx])

        data["updatedAt"] = Date.now()

        addressPulling.current[wallet] = false
        setAddressPulling(existingValues => ({
            ...addressPulling.current,
        }))

        return data
    }

    const updateZkSyncDataForWallet = async function (wallet) {
        const data = await pullZkSyncDataForWallet(wallet)
        const newZkSyncData = zkSyncData
        newZkSyncData[wallet] = data
        setZkSyncData(() => (newZkSyncData))
        localStorage.setItem("zkSyncData", JSON.stringify(newZkSyncData))
    }

    const getZkSyncRows = function (data) {
        const rows = []
        for (const wallet in data) {
            rows.push({
                id: wallet,
                nickname: common.getAddressNickname(wallet, user) || "",
                ethETHBalance: data[wallet]["ethETHBalance"] || 0.0,
                ethTxCount: data[wallet]["ethTxCount"] || 0,
                zkSyncEraETHBalance: data[wallet]["zkSyncEraETHBalance"] || 0.0,
                zkSyncEraUSDCBalance: data[wallet]["zkSyncEraUSDCBalance"] || 0.0,
                zkSyncEraUSDTBalance: data[wallet]["zkSyncEraUSDTBalance"] || 0.0,
                zkSyncEraTotalBalance: data[wallet]["zkSyncEraTotalBalance"] || 0.0,
                zkSyncEraTxCount: data[wallet]["zkSyncEraTxCount"] || 0,
                zkSyncEraStarmakerCount: data[wallet]["zkSyncEraStarmakerCount"] || 0,
                zkSyncEraSpacifiCount: data[wallet]["zkSyncEraSpacifiCount"] || 0,
                zkSyncEraLastTx: data[wallet]["zkSyncEraLastTx"],
                zkSyncEraUniqueContractCount: data[wallet]["zkSyncEraUniqueContractCount"] || 0,
                zkSyncEraDaysCount: data[wallet]["zkSyncEraDaysCount"] || 0,
                zkSyncEraDaysPercentile: data[wallet]["zkSyncEraDaysPercentile"] || 0,
                zkSyncEraWeeksCount: data[wallet]["zkSyncEraWeeksCount"] || 0,
                zkSyncEraWeeksPercentile: data[wallet]["zkSyncEraWeeksPercentile"] || 0,
                zkSyncEraMonthsCount: data[wallet]["zkSyncEraMonthsCount"] || 0,
                zkSyncEraMonthsPercentile: data[wallet]["zkSyncEraMonthsPercentile"] || 0,
                zkSyncEraGasFee: data[wallet]["zkSyncEraGasFee"] || 0.0,
                zkSyncEraL1ToL2Count: data[wallet]["zkSyncEraL1ToL2Count"] || 0,
                zkSyncEraL1ToL2Value: data[wallet]["zkSyncEraL1ToL2Value"] || 0.0,
                zkSyncEraL1ToL2Percentile: data[wallet]["zkSyncEraL1ToL2Percentile"] || 0,
                zkSyncEraL2ToL1Count: data[wallet]["zkSyncEraL2ToL1Count"] || 0,
                zkSyncEraL2ToL1Value: data[wallet]["zkSyncEraL2ToL1Value"] || 0.0,
                updatedAt: data[wallet]["updatedAt"],
            })
        }
        return rows
    }

    const getZkSyncColumns = function () {
        const columns: GridColDef[] = [
            {
                field: 'id',
            },
            {
                field: 'updatedAt',
                renderHeader: () => common.RenderHeader('Last Updated'),
                headerClassName: 'column-header',
                editable: false,
                sortable: true,
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                renderCell: (params) => (
                    <Stack spacing={0.5} direction="row" justifyContent="left" alignItems="center" sx={{ height: 1, width: 1 }}>
                        {!addressPullingState[params.row.id] &&
                            <IconButton color="primary" onClick={() => updateZkSyncDataForWallet(params.row.id)}>
                                <Iconify icon="mdi:refresh" color="primary" width={20} />
                            </IconButton>
                        }
                        {addressPullingState[params.row.id] &&
                            <IconButton color="primary" disabled>
                                <CircularProgress color="primary" size={20} />
                            </IconButton>
                        }
                        <Typography variant="body1">{common.formatTime(params.value)}</Typography>
                    </Stack>
                ),
            },
            {
                field: 'nickname',
                renderHeader: () => common.RenderHeader('Address'),
                headerClassName: 'column-header',
                minWidth: 200,
                flex: 1,
                editable: true,
                headerAlign: 'center',
                renderCell: (params: GridRenderCellParams) => {
                    const address = params.row.id
                    const nickname = common.getAddressNickname(address, user)

                    return (
                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 1, height: 1, width: 1, }}>
                            <Typography variant="body1">
                                {nickname}
                                {!nickname && common.getTruncatedAddress(address)}
                            </Typography>
                            <Stack spacing={0} direction="row" justifyContent="center" alignItems="center">
                                <Tooltip title={`Edit nickname`}>
                                    <IconButton onClick={() => { apiRef.current.startCellEditMode({ id: address, field: 'nickname' }) }} sx={{ margin: 0, padding: "3px" }}>
                                        <Iconify icon="mdi:edit-outline" width={18} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={`${address} (Click to Copy!)`}>
                                    <IconButton onClick={() => {
                                        copy(address).then((success) => { if (success) enqueueSnackbar("Copied!", { variant: "info" }) })
                                    }} sx={{ margin: 0, padding: "3px" }}>
                                        <Iconify icon="eva:copy-fill" width={18} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    )
                },
                valueSetter: (params: GridValueSetterParams) => {
                    const nickname = params.value
                    return { ...params.row, nickname };
                },
            },
            {
                field: 'ethETHBalance',
                renderHeader: () => common.RenderHeader('L1 ETH', "ETH balance on Ethereum"),
                headerClassName: 'column-header',
                type: 'number',
                minWidth: 75,
                flex: 1,
                editable: false,
                align: 'left',
                headerAlign: 'left',
                renderCell: (params) => common.RenderBalance(params.value),
            },
            {
                field: 'zkSyncEraETHBalance',
                renderHeader: () => common.RenderHeader('ETH', "ETH balance on zkSync"),
                headerClassName: 'column-header',
                type: 'number',
                minWidth: 65,
                flex: 1,
                editable: false,
                align: 'left',
                headerAlign: 'left',
                renderCell: (params) => common.RenderBalance(params.value),
            },
            {
                field: 'zkSyncEraUSDCBalance',
                renderHeader: () => common.RenderHeader('USDC', "USDC balance on zkSync"),
                headerClassName: 'column-header',
                type: 'number',
                minWidth: 70,
                flex: 1,
                editable: false,
                align: 'left',
                headerAlign: 'left',
                renderCell: (params) => common.RenderBalance(params.value),
            },
            {
                field: 'zkSyncEraTxCount',
                renderHeader: () => common.RenderHeader('# Tx', "Number of transactions on zkSync"),
                headerClassName: 'column-header',
                type: 'number',
                minWidth: 80,
                flex: 1,
                editable: false,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => common.RenderPercentile(params.row.zkSyncEraTxCount.toString(), 0),
            },
            {
                field: 'zkSyncEraUniqueContractCount',
                renderHeader: () => common.RenderHeader('# Contracts', "Number of unique contracts interacted with on zkSync"),
                headerClassName: 'column-header',
                type: 'number',
                minWidth: 120,
                flex: 1,
                editable: false,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params: GridRenderCellParams) => {
                    var count = params.row.zkSyncEraUniqueContractCount.toString()
                    function createData(name: string, interactions: string) {
                      return { name, interactions };
                    }
                    const TABLE_DATA = [
                      createData('Starmaker', params.row.zkSyncEraStarmakerCount.toString()),
                      createData('SpaciFi', params.row.zkSyncEraSpacifiCount.toString()),
                    ];

                    const TABLE_HEAD = [
                      { id: 'PROTOCOLS	', label: 'PROTOCOLS' },
                      { id: 'INTERACTIONS', label: 'INTERACTIONS', align: 'right' },
                    ];
                    return (
                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 2, height: 2, width: 2, }}>
                          <Typography
                            aria-owns={hover ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseLeave={handleHoverClose}
                            onMouseEnter={(event) => handleHoverOpen(event, params.row.id)}
                          >
                            {count}
                          </Typography>
                          <Popover
                            id="mouse-over-popover"
                            open={Boolean(hoverWallet===params.row.id)}
                            anchorEl={hover}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={handleHoverClose}
                            disableRestoreFocus
                            sx={{
                              pointerEvents: 'none',
                            }}
                          >
                            <Box sx={{ p: 2, maxWidth: 800 }}>
                              <Typography variant="subtitle1" gutterBottom>
                                <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
                                  {/* <Scrollbar> */}
                                    <Table sx={{ minWidth: 200 }}>
                                      <TableHeadCustom headLabel={TABLE_HEAD} />

                                      <TableBody>
                                        {TABLE_DATA.map((row) => (
                                          <TableRow key={row.name}>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell align="right">{row.interactions}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  {/* </Scrollbar> */}
                                </TableContainer>
                              </Typography>
                            </Box>
                          </Popover>
                        </Stack>
                    )
                },
            },
            {
                field: 'zkSyncEraLastTx',
                renderHeader: () => common.RenderHeader('Last Tx', "Time of last transaction on zkSync"),
                headerClassName: 'column-header',
                editable: false,
                sortable: true,
                minWidth: 120,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                renderCell: (params) => (
                    <Typography variant="body1">{common.formatTime(params.value)}</Typography>
                ),
            },
            {
                field: 'zkSyncEraL1ToL2',
                renderHeader: () => common.RenderHeader('L1 -> L2', "Number of bridging transactions and total bridged value from Ethereum to zkSync using the official bridge"),
                headerClassName: 'column-header',
                minWidth: 120,
                flex: 1,
                editable: false,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => (
                    common.RenderPercentile(
                        `${params.row.zkSyncEraL1ToL2Count}${params.row.zkSyncEraL1ToL2Value
                            ? " (" + params.row.zkSyncEraL1ToL2Value.toFixed(3) + " ETH)"
                            : ""}`,
                        params.row.zkSyncEraL1ToL2Percentile,
                    )
                ),
            },
            {
                field: 'zkSyncEraL2ToL1',
                renderHeader: () => common.RenderHeader('L2 -> L1', "Number of bridging transactions and total bridged value from zkSync to Ethereum using the official bridge"),
                headerClassName: 'column-header',
                minWidth: 120,
                flex: 1,
                editable: false,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => (
                    <Typography variant="body1">
                        {params.row.zkSyncEraL2ToL1Count || 0}
                        {params.row.zkSyncEraL2ToL1Value ? ` (${params.row.zkSyncEraL2ToL1Value.toFixed(3)} ETH)` : ""}
                    </Typography>
                ),
            },
            {
                field: 'zkSyncEraDaysCount',
                renderHeader: () => common.RenderHeader('Days', "Number of days with active transactions on zkSync"),
                headerClassName: 'column-header',
                type: 'number',
                minWidth: 120,
                flex: 1,
                editable: false,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => common.RenderPercentile2(params.row.zkSyncEraDaysCount.toString(), params.row.zkSyncEraDaysPercentile),
            },
            {
                field: 'zkSyncEraWeeksCount',
                renderHeader: () => common.RenderHeader('Weeks', "Number of weeks with active transactions on zkSync"),
                headerClassName: 'column-header',
                type: 'number',
                minWidth: 120,
                flex: 1,
                editable: false,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => common.RenderPercentile2(params.row.zkSyncEraWeeksCount.toString(), params.row.zkSyncEraWeeksPercentile),
            },
            {
                field: 'zkSyncEraMonthsCount',
                renderHeader: () => common.RenderHeader('Months', "Number of months with active transactions on zkSync"),
                headerClassName: 'column-header',
                type: 'number',
                minWidth: 120,
                flex: 1,
                editable: false,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => common.RenderPercentile2(params.row.zkSyncEraMonthsCount.toString(), params.row.zkSyncEraMonthsPercentile),
            },
            {
                field: 'zkSyncEraGasFee',
                renderHeader: () => common.RenderHeader('Gas', "Total gas fee spent on transactions on zkSync"),
                headerClassName: 'column-header',
                width: 100,
                flex: 0,
                editable: false,
                align: 'right',
                headerAlign: 'center',
                valueGetter: (params: GridValueGetterParams) => {
                    if (params.row.zkSyncEraGasFee && params.row.zkSyncEraGasFee > 0.000) {
                        return params.row.zkSyncEraGasFee.toFixed(3)
                    }
                    return 0
                },
                renderCell: (params) => (
                    <Stack spacing={0.5} direction="row" alignItems="center" sx={{ px: 1, height: 1 }}>
                        <Typography variant="body1">{params.value}</Typography>
                        <SvgColor src={ethImg} sx={{ px: 1, width: 1 }} />
                    </Stack>
                ),
            },
        ];
        return columns
    }

    const getZkSyncColumnGrouping = function () {
        return [
            {
                groupId: 'Assets',
                align: 'center',
                headerClassName: 'column-header-group',
                renderHeaderGroup: () => common.RenderHeaderGroup('Assets'),
                headerAlign: 'center',
                children: [{ field: 'ethETHBalance' }, { field: 'zkSyncEraETHBalance' }, { field: 'zkSyncEraUSDCBalance' }],
            },
            {
                groupId: 'Transactions',
                align: 'center',
                headerClassName: 'column-header-group',
                renderHeaderGroup: () => common.RenderHeaderGroup('Transactions'),
                headerAlign: 'center',
                children: [{ field: 'zkSyncEraTxCount' }, { field: 'zkSyncEraLastTx' }, { field: 'zkSyncEraUniqueContractCount' }],
            },
            {
                groupId: 'Bridges',
                align: 'center',
                headerClassName: 'column-header-group',
                renderHeaderGroup: () => common.RenderHeaderGroup('Bridges'),
                headerAlign: 'center',
                children: [{ field: 'zkSyncEraL1ToL2' }, { field: 'zkSyncEraL2ToL1' }],
            },
            {
                groupId: 'Active',
                align: 'center',
                headerClassName: 'column-header-group',
                renderHeaderGroup: () => common.RenderHeaderGroup('Active'),
                headerAlign: 'center',
                children: [{ field: 'zkSyncEraDaysCount' }, { field: 'zkSyncEraWeeksCount' }, { field: 'zkSyncEraMonthsCount' }],
            },
            {
                groupId: 'Fees',
                align: 'center',
                headerClassName: 'column-header-group',
                renderHeaderGroup: () => common.RenderHeaderGroup('Fees'),
                headerAlign: 'center',
                children: [{ field: 'zkSyncEraGasFee' }],
            },
        ]
    }

    const updateZkSyncDataForNewWallets = async function (oldData, forceRefresh = false) {
        if (!user || !initLoaded.current) {
            return
        }
        const [newZkSyncData, newWallets, removeWallets] = activityutils.updateDataAndNewWallets(oldData, "evm", user)

        //@ts-ignore
        if (newWallets.length === 0) {
            if (removeWallets.length > 0) {
                setZkSyncData(() => (newZkSyncData))
                localStorage.setItem("zkSyncData", JSON.stringify(newZkSyncData))
            }
            return
        }

        const pulls = []
        const newData = newZkSyncData
        if (forceRefresh) {
            for (const wallet in newZkSyncData) {
                const walletData = pullZkSyncDataForWallet(wallet)
                walletData.then((data) => { newData[wallet] = data })
                pulls.push(walletData)
            }
        } else {
            for (const walletId in newWallets) {
                const walletData = pullZkSyncDataForWallet(newWallets[walletId])
                walletData.then((data) => { newData[newWallets[walletId]] = data })
                pulls.push(walletData)
            }
        }
        await Promise.all(pulls)
        setZkSyncData(() => (newData))
        localStorage.setItem("zkSyncData", JSON.stringify(newData))
    }

    const initialLoadZkSyncData = async function () {
        const storedZkSyncData = JSON.parse(localStorage.getItem("zkSyncData")) || {}
        setZkSyncData(() => (storedZkSyncData))
        const pulls = []
        for (const wallet in storedZkSyncData) {
            if (!storedZkSyncData[wallet] || !storedZkSyncData[wallet]["updatedAt"]) {
                const walletData = pullZkSyncDataForWallet(wallet)
                walletData.then((data) => {
                    storedZkSyncData[wallet] = data
                })
                pulls.push(walletData)
            }
        }
        await Promise.all(pulls)
        setZkSyncData(() => (storedZkSyncData))
        localStorage.setItem("zkSyncData", JSON.stringify(storedZkSyncData))
        initLoaded.current = true
        return storedZkSyncData
    }

    // Whenever local user state finds an update on the user, refresh wallet list
    useEffect(() => {
        if (user && (user.wallets.length || user.pendingWallets.length || user.watchingWallets.length) && initLoaded.current) {
            updateZkSyncDataForNewWallets(zkSyncData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Whenever page loads, load locally stored data
    useEffect(() => {
        if (firstMount.current) {
            firstMount.current = false
            initialLoadZkSyncData().then((initialData) => updateZkSyncDataForNewWallets(initialData))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <style>
                {css}
            </style>
            <Container maxWidth={false}>
                <ZkSyncAggregated zkSyncData={zkSyncData} />
                <Stack gap={2} sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    marginBottom: "24px",
                    marginTop: "24px",
                    width: 1,
                }}>
                    <Button
                        variant='soft'
                        startIcon={<Iconify icon="mdi:refresh" />}
                        onClick={() => { updateZkSyncDataForNewWallets(true) }}
                    >
                        Refresh All
                    </Button>
                    <NewWallet chainId="evm" sx={{}} />
                </Stack>
                <Card>
                    <Box>
                        <CustomDataGrid
                            data={getZkSyncRows(zkSyncData)}
                            columns={getZkSyncColumns()}
                            columnGrouping={getZkSyncColumnGrouping()}
                            processRowUpdate={
                                useCallback(
                                    async (newRow: GridRowModel) => {
                                        const address = newRow.id
                                        const value = newRow.nickname
                                        common.setAddressNickname(address, value, user, updateUser)
                                        return newRow
                                    },
                                    // eslint-disable-next-line react-hooks/exhaustive-deps
                                    [user],
                                )
                            }
                            onProcessRowUpdateError={useCallback((error: Error) => {
                                console.log(error)
                            }, [])}
                            apiRef={apiRef}
                        />
                    </Box>
                </Card>
            </Container>

        </>
    );
}